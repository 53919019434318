export const RECORD_STATUS = {
    APPROVEMENT: "На согласовании",
    MODERATION: "Ожидает модерации",
    READY: "В работе"
};

export const RECORD_ONLINE_STATUS = {
    ONLINE: "В эфире",
    OFFLINE: "Не в эфире"
};

export const RECORD_TYPE = {
    DEMO: "DEMO",
    ARCHIVED: "ARCHIVED",
    ACTIVE: "ACTIVE"
};

export const STUDIO_SECTION = {
    RECORDS: 'records',
    ARCHIVE: 'archive',
    DEMO: 'demo',
}
