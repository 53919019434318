import React from 'react';
import PropTypes from 'prop-types';
import styles from './RecordInfoSection.module.scss';
import classNames from 'class-names';
import { getOptionalValue } from "../../../utils/getOptionalValue";
import { getSecondsFromMs } from "../../../utils/getSecondsFromMs";

function RecordInfoSection({ open, data }) {
    const categoryName = data.categories && data.categories.map(category => category.name).join(', ') || '-';

    return (
        <div className={classNames(styles.container, { [styles.open]: open })}>
            <div className={classNames(styles.dropdownIcon, { [styles.open]: open })}/>
            <div className={styles.content}>
                <div className={styles.section}>
                    <div className={styles.sectionLabel}>
                        Маркетинговые данные
                    </div>
                    <div className={styles.infoContainer}>
                        <div className={styles.name}>
                            Категория ролика
                        </div>
                        <div className={styles.value}>
                            {categoryName}
                        </div>
                    </div>
                    <div className={styles.infoContainer}>
                        <div className={styles.name}>
                            Тип ролика
                        </div>
                        <div className={styles.value}>
                            {getOptionalValue(data, 'type.name')}
                        </div>
                    </div>
                </div>
                {data.techParams && (
                    <div className={styles.section}>
                        <div className={styles.sectionLabel}>
                            Технические данные
                        </div>
                        <div className={styles.infoContainer}>
                            <div className={styles.name}>
                                Частота дискретизации
                            </div>
                            <div className={styles.value}>
                                {getOptionalValue(data, 'techParams.frequency')}
                            </div>
                        </div>
                        <div className={styles.infoContainer}>
                            <div className={styles.name}>
                                Вес файла
                            </div>
                            <div className={styles.value}>
                                {getOptionalValue(data, 'techParams.size')}
                            </div>
                        </div>
                        <div className={styles.infoContainer}>
                            <div className={styles.name}>
                                Формат файла
                            </div>
                            <div className={styles.value}>
                                {getOptionalValue(data, 'techParams.format')}
                            </div>
                        </div>
                        <div className={styles.infoContainer}>
                            <div className={styles.name}>
                                Битрейт ролика
                            </div>
                            <div className={styles.value}>
                                {getOptionalValue(data, 'techParams.bitrate')}
                            </div>
                        </div>
                        <div className={styles.infoContainer}>
                            <div className={styles.name}>
                                Используемый кодек
                            </div>
                            <div className={styles.value}>
                                {getOptionalValue(data, 'techParams.codec')}
                            </div>
                        </div>
                        <div className={styles.infoContainer}>
                            <div className={styles.name}>
                                Тип звука
                            </div>
                            <div className={styles.value}>
                                {getOptionalValue(data, 'techParams.channelMode')}
                            </div>
                        </div>
                        <div className={styles.infoContainer}>
                            <div className={styles.name}>
                                Хронометраж ролика
                            </div>
                            <div className={styles.value}>
                                {getSecondsFromMs(data.duration)} секунд
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

RecordInfoSection.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object
};

export default RecordInfoSection;
