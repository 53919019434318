import React, { useEffect } from 'react';
import styles from './Layout.module.scss';
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ScrollButton from "../../components/ScrollButton";
import ModalRoot from "../../components/ModalRoot";

const Layout = ({ children }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <Header/>
            <ModalRoot/>
            <div className={styles.container}>
                {children}
            </div>
            <ScrollButton/>
            <Footer/>
        </>
    );
};

export default Layout;
