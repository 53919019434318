import { loadFromLocalStorage } from "../utils/localStorage";
import axios from "axios";
import { logOut } from "../redux/modules/auth/auth-thunk";
import { accessTokenSelector } from "../redux/modules/auth/auth-selectors";

export const configureApi = (store) => {
    //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    const accessToken = loadFromLocalStorage('accessToken');

    if (accessToken) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }

    const axiosInstance = axios.create();

    axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response.status === 401) {
                store.dispatch(logOut(false));
            } else {
                throw error;
            }
        }
    );

    axiosInstance.interceptors.request.use(
        config => {
            config.headers['Access-Control-Allow-Origin'] = '*';
            const currentToken = accessTokenSelector(store.getState());
            if (currentToken) {
                config.headers['Authorization'] = `Bearer ${currentToken}`;
            }
            return config;
        }
    )

    return axiosInstance;
}
