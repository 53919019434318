import { useState } from 'react';

//TODO Заменить везде
export const useSelectableList = (allIds, multiselect = true) => {
    const [selectedIds, setSelectedIds] = useState([]);

    const onToggle = (id) => {
        if (multiselect) {
            return selectedIds.includes(id) ?
                setSelectedIds(selectedIds.filter(item => item !== id)) :
                setSelectedIds([...selectedIds, id]);
        }
        else {
            selectedIds.includes(id) ?
            setSelectedIds([]) : setSelectedIds([id]);
        }
    };

    const toggleAll = (e) => {
        e.preventDefault();
        if (multiselect && allIds) {
            return setSelectedIds(selectedIds.length ? [] : allIds);
        }
    };

    const unselectAll = () => {
        return setSelectedIds([]);
    };

    return [onToggle, toggleAll, selectedIds, unselectAll, setSelectedIds];
};
