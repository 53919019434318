import React  from "react";
import VolumeRange from "../../VolumeRange";
import { useDispatch, useSelector } from "react-redux";
import {
    loadObjectVolumeLevels, setObjectVolumeLevel,
    volumeLevelsSelector
} from "../../../redux/modules/radio/slices/volume-levels-slice";
import { LoadingWrapper } from "../../LoadingWrapper";
import { useDebouncedCallback } from "../../../hooks/useDebouncedCallback";

export const ObjectSceneVolume = ({ objectId }) => {
    const volumeLevels = useSelector(volumeLevelsSelector);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(loadObjectVolumeLevels({ objectId }));
    }, [dispatch, objectId])

    const onChange = useDebouncedCallback((type, volume) => {
        dispatch(setObjectVolumeLevel({objectId, type, volume: Number(volume)}))
    }, 200)

    return (
        <LoadingWrapper isLoading={volumeLevels.isLoading}>
            <VolumeRange onChange={(e) => onChange('background', e.target.value)}
                         title='Фон'
                         value={volumeLevels.background}/>
            <VolumeRange onChange={(e) => onChange('advertising', e.target.value)}
                         title='Реклама'
                         value={volumeLevels.advertising}/>
            <VolumeRange onChange={(e) => onChange('clock', e.target.value)}
                         title='Часы/погода'
                         value={volumeLevels.clock}/>
            <VolumeRange onChange={(e) => onChange('master', e.target.value)}
                         title='Мастер'
                         value={volumeLevels.master}/>
        </LoadingWrapper>
    )
}
