import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'class-names';
import styles from './Tab.module.scss';

const Tab = ({ className, defaultActive, children }) => {
    return (
        <div className={classNames(className, styles.container)}>
            {children}
        </div>
    );
};

Tab.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    hideOnMobile: PropTypes.bool,
    hideOnDesktop: PropTypes.bool,
    defaultActive: PropTypes.bool
};

export default Tab;
