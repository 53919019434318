import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchObjectVolumeLevels, putObjectVolumeLevel } from "../../../../api/radio-api";

const initialState = {
    advertising: 0,
    background: 0,
    clock: 0,
    master: 0,
    isLoading: false,
}

export const volumeLevelsSelector = (state) => state.volumeLevels;

export const loadObjectVolumeLevels = createAsyncThunk(
    'object/fetchObjectVolumeLevels',
    async ({ objectId }, thunkAPI) => {
        console.log("thunk called", {objectId})
        const response = await fetchObjectVolumeLevels(objectId)
        return response.data
    }
)

export const setObjectVolumeLevel = createAsyncThunk(
    'object/setObjectVolumeLevels',
    async ({ objectId, type, volume }, thunkAPI) => {
        const { data } = await putObjectVolumeLevel(objectId, type, volume)
        return data;
    }
)

export const volumeLevelsSlice = createSlice({
    name: 'objectVolumeLevels',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(loadObjectVolumeLevels.fulfilled, (state, action) => {
            return { ...action.payload, isLoading: false };
        }).addCase(setObjectVolumeLevel.fulfilled, (state, action) => {
            return { ...action.payload, isLoading: false };

        }).addCase(loadObjectVolumeLevels.pending, (state, action) => {
            return { ...state, isLoading: true };
        }).addCase(setObjectVolumeLevel.pending, (state, action) => {
            return { ...state, isLoading: true };
        })
    },
})

export const { set } = volumeLevelsSlice.actions

export const { reducer: volumeLevelsReducer } = volumeLevelsSlice;
