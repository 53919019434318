import { RESET_STUDIO_FILTERS, SET_STUDIO_DATA, SET_STUDIO_FILTERS, SET_STUDIO_METADATA } from "./studio-actions";
import { STUDIO_ENTITY } from "../../../constants/studioEntities";
import { SET_STUDIO_RECORD_DATA } from "../../actions/action-types";

export const DEFAULT_STUDIO_DATA = {
    isLoading: false,
    pageNumber: 0,
    totalPages: 1,
    pageSize: 10,
    totalRecords: 0,
    list: [],
};

export const DEFAULT_RECORD_DATA = {
    isLoading: false,
    recordId: null,
    entity: null,
    data: null,
}

const initialState = {
    metadata: null,
    filters: {
        [STUDIO_ENTITY.DEMO]: {},
        [STUDIO_ENTITY.ARCHIVE]: {},
        [STUDIO_ENTITY.RECORDS]: {},
    },
    data: {
        [STUDIO_ENTITY.ARCHIVE]: { ...DEFAULT_STUDIO_DATA },
        [STUDIO_ENTITY.RECORDS]: { ...DEFAULT_STUDIO_DATA },
        [STUDIO_ENTITY.DEMO]: { ...DEFAULT_STUDIO_DATA },
    },
    record: {...DEFAULT_RECORD_DATA}
};

export const studioReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STUDIO_METADATA:
            return {
                ...state,
                metadata: action.payload
            };

        case SET_STUDIO_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.entity]: { ...action.payload.filters },
                }
            };

        case RESET_STUDIO_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload]: {},
                }
            };

        case SET_STUDIO_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.entity]: action.payload.data,
                }
            };

        case SET_STUDIO_RECORD_DATA:
            return {
                ...state,
                record: {
                    ...state.record,
                    ...action.payload,
                }
            };


        default:
            return state;
    }
};
