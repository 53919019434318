import React from 'react';
import styles from './BroadcastHoursScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import objectIcon from "../../../../img/icons/object-dark-grey-icon.svg";
import RecordStatus from "../../../../components/Cards/RecordCard/RecordStatus";
import { RECORD_ONLINE_STATUS, RECORD_STATUS } from "../../../../constants/recordTypes";
import ItemListTitle from "../../../../components/ItemListTitle";
import TimePicker from "../../../../components/TimePicker";
import Button from "../../../../components/common/Button";
import Select from "../../../../components/common/Select";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Объекты',
    href: '/radio/objects'
}, {
    name: 'Страница объекта',
    href: '/radio/object'
}, {
    name: 'Часы вещания',
    href: '/radio/object/broadcast-hours'
}];

const BROADCAST_TYPES = [
    'объекта',
    'фона',
    'рекламы',
]

const BroadcastHoursScene = () => {
    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes} className={styles.hideDesktop}/>
            <ItemListTitle icon={objectIcon}
                           className={styles.pageTitle}
                           title={"09385-19 ТГПУ Очное заочное"}>
                ID 51464 <RecordStatus onlineStatus={RECORD_ONLINE_STATUS.ONLINE}
                                       status={RECORD_STATUS.READY}/>
            </ItemListTitle>
            <div className={styles.pageContainer}>
                <div className={styles.currentValueContainer}>
                    <div className={styles.contentLabel}>
                        Часы вещания <Select options={BROADCAST_TYPES} className={styles.selectWrapper}/>
                    </div>
                    <div className={styles.currentValue}>
                        12:00 - 14:00
                    </div>
                </div>
                <div className={styles.pickerContainer}>
                    <div className={styles.contentLabel}>
                        Часы вещания с:
                    </div>
                    <TimePicker className={styles.timePicker}/>
                </div>
                <div className={styles.pickerContainer}>
                    <div className={styles.contentLabel}>
                        Часы вещания по:
                    </div>
                    <TimePicker className={styles.timePicker}/>
                </div>
                <div className={styles.buttonContainer}>
                    <Button className={styles.submitButton}>
                        Применить
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default BroadcastHoursScene;
