import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { loadStudioMetadata } from "../redux/modules/studio/studio-actions";
import { studioMetadataSelector } from "../redux/modules/studio/studio-selectors";

export const useStudioMetadata = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const metadata = useSelector(studioMetadataSelector);

    useEffect(() => {
        if (!isLoading && !metadata) {
            dispatch(loadStudioMetadata());
            setLoading(true);
        }

        if (metadata) {
            setLoading(false);
        }
    }, [isLoading, metadata]);

    const isLoaded = !!metadata;

    return [isLoaded];
}
