import React from 'react';
import styles from './ArchiveListScene.module.scss';
import { connect } from "react-redux";
import Search from "../../../components/StudioSearch";
import archiveIcon from "../../../img/icons/archive-icon.svg";
import { PAGE_SUBSECTIONS, PAGE_TYPE } from "../../../constants/pageTypes";
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import RecordActions from "../../../components/Search/actions/RecordActions";
import CardCheckbox from "../../../components/Cards/CardCheckbox";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ListContainer from "../../../components/ListContainer";
import { useStudioMetadata } from "../../../hooks/useStudioMetadata";
import { useSelectableList } from "../../../hooks/useSelectableList";
import { createStudioDataSelector } from "../../../redux/modules/studio/studio-selectors";
import { STUDIO_ENTITY } from "../../../constants/studioEntities";
import { loadStudioData, orderNewTrack, removeTracksFromArchive } from "../../../redux/modules/studio/studio-actions";
import Spinner from "../../../components/Spinner";
import StudioRecordCard from "../../../components/Cards/StudioRecordCard";

const mapStateToProps = (state) => ({
    data: createStudioDataSelector(STUDIO_ENTITY.ARCHIVE)(state)
});

const mapDispatchToProps = dispatch => ({
    loadData: () => dispatch(loadStudioData(STUDIO_ENTITY.ARCHIVE)),
    removeFromArchive: (ids) => dispatch(removeTracksFromArchive(ids, STUDIO_ENTITY.ARCHIVE)),
    orderTrack: () => dispatch(orderNewTrack()),
});

const breadcrumbsRoutes = [{
    name: 'Студия звукозаписи',
    href: '#'
}, {
    name: 'Архив',
    href: '/studio/archive'
}];

const ArchiveListScene = ({ data, loadData, removeFromArchive, orderTrack }) => {
    useSetCurrentSection(PAGE_SUBSECTIONS.ARCHIVE);
    const [metadataLoaded] = useStudioMetadata();
    const [onToggle, selectAll, selectedIds, unselectAll] = useSelectableList();

    React.useEffect(() => {
        loadData();
    }, [loadData]);

    const setPage = (page) => {
    };

    const removeSelectedFromArchive = () => {
        removeFromArchive(selectedIds);
        unselectAll();
    };

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.recordControlsContainer}>
                {metadataLoaded && <Search
                    entity={STUDIO_ENTITY.ARCHIVE}
                    actions={<RecordActions
                                orderTrack={orderTrack}
                                pageType={PAGE_TYPE.ARCHIVE}
                                onArchive={removeSelectedFromArchive}
                    />}/>}
            </div>
            {data.isLoading ? (
                    <div className={styles.spinnerContainer}>
                        <Spinner/>
                    </div>
                )
                : (
                    <ListContainer noLoader className={styles.recordsContainer}>
                        {data.list.map((record, index) => (
                            <StudioRecordCard
                                record={record}
                                checkbox={<CardCheckbox
                                    onChange={() => onToggle(record.id)}
                                    checked={selectedIds.includes(record.id)}/>}
                                icon={archiveIcon}
                                entity={STUDIO_ENTITY.ARCHIVE}
                                key={index}
                            />)
                        )}
                    </ListContainer>
                )}
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchiveListScene);
