import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { radioMetadataSelector } from "../../redux/modules/radio/radio-selectors";
import { loadRadioMetadata } from "../../redux/modules/radio/radio-actions";

export const useRadioMetadata = () => {
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const metadata = useSelector(radioMetadataSelector);

    useEffect(() => {
        if (!isLoading && !metadata) {
            dispatch(loadRadioMetadata());
            setLoading(true);
        }

        if (metadata) {
            setLoading(false);
        }
    }, [isLoading, metadata]);

    const isLoaded = !!metadata;

    return [isLoaded];
}
