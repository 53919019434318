import React from 'react';
import styles from './ObjectPriorityAdsScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ObjectPriorityCard from "../../../../components/Cards/ObjectPriorityCard";
import ListContainer from "../../../../components/ListContainer";
import { useRadioPriorityAds } from "../../../../hooks/radio/useRadioPriorityAds";

const generateBreadcrumbsRoutes = (id) => [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Объекты',
    href: '/radio/objects'
}, {
    name: 'Страница объекта',
    href: `/radio/objects/${id}`
}, {
    name: 'Приоритетные объявления',
    href: `/radio/objects/${id}/announcements`
}];

const ObjectPriorityAdScene = ({match}) => {
    const { id } = match.params;

    const [data, isLoaded] = useRadioPriorityAds(id);

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={generateBreadcrumbsRoutes(id)}/>
            {isLoaded && (
                <ListContainer noLoader className={styles.listContainer}>
                    {data.list.map(item => (
                        <ObjectPriorityCard data={item} key={item.id} />
                    ))}
                </ListContainer>
            )}
        </div>
    );
};

export default ObjectPriorityAdScene;
