import React from "react";
import PropTypes from "prop-types";
import styles from "./BackgroundPlaylistItem.module.scss";
import classNames from "class-names";
import { ReactComponent as PlaylistCountObjects } from "../../img/icons/image-count-objects.svg"
import ListContainer from "../ListContainer";
import Button from "../common/Button";

const BackgroundPlaylistItem = ({
  title,
  image,
  objectsCount,
  imagePlaylistPlay,
  isActive,
  totalObjectsCount,
}) => {

  const colorStyle = isActive ? styles.colorActive : styles.colorInactive;

  return (
      <div className={!isActive ? classNames([styles.playlist, colorStyle]) : styles.playlist}>
          <div className={styles.block}>
            <div className={styles.image} style={{backgroundImage: `url(${image})`}}>
              <div className={styles.imagePlaylistPlay}>
                    <ListContainer emptyText="" className={styles.listContainer}>
                        <img src={imagePlaylistPlay} alt=""/>
                    </ListContainer>
              </div>
            </div>
            <div className={styles.leftBlock}>
              <p className={styles.title}>{title}</p>
              <div className={classNames([styles.infoBlock, colorStyle])}>
                <div className={styles.infoBlockObjects}>
                  <PlaylistCountObjects className={styles.playlistCountObjects}/>
                  <span>{objectsCount}/{totalObjectsCount}</span>
                </div>
                {/*{objectsCount !== 10  ? (*/}
                {/*    <Button className={styles.priceButton}>*/}
                {/*      <span>495 ₽</span>*/}
                {/*    </Button>*/}
                {/*    ) : null }*/}
              </div>
            </div>
          </div>
      </div>
  );
};

BackgroundPlaylistItem.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  objectsCount: PropTypes.number,
  imagePlaylistPlay: PropTypes.string,
  inActive: PropTypes.bool,
};

export default BackgroundPlaylistItem;
