import React from 'react';

import Spinner from "../Spinner";
import styles from "./LoadingWrapper.module.scss";

export const LoadingWrapper = ({isLoading, children}) => {
    return isLoading ? (
        <div className={styles.spinnerContainer}>
            <Spinner/>
        </div>
    ) : children;
}
