import { UI_HIDE_MODAL, UI_OPEN_MODAL } from "../../../actions/action-types";

const initialState = {
    visible: false,
    onSubmit: () => {},
    title: "Предупреждение",
    text: null,
    submitText: "Подтвердить",
    cancelText: "Отменить",
};

export const modalReducer = (state = initialState, action) => {
  switch (action.type) {

      case UI_OPEN_MODAL:
          return {
              ...initialState,
              ...action.payload,
              visible: true,
          };

      case UI_HIDE_MODAL:
          return initialState;

    default: return state;
  }
};
