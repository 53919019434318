import React from 'react';
import PropTypes from 'prop-types';
import styles from './CalendarPlaylistRepeat.module.scss';
import { REPEAT_TYPE } from "../../constants/repeatTypes";
import CalendarRepeatWeekly from "./CalendarRepeatWeekly";
import CalendarRepeatMonthly from "./CalendarRepeatMonthly";
import classNames from 'class-names';
import Checkbox from "../common/Checkbox/NewCheckbox";

const REPEAT_COMPONENTS = {
    [REPEAT_TYPE.WEEKLY]: CalendarRepeatWeekly,
    [REPEAT_TYPE.MONTHLY]: CalendarRepeatMonthly,
}

const CalendarPlaylistRepeat = ({ repeatType, selectedDate }) => {
    const RepeatComponent = REPEAT_COMPONENTS[repeatType] || undefined;
    const [repeatEndType, setRepeatEndType] = React.useState('never');

    if (repeatType === REPEAT_TYPE.NEVER) {
        return null;
    }

    return (
        <div className={styles.container}>
            <h5 className={styles.title}>Настройка повтора</h5>
            {RepeatComponent && (
                <RepeatComponent
                    repeatType={repeatType}
                    selectedDate={selectedDate}
                />
            )}
            <div className={styles.periodEndContainer}>
                <h5 className={styles.periodEndTitle}>
                    Заканчивается
                </h5>
                <div className={styles.periodForm}>
                    <Checkbox
                        className={styles.periodEndItem}
                        onChange={() => setRepeatEndType('never')}
                        checked={repeatEndType === 'never'}
                    >
                        Никогда
                    </Checkbox>
                    <Checkbox
                        className={styles.periodEndItem}
                        onChange={() => setRepeatEndType('date')}
                        checked={repeatEndType === 'date'}
                    >
                        <input type="date" className={styles.periodInput}
                               onClick={() => setRepeatEndType('date')}
                               defaultValue={selectedDate.toISOString().split('T')[0]}/>
                    </Checkbox>
                    <Checkbox
                        className={styles.periodEndItem}
                        onChange={() => setRepeatEndType('number')}
                        checked={repeatEndType === 'number'}
                    >
                    <span>
                        После
                        <input
                            className={classNames(styles.periodInput, styles.countInput)}
                            onClick={() => setRepeatEndType('number')}
                            type='number'
                            defaultValue='1'
                        />
                        раза
                    </span>
                    </Checkbox>
                </div>
            </div>
        </div>
    );
};

CalendarPlaylistRepeat.propTypes = {
    repeatType: PropTypes.string,
    selectedDate: PropTypes.instanceOf(Date)
};

export default CalendarPlaylistRepeat;
