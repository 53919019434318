import React from 'react';
import styles from './BalanceHistoryCard.module.scss';
import GenericCard from "../GenericCard";
import classNames from 'class-names';

export const OPERATION_TYPE = {
    INCOME: 'Зачисление на счёт',
    PAYMENT: 'Списание по счёту',
};

const BalanceHistoryCard = ({operationType, responsive, checkbox, onClick, billDate, orderId, amount }) =>
    (
        <GenericCard
            onClick={onClick}
            className={classNames(styles.card, responsive && styles.responsive)}
            headClassName={classNames(styles.cardHead, operationType === OPERATION_TYPE.INCOME && styles.cardIncome)}
            headContent={<>
                {billDate}
                {checkbox}
            </>}>
            <div className={styles.cardContent}>
                <div className={styles.operationType}>
                    {orderId}<br/>
                    {operationType}
                </div>
                <div className={styles.price}>
                    <div className={classNames(
                        styles.icon,
                        operationType === OPERATION_TYPE.INCOME ? styles.income : styles.payment
                    )}/> {amount} ₽
                </div>
            </div>
        </GenericCard>
    );

export default BalanceHistoryCard;
