import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { uiSetCurrentSection } from "../redux/actions/ui/currentPage/currentSection";
import {setSelectedObject} from "../redux/modules/profile/profile-actions";

export const useSetCurrentSection = (section) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(uiSetCurrentSection(section));
    }, [section, dispatch]);
};

