import React from 'react';
import styles from './LoginScene.module.scss';
import Modal from "../../components/AuthModal";
import AuthInput from "../../components/common/AuthInput";
import AuthButton from "../../components/common/AuthButton";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logIn as logInAction } from '../../redux/modules/auth/auth-thunk';
import loginIcon from '../../img/icons/login-icon.svg';
import passwordIcon from '../../img/icons/password-icon.svg';
import { DEFAULT_ROUTE } from "../../constants/routing";
import Checkbox from "../../components/common/Checkbox";

const mapStateToProps = ({ authData }) => ({
    authorized: authData.authorized
});

const mapDispatchToProps = dispatch => ({
    logIn: ({ login, password }) => dispatch(logInAction({ login, password }))
});

const LoginScreen = ({ authorized, logIn }) => {
    const [login, setLogin] = React.useState('');
    const [password, setPassword] = React.useState('');

    if (authorized) {
        return <Redirect to={DEFAULT_ROUTE}/>
    }

    const onLogin = () => logIn({ login, password });

    return (
        <>
            <div className={styles.backgroundContainer}/>
            <div className={styles.container}>
                <div className={styles.logo}/>
                <Modal className={styles.modalContainer}>
                    <h1 className={"auth-modal__head"}>
                        Авторизация
                    </h1>
                    <div className={"auth-modal__form"}>
                        <AuthInput
                            placeholder="Логин"
                            icon={loginIcon}
                            value={login}
                            onChange={(e) => setLogin(e.target.value)}
                        />
                        <AuthInput
                            placeholder="Пароль"
                            icon={passwordIcon}
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className={styles.passwordOptions}>
                        <div className={styles.rememberPassword}>
                            <Checkbox type={'card'}
                                      className={styles.checkbox}>
                                Запомнить пароль
                            </Checkbox>
                        </div>
                        <Link to={'/restore-password'} className={styles.restorePasswordLink}>
                            Забыли пароль?
                        </Link>
                    </div>
                    <div className={"auth-modal__buttons-container"}>
                        <AuthButton
                            primary
                            onClick={onLogin}
                        >
                            Войти
                        </AuthButton>
                        <AuthButton>
                            <Link to={'/register'}>
                                Регистрация
                            </Link>
                        </AuthButton>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
