const COOKIE_EXPIRE_TIME = 120 * 60 * 1000; // 120 минут

export const checkAuth = () => {
    return document.cookie.indexOf('authorized') >= 0;
};

export const addAuthCookie = () => {
    const expireDate = new Date();
    expireDate.setTime(expireDate.getTime() + COOKIE_EXPIRE_TIME);
    document.cookie = `authorized=true;expires=${expireDate.toUTCString()}`;
};
