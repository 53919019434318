import React, { useEffect, useState } from 'react';
import styles from './DemoListScene.module.scss';
import Search from "../../../components/StudioSearch";
import StudioRecordCard from "../../../components/Cards/StudioRecordCard";
import demoIcon from "../../../img/icons/demo-icon.svg";
import Pagination from "../../../components/Pagination";
import { connect } from "react-redux";
import { PAGE_SUBSECTIONS, PAGE_TYPE } from "../../../constants/pageTypes";
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import RecordActions from "../../../components/Search/actions/RecordActions";
import CardCheckbox from "../../../components/Cards/CardCheckbox";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ListContainer from "../../../components/ListContainer";
import { useStudioMetadata } from "../../../hooks/useStudioMetadata";
import { STUDIO_ENTITY } from "../../../constants/studioEntities";
import { loadStudioData } from "../../../redux/modules/studio/studio-actions";
import { createStudioDataSelector } from "../../../redux/modules/studio/studio-selectors";
import Spinner from "../../../components/Spinner";
import { useSelectableList } from "../../../hooks/useSelectableList";

const mapStateToProps = (state) => ({
    data: createStudioDataSelector(STUDIO_ENTITY.DEMO)(state)
});

const mapDispatchToProps = dispatch => ({
    loadData: () => dispatch(loadStudioData(STUDIO_ENTITY.DEMO)),
});

const breadcrumbsRoutes = [{
    name: 'Студия звукозаписи',
    href: '#'
}, {
    name: 'Demo',
    href: '/studio/demo'
}];

const DemoListScene = ({ data, loadData }) => {
    useSetCurrentSection(PAGE_SUBSECTIONS.DEMO);
    const [metadataLoaded] = useStudioMetadata();
    const [onToggle, selectAll, selectedIds] = useSelectableList();

    React.useEffect(() => {
        loadData();
    }, [loadData]);

    const setPage = (page) => {
        loadData();
    };

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.recordControlsContainer}>
                {metadataLoaded && <Search
                    entity={STUDIO_ENTITY.DEMO}
                    actions={<RecordActions pageType={PAGE_TYPE.DEMO}
                    />}/>}
            </div>
            {data.isLoading ? (
                    <div className={styles.spinnerContainer}>
                        <Spinner/>
                    </div>
                )
                : (<ListContainer noLoader className={styles.recordsContainer}>
                    {data.list.map((record, index) => (
                        <StudioRecordCard
                            record={record}
                            checkbox={
                                <CardCheckbox
                                    onChange={() => onToggle(record.id)}
                                    checked={selectedIds.includes(record.id)}/>
                            }
                            entity={STUDIO_ENTITY.DEMO}
                            icon={demoIcon}
                            key={index}
                        />
                    ))}
                </ListContainer>)}
            {/*<Pagination entityName={'Роликов'}
                        selectable
                        count={demo.count}
                        resultsPerPage={searchData.count}
                        currentPage={searchData.page}
                        setPage={setPage}
                        setSelected={setSelectedIds}
                        selectedIds={selectedIds}
                        items={demo.list}/>*/}
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(DemoListScene);

