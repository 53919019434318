import React from 'react';
import PropTypes from 'prop-types';
import styles from './CheckboxWithLabel.module.scss'
import CardCheckbox from "../../../Cards/CardCheckbox";
import classNames from 'class-names';

const CheckboxWithLabel = ({ labelChecked, labelUnchecked, onChange, checked, className }) => (
        <div onClick={onChange}
             className={classNames(styles.container, className)}>
            <CardCheckbox onChange={onChange}
                          className={styles.checkbox}
                          checked={checked}/>
            <div className={styles.text}>
                {checked ? labelChecked : labelUnchecked} всё
            </div>
        </div>
);


CheckboxWithLabel.propTypes = {
    labelChecked: PropTypes.string,
    labelUnchecked: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool
};

export default CheckboxWithLabel;
