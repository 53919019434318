import { SET_AUTHORIZED, SET_TOKENS, RESET_TOKENS } from "../../actions/action-types";
import { checkAuth } from "../../../utils/authCookies";
import { loadFromLocalStorage } from "../../../utils/localStorage";

const initialState = {
    authorized: checkAuth(),
    // Уникальный код, который мы передаём перед авторизацией, и должны сверить с полученным после неё
    uniqueState: loadFromLocalStorage('uniqueState'),
    // Код, который мы получаем в ответе, по нему потом можем запросить токен
    accessToken: loadFromLocalStorage('accessToken'),
    refreshToken: loadFromLocalStorage('refreshToken'),
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_AUTHORIZED:
            return { ...state, authorized: action.payload };

        case SET_TOKENS:
            return {
                ...state,
                accessToken: action.payload.accessToken,
                refreshToken: action.payload.refreshToken,
            };

        case RESET_TOKENS:
            return {
                ...state,
                accessToken: null,
                refreshToken: null,
            }

        default:
            return state;
    }
};
