import React from 'react';
import styles from './PriorityRecordsScene.module.scss';
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Search from "../../../../components/Search";
import classNames from "class-names";
import Button from "../../../../components/common/Button";
import ObjectCard from "../../../../components/Cards/ObjectCard";
import Pagination from "../../../../components/Pagination";
import ListContainer from "../../../../components/ListContainer";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Приоритетные объявления',
    href: '/radio/priority'
}, {
    name: 'Страница медиаплана',
    href: '/radio/priority/create'
}, {
    name: 'Выбор ролика',
    href: '/radio/priority/create/records'
}];

const testObjectIds = [10, 21, 32, 49, 99, 19];

const PriorityRecordsScene = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.PRIORITY);

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.title}>
                Список роликов
            </div>
            <div className={styles.controlsContainer}>
                <Search showFilter
                        containerClassName={styles.searchContainer}
                        actions={
                            <div className={classNames(styles.buttonsContainer, styles.hideMobile)}>
                                <Button href={'/radio/priority/create'}>
                                    Отменить
                                </Button>
                                <Button href={'/radio/priority/create'}>
                                    Выбрать
                                </Button>
                            </div>}/>
            </div>
            <ListContainer className={styles.listContainer}>
                {testObjectIds.map((item, index) => (
                    <ObjectCard key={index}/>
                ))}
            </ListContainer>
            <div className={classNames(styles.buttonsContainer, styles.hideDesktop)}>
                <Button href={'/radio/priority/create'}>
                    Отменить
                </Button>
                <Button href={'/radio/priority/create'}>
                    Выбрать
                </Button>
            </div>
            <Pagination count={12}
                        entityName={'Объектов'}
                        resultsPerPage={6}
                        currentPage={1}
                        setPage={() => {
                        }}
                        items={[]}/>
        </div>
    );
};

export default PriorityRecordsScene;
