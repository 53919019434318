import React from 'react';
import PropTypes from 'prop-types';
import styles from './BroadcastReference.module.scss';
import BroadcastReferenceItem from "./BroadcastReferenceItem";

const BroadcastReference = ({ data }) => {
    return (
        <div className={styles.container}>
            {data.map((item, index) => <BroadcastReferenceItem data={item} key={index}/>)}
        </div>
    );
};

BroadcastReference.propTypes = {
    data: PropTypes.array
};

export default BroadcastReference;
