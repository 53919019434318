import { UI_SET_CURRENT_SECTION } from "../../../actions/action-types";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";

const initialState = PAGE_SUBSECTIONS.OBJECTS;

export const currentSectionReducer = (state = initialState, action) => {
  switch (action.type) {

      case UI_SET_CURRENT_SECTION:
          return action.payload;

    default: return state;
  }
};
