import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'class-names';
import styles from './AuthModal.module.scss';
import './modalStyles.scss';

const AuthModal = ({children, className, ...props}) => {
    return (
        <div className={classNames(styles.container, "auth-modal", className)}>
            {children}
        </div>
    );
};

AuthModal.propTypes = {
    className: PropTypes.string
};

export default AuthModal;
