import React from 'react';
import PropTypes from 'prop-types';
import styles from './Input.module.scss';
import classNames from 'class-names';

const DEFAULT_DATE_PROPS = {
    min: '2010-01-01',
    max: '2030-01-01'
};

const Input = ({
    type = 'text',
    name,
    defaultValue,
    label,
    onChange,
    value,
    inputClassName,
    className,
    maxLength,
    ...props
}) => {
    const InputComponent = type === 'textarea' ? type : 'input';
    const dateProps = type === 'date' ? DEFAULT_DATE_PROPS : [];

    return (
        <div className={classNames(styles.container, {
            [styles.date]: type === 'date',
            [styles.time]: type === 'time'
        }, className)}>
            {label ? <label htmlFor={name}>
                {label}
            </label> : null}
            <InputComponent
                {...dateProps}
                {...props}
                onChange={onChange}
                defaultValue={defaultValue}
                value={value}
                type={type}
                name={name}
                className={inputClassName}
                maxLength={maxLength}
            />
        </div>
    );
};

Input.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any,
    inputClassName: PropTypes.string,
    className: PropTypes.string,
    maxLength: PropTypes.number,
};

export default Input;
