import React, {useEffect, useState} from 'react';
import styles from './BackgroundPlaylists.module.scss';
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../constants/pageTypes";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Search from "../../../components/Search/BackgroundSearch/index"
import { Link } from "react-router-dom";
import PlaylistItem from "../../../components/BackgroundPlaylistItem/index";
import Button from '../../../components/common/Button';
import { useDispatch, useSelector } from "react-redux";
import { backgroundPlaylists } from "../../../redux/modules/background/background-selectors";
import {loadBackgroundPlaylists} from "../../../redux/modules/background/background-actions";
import Spinner from "../../../components/Spinner";
import {useDebouncedCallback} from "../../../hooks/useDebouncedCallback";
import image from "../../../img/icons/playlist-info-icon.svg";
import {useRadioObjects} from "../../../hooks/radio/useRadioObjects";

const breadcrumbsRoutes = [
    {
        name: 'Фон',
        href: '/background/playlists'
    },
    {
        name: 'Плейлисты',
        href: '/background/playlists'
    }
];

const BackgroundPlaylists = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.PLAYLISTS);
    const dispatch = useDispatch();
    const data = useSelector(backgroundPlaylists);
    const [objects] = useRadioObjects();
    useEffect(() => {
        dispatch(loadBackgroundPlaylists({type: 'NON_RAO', title: '', _sort: ''}))
    },[dispatch]);


    const setQuery = useDebouncedCallback((query) => {
        dispatch(loadBackgroundPlaylists({type: 'NON_RAO', title: query, _sort: ''}))
    }, 500);

    const setOptions = (options) => {
        dispatch(loadBackgroundPlaylists(options));
    }

    const setFilters = (filter) => {
        if (filter) {
            dispatch(loadBackgroundPlaylists({type: 'NON_RAO'}))
        } else {
            dispatch(loadBackgroundPlaylists())
        }
    }

    if (!data) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes} className={styles.breadcrumbs}/>
            {/*<div className={styles.searchContainer}>*/}
            {/*    <Search containerClassName={styles.searchBackground}*/}
            {/*            setOptions={setOptions}*/}
            {/*            setFilters={setFilters}*/}
            {/*            onChange={({target}) => setQuery(target.value)} />*/}
            {/*    <Button className={styles.link} href={`/background/playlist/1/order`}>*/}
            {/*        <p>Заказать плейлист</p>*/}
            {/*    </Button>*/}
            {/*</div>*/}
            <div className={styles.playlists}>
                {data.items.map((playlist, id) => (
                    <Link className={styles.playlistLink}
                          to={`/background/playlist/${playlist.id}`}
                          key={id}>
                        <PlaylistItem
                            title={playlist.title}
                            image={playlist.miniatureImage}
                            objectsCount={playlist.objectsCount}
                            totalObjectsCount={objects?.totalEntities}
                            // imagePlaylistPlay={playlist?.imagePlaylistPlay}
                            isActive />
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default BackgroundPlaylists;
