import {
  OBJECT_CREATE_METADATA,
  RESET_RADIO_PARAMS,
  SET_RADIO_DATA,
  SET_RADIO_FORM_DATA,
  SET_RADIO_METADATA,
  SET_RADIO_PARAMS
} from "./radio-actions";

export const DEFAULT_LIST_PARAMS = {
  _page: 0,
  _pageSize: 10,
  entityName: null,
};

export const DEFAULT_RADIO_LIST = {
  isLoading: false,
  totalPages: 1,
  pageSize: 10,
  totalEntities: 0,
  list: [],
}

export const DEFAULT_RADIO_ENTITY = {
  isLoading: false,
  id: null,
  entityName: null,
  data: null,
}

const initialState = {
  metadata: null,
  params: {},
  data: null,
  formData: {
    entityName: null,
  },
  objectCreateMetadata: null,
};

export const radioReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RADIO_DATA:
      return ({
        ...state,
        data: action.payload,
      });

    case SET_RADIO_FORM_DATA:
      return ({
        ...state,
        formData: action.payload,
      });

    case SET_RADIO_METADATA:
      return ({
        ...state,
        metadata: action.payload,
      });

    case SET_RADIO_PARAMS:
      return ({
        ...state,
        params: action.payload,
      });

    case RESET_RADIO_PARAMS:
      return ({
        ...state,
        params: {}
      })

    case OBJECT_CREATE_METADATA:
      return ({
        ...state,
        objectCreateMetadata: action.payload
      })


    default: return state;
  }
};
