import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './MediaplanListActions.module.scss';
import Popup from "../../../Popup";
import classNames from 'class-names';
import { Link } from "react-router-dom";

const MediaplanListActions = ({ className, addHref, onDelete }) => {
    const [actionsOpen, setActionsOpen] = useState(false);

    return (
        <div className={classNames(styles.container, className)}>
            <Link to={addHref} className={styles.addButton}>
                Добавить
            </Link>
            <div className={styles.dropdownButton} onClick={() => setActionsOpen(!actionsOpen)}>
                Действия
                {actionsOpen &&
                <Popup closePopup={() => setActionsOpen(false)}
                       containerClassName={styles.dropdownPopupContainer}
                       className={styles.dropdownPopup}>
                    <div className={styles.popupButton} onClick={onDelete}>
                        Удалить отмеченные
                    </div>
                </Popup>}
            </div>
        </div>
    );
};

MediaplanListActions.propTypes = {
    className: PropTypes.string
};

export default MediaplanListActions;
