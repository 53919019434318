import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import './date-picker.scss';
import { ru } from "react-date-range/src/locale";
import styles from './DatePicker.module.scss';
import classNames from 'class-names';

const DatePicker = ({ className, value, onChange }) => {
    const [selectedRange, setSelectedRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
        color: '#E53935'
    }]);

    const handleSelect = (ranges) => {
        if (onChange) {
            onChange(ranges);

            return;
        }

        setSelectedRange([ranges.selection]);
    };

    React.useEffect(() => {
        if (Array.isArray(value)) {
            setSelectedRange([{
                ...selectedRange[0],
                startDate: value[0],
                endDate: value[1],
            }])
        }
    }, [value])

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.labelContainer}>
                <div className={styles.label}>
                    Дата создания
                </div>
                <div className={styles.icon}/>
            </div>
            <DateRange
                locale={ru}
                ranges={selectedRange}
                onChange={handleSelect}
            />
        </div>
    );
};

DatePicker.propTypes = {};

export default DatePicker;
