import React from 'react';
import styles from './RegisterScene.module.scss';
import Modal from "../../components/AuthModal";
import AuthInput from "../../components/common/AuthInput";
import AuthButton from "../../components/common/AuthButton";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { DEFAULT_ROUTE } from "../../constants/routing";
import { register as registerAction } from "../../redux/modules/auth/auth-thunk";

const mapStateToProps = ({ authData }) => ({
    authorized: authData.authorized
});

const mapDispatchToProps = dispatch => ({
    register: (data) => dispatch(registerAction(data))
});

const RegisterScene = ({ authorized, register }) => {
    const [data, setData] = React.useState({
        phone: '',
        email: '',
        password: '',
        inn: '',
        name: ''
    });

    const onRegister = () => register(data);

    const onChange = (e) => setData({
        ...data,
        [e.target.name]: e.target.value
    });

    if (authorized) {
        return <Redirect to={DEFAULT_ROUTE}/>
    }

    return (
        <>
            <div className={styles.backgroundContainer}/>
            <div className={styles.container}>
                <div className={styles.logo}/>
                <Modal className={styles.modalContainer}>
                    <h1 className={"auth-modal__head"}>
                        Регистрация
                    </h1>
                    <div className={"auth-modal__form"}>
                        <AuthInput label="Телефон"
                                   value={data["phone"]}
                                   onChange={onChange}
                                   name="phone"
                                   hint="На данный номер телефона будет отправлено СМС с подтверждением"/>
                        <AuthInput
                            label="Email"
                            type="email"
                            hint="На данную почту будет отправлено подтверждение"
                            value={data["email"]}
                            onChange={onChange}
                            name="email"
                        />
                        <AuthInput
                            className={styles.inputWithMargin}
                            label="Пароль"
                            type="password"
                            value={data["password"]}
                            onChange={onChange}
                            name="password"
                        />
                        <AuthInput
                            label="ИНН"
                            type="number"
                            hint="ИНН вашей организации"
                            value={data["inn"]}
                            onChange={onChange}
                            name="inn"
                        />
                        <AuthInput
                            label="Название"
                            hint="Название организации"
                            value={data["name"]}
                            onChange={onChange}
                            name="name"
                        />
                    </div>
                    <div className={"auth-modal__buttons-container"}>
                        <AuthButton primary onClick={onRegister}>
                            Зарегистрироваться
                        </AuthButton>
                        <AuthButton>
                            <Link to={'/login'}>
                                Войти
                            </Link>
                        </AuthButton>
                    </div>
                </Modal>
            </div>
        </>
    );
};

RegisterScene.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterScene);
