import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    deleteObjectBroadcastPauses,
    fetchObjectBroadcastPauses,
    postObjectBroadcastPause
} from "../../../../api/radio-api";

const initialState = {
    list: [],
    isListLoading: false,
    isCreateLoading: false,
}

export const objectPausesSelector = (state) => state.objectPauses;

export const loadObjectBroadcastPauses = createAsyncThunk(
    'object/loadObjectBroadcastPauses',
    async ({ objectId }, thunkAPI) => {
        const response = await fetchObjectBroadcastPauses(objectId)
        return response.data
    }
)

export const addObjectBroadcastPause = createAsyncThunk(
    'object/addObjectBroadcastPause',
    async ({ objectId, pauseData }, thunkAPI) => {
        const { data } = await postObjectBroadcastPause(objectId, pauseData)
        return data;
    }
)

export const deleteObjectBroadcastPause = createAsyncThunk(
    'object/deleteObjectBroadcastPause',
    async ({ objectId, pauseId }, thunkAPI) => {
        const { data } = await deleteObjectBroadcastPauses(objectId, pauseId)
        return data;
    }
)

export const objectPausesSlice = createSlice({
    name: 'objectBroadcastPauses',
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(loadObjectBroadcastPauses.pending, (state, action) => {
            return { ...state, isListLoading: true };
        }).addCase(loadObjectBroadcastPauses.fulfilled, (state, action) => {
            return { ...state, list: action.payload, isListLoading: false };
        }).addCase(addObjectBroadcastPause.pending, (state, action) => {
            return { ...state, isCreateLoading: true };
        }).addCase(addObjectBroadcastPause.fulfilled, (state, action) => {
            return { ...state, isCreateLoading: false };
        })
    },
})

export const { set } = objectPausesSlice.actions

export const { reducer: objectPausesReducer } = objectPausesSlice;
