import React from 'react';
import styles from './Header.module.scss';
import HeaderMenu from "./HeaderMenu";

const Header = () => {
    return (
        <div className={styles.container}>
            <div className={styles.logo}/>
            <HeaderMenu/>
        </div>
    );
};

export default Header;
