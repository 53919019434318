import { combineReducers } from "redux";
import { authReducer } from "../modules/auth/auth-reducer";
import { uiReducer } from "./ui/uiReducer";
import { studioReducer } from "../modules/studio/studio-reducer";
import {profileReducer} from "../modules/profile/profile-reducer";
import {backgroundReducer} from "../modules/background/background-reducer";
import { radioReducer } from "../modules/radio/radio-reducer";
import { playerReducer } from "../modules/player/player-reducer";
import { volumeLevelsReducer as volumeLevels } from "../modules/radio/slices/volume-levels-slice";
import { objectPausesReducer as objectPauses } from "../modules/radio/slices/object-pauses-slice";

export const initialState = {

};

export const rootReducer = combineReducers({
    authData: authReducer,
    studio: studioReducer,
    profile: profileReducer,
    background: backgroundReducer,
    radio: radioReducer,
    ui: uiReducer,
    player: playerReducer,
    volumeLevels,
    objectPauses,
});
