import React, { useEffect } from 'react';
import styles from './Popup.module.scss';
import './popup.scss';
import classNames from 'class-names';

const Popup = ({ children, closePopup, className, containerClassName }) => {
    const scrollListener = () => closePopup();

    useEffect(() => {
        document.addEventListener('scroll', scrollListener);
        return () => document.removeEventListener('scroll', scrollListener);
    });

    return (
        <>
            <div className={styles.popupCloseOverlay} onClick={closePopup}/>
            <div className={classNames(styles.container, containerClassName)}>
                <div className={classNames(styles.popup, className)}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default Popup;
