import React, { useState } from "react";
import styles from "./CalendarPlaylist.module.scss";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import NewCalendar from "../../../../../components/common/NewCalendar";
import classNames from "class-names";
import Checkbox from "../../../../../components/common/Checkbox/NewCheckbox";
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import { showModal } from "../../../../../redux/actions/ui/modal/modal";
import {connect, useDispatch} from "react-redux";
import btnImage from "../../../../../img/icons/calendar-icon.svg"
import { REPEAT_TYPE } from "../../../../../constants/repeatTypes";
import CalendarPlaylistRepeat from "../../../../../components/CalendarPlaylistRepeat";
import {sendObjectPlaylist} from "../../../../../redux/modules/background/background-actions";
import moment from "moment";

const breadcrumbsRoutes = (objectId, playlistId) => (
    [
        {
        name: "Корпоративное радио",
        href: "#",
        },
        {
            name: "Объекты",
            href: "/radio/objects",
        },
        {
            name: "Страница объекта",
            href: `/radio/objects/${objectId}`,
        },
        {
            name: "Фон",
            href: `/radio/object/background/${objectId}`,
        },
        {
            name: 'Список плейлистов',
            href: `/radio/object/background/${objectId}/playlists`
        },
        {
            name: 'Время эфира',
            href: `/radio/object/background/${objectId}/playlists/calendar/${playlistId}`
        }
    ]
);

const mapDispatchToProps = (dispatch) => ({
    showModal: (data) => dispatch(showModal(data)),
});

const CalendarPlaylist = ({ showModal }) => {
    let { objectId, playlistId } = useParams();
    const history = useHistory();
    const { state } = useLocation();
    const [currentDate, setCurrentDate] = useState(state ? new Date(state?.date) : new Date());
    const [formCalendar, onOpen] = useState(true);
    const [selectedOption, setSelectedOption] = useState("actual");
    const dispatch = useDispatch();

    // const [startTime, setStartTime] = useState('09:00');
    // const [endTime, setEndTime] = useState('18:00');

    const [repeatType, setRepeatType] = useState(REPEAT_TYPE.NEVER)

    const onDateChange = (newDate) => setCurrentDate(newDate);

    // const normalizeTime = (time) => {
    //     const hours = time.split(':')[0].substring(0, 2);
    //     const hoursNumber = Number(hours);
    //
    //     if (isNaN(hoursNumber) || hoursNumber > 23) {
    //         return '00:00';
    //     }
    //
    //     return hours + ':00';
    // };

    // const onStartChange = e => setStartTime(normalizeTime(e.target.value));
    // const onEndChange = e => setEndTime(normalizeTime(e.target.value));

    const onSubmit = () => {
        const dateTo = currentDate;
        const params = {
            playlistId: playlistId,
            usedByDefault: false,
            dateFrom: moment(currentDate).format('YYYY-MM-DD'),
            dateTo: moment(dateTo.setDate(dateTo.getDate() + 1)).format('YYYY-MM-DD'),
        };

        dispatch(sendObjectPlaylist(objectId, params));
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes(objectId, playlistId)} />
            <div className={styles.elements}>
                <span>{currentDate.toLocaleDateString()} год</span>
                <button className={styles.btn} onClick={() => onOpen(!formCalendar)}>
                    <img src={btnImage} alt="button"/>
                </button>
            </div>
            <div className={classNames(styles.dateRange, {[styles.calendar]: formCalendar})}>
                <NewCalendar
                    className={styles.dates}
                    currentDate={currentDate}
                    onChange={onDateChange} />
            </div>
            <div className={styles.listOptions}>
                <Checkbox type={"card"}
                          checked={selectedOption === "actual"}
                          onChange={() => setSelectedOption("actual")}
                          className={styles.checkboxDay}>
                    <p>Весь день</p>
                </Checkbox>
                {/*<div className={styles.timeBlock} onClick={() => setSelectedOption("non-actual")}>*/}
                {/*    <div className={styles.choiceTime}>*/}
                {/*        <p>Время</p>*/}
                {/*        <input onChange={onStartChange}*/}
                {/*               className={styles.input}*/}
                {/*               value={startTime}*/}
                {/*               type="time"*/}
                {/*        />*/}
                {/*        <span> - </span>*/}
                {/*        <input*/}
                {/*            onChange={onEndChange}*/}
                {/*            className={classNames(styles.input, styles.lastInput)}*/}
                {/*            value={endTime}*/}
                {/*            type="time"*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <Checkbox*/}
                {/*        type={"card"}*/}
                {/*        checked={selectedOption === "non-actual"}*/}
                {/*        onChange={() => setSelectedOption("non-actual")}*/}
                {/*        className={styles.checkboxTime}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <div className={styles.blockTime}>
                <div className={styles.blockCheckbox}>
                    <div className={styles.blockTop}>
                        <h5 className={styles.blockCheckboxTitle}>Повторять</h5>
                    </div>
                    <Checkbox className={styles.listCheckbox}
                              name="status"
                              checked={repeatType === REPEAT_TYPE.NEVER}
                              onChange={() => setRepeatType(REPEAT_TYPE.NEVER)}>
                        Никогда
                    </Checkbox>
                    <Checkbox className={styles.listCheckbox}
                              name="status"
                              checked={repeatType === REPEAT_TYPE.DAILY}
                              onChange={() => setRepeatType(REPEAT_TYPE.DAILY)}>
                            Ежедневно
                    </Checkbox>
                    {/*<Checkbox*/}
                    {/*    className={styles.listCheckbox}*/}
                    {/*    name="status"*/}
                    {/*    checked={repeatType === REPEAT_TYPE.WEEKLY}*/}
                    {/*    onChange={() => setRepeatType(REPEAT_TYPE.WEEKLY)}*/}
                    {/*>*/}
                    {/*    Еженедельно*/}
                    {/*</Checkbox>*/}
                    {/*<Checkbox*/}
                    {/*    className={styles.listCheckbox}*/}
                    {/*    name="status"*/}
                    {/*    checked={repeatType === REPEAT_TYPE.MONTHLY}*/}
                    {/*    onChange={() => setRepeatType(REPEAT_TYPE.MONTHLY)}*/}
                    {/*>*/}
                    {/*    <p>Ежемесячно</p>*/}
                    {/*</Checkbox>*/}
                    {/*<Checkbox*/}
                    {/*    className={styles.listCheckbox}*/}
                    {/*    name="status"*/}
                    {/*    checked={repeatType === REPEAT_TYPE.YEARLY}*/}
                    {/*    onChange={() => setRepeatType(REPEAT_TYPE.YEARLY)}*/}
                    {/*>*/}
                    {/*    <p>Ежегодно</p>*/}
                    {/*</Checkbox>*/}
                </div>
                <CalendarPlaylistRepeat className={styles.calendarPlaylistRepeat} repeatType={repeatType} selectedDate={currentDate}/>
            </div>
            <div className={styles.endBlock}>
                <div className={styles.buttons}>
                    <Link to={`/radio/object/background/${objectId}/playlists`}>
                        <button className={styles.back}>Назад</button>
                    </Link>
                    <button
                        className={styles.ready}
                        onClick={() => {
                            onSubmit();
                            showModal({
                                title: "Автоматическая корректировка",
                                text:
                                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc volutpat erat in ante pulvinar ornare. Duis porttitor molestie ex, et aliquam mi elementum tincidunt. Suspendisse id orci tellus.",
                                submitText: "Хорошо",
                                cancelText: "Отмена",
                                onSubmit: () => history.push(`/radio/object/background/${objectId}`)
                            })
                        }}
                    >
                        Готово
                    </button>
                </div>
                {/*<div className={styles.button}>*/}
                {/*    <button className={styles.save}*/}
                {/*            onClick={() =>*/}
                {/*                showModal({*/}
                {/*                    title: "Автоматическая корректировка",*/}
                {/*                    text:*/}
                {/*                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc volutpat erat in ante pulvinar ornare. Duis porttitor molestie ex, et aliquam mi elementum tincidunt. Suspendisse id orci tellus.",*/}
                {/*                    submitText: "Хорошо",*/}
                {/*                    cancelText: "Отмена"*/}
                {/*                })*/}
                {/*            }>Сохранить*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default connect(undefined, mapDispatchToProps)(CalendarPlaylist);
