import { createSelector } from "reselect";

const authSelector = (state) => state.authData;

export const authUniqueStateSelector = createSelector(
    authSelector,
    ({uniqueState}) => uniqueState,
);

export const accessTokenSelector = createSelector(
    authSelector,
    ({accessToken}) => accessToken,
);

export const refreshTokenSelector = createSelector(
    authSelector,
    ({refreshToken}) => refreshToken,
);
