import React from 'react';
import PropTypes from 'prop-types';
import styles from './ObjectCreateScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import Select from "../../../../components/common/Select";
import Checkbox from "../../../../components/common/Checkbox/NewCheckbox";
import Multiselect from "../../../../components/common/Multiselect";
import { useDispatch, useSelector } from "react-redux";
import { loadObjectCreateMetadata } from "../../../../redux/modules/radio/radio-actions";
import { objectCreateMetadataSelector } from "../../../../redux/modules/radio/radio-selectors";

const breadcrumbsRoutes = [
    {
        name: 'Корпоративное радио',
        href: '#'
    },
    {
        name: 'Объекты',
        href: '/radio/objects'
    },
    {
        name: 'Заказ объекта',
        href: '/radio/object/create'
    }
];

const TARIFF_LIST = [
    "Tarrifishe",
];

const PLAYLISTS = [
    {
        value: 1,
        label: 'test-playlist'
    },
    {
        value: 2,
        label: 'test-playlist'
    },
]

const ObjectCreateScene = props => {
    useSetCurrentSection(PAGE_SUBSECTIONS.OBJECTS);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(loadObjectCreateMetadata());
    }, [dispatch]);

    const formMetadata = useSelector(objectCreateMetadataSelector);

    const [formData, setFormData] = React.useState({});

    const onChange = (e, passedValue) => {
        const value = passedValue === undefined ? e.target.value : passedValue;
        setFormData({
            ...formData,
            [e.target.name]: value,
        });
    };


    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.objectForm}>
                <div className={styles.desktopCol}>
                    <Input
                        name="name"
                        label="Название объекта"
                        inputClassName={styles.input}
                        className={styles.inputWrapper}
                    />
                    <Input
                        name="region"
                        label="Регион"
                        inputClassName={styles.input}
                        className={styles.inputWrapper}
                    />
                    <Input
                        name="city"
                        label="Город"
                        inputClassName={styles.input}
                        className={styles.inputWrapper}
                    />
                    <Input
                        name="address"
                        label="Адрес"
                        inputClassName={styles.input}
                        className={styles.inputWrapper}
                    />
                    <Input
                        name="comment"
                        type="textarea"
                        label="Описание"
                        inputClassName={styles.input}
                        className={styles.inputWrapper}
                        maxLength={350}
                    />
                </div>
                <div className={styles.desktopCol}>
                    {formMetadata && formMetadata.map(metadataItem => (
                        <Input
                            name={metadataItem.id}
                            label={metadataItem.name}
                            inputClassName={styles.input}
                            className={styles.inputWrapper}
                        />
                    ))}
                    {/*<Checkbox*/}
                    {/*    type="card"*/}
                    {/*    checked={formData['internet'] || false}*/}
                    {/*    name="internet"*/}
                    {/*    onChange={(e) => {*/}
                    {/*        onChange(e, e.target.checked)*/}
                    {/*    }}*/}
                    {/*    className={styles.checkboxWrapper}*/}
                    {/*>*/}
                    {/*    <span className={styles.checkboxLabel}>*/}
                    {/*        Интернет*/}
                    {/*    </span>*/}
                    {/*</Checkbox>*/}
                </div>
                <Button
                    href="/radio/objects"
                    className={styles.button}
                >
                    Заказать
                </Button>
            </div>
        </div>
    );
};

ObjectCreateScene.propTypes = {};

export default ObjectCreateScene;
