import React, {useEffect, useState} from 'react';
import styles from './BalanceHistory.module.scss';
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../constants/pageTypes";
import Breadcrumbs from "../../../components/Breadcrumbs";
import CurrentBalance from "../../../components/Profile/CurrentBalance";
import { Link } from "react-router-dom";
import ListContainer from "../../../components/ListContainer";
import BalanceHistoryCard, { OPERATION_TYPE } from "../../../components/Cards/BalanceHistoryCard";
import BalanceHistoryFilter from "../../../components/Profile/BalanceHistoryFilter";
import Button from "../../../components/common/Button";
import {useSelectableList} from "../../../hooks/useSelectableList";
import CardCheckbox from "../../../components/Cards/CardCheckbox";
import {useDispatch, useSelector} from "react-redux";
import {profileSelectorBills} from "../../../redux/modules/profile/profile-selectors";
import {loadProfileBills} from "../../../redux/modules/profile/profile-actions";
import Spinner from "../../../components/Spinner";

const breadcrumbsRoutes = [
    {
        name: 'Личный кабинет',
        href: '/profile'
    },
    {
        name: 'История операций',
        href: '/profile/history'
    }
];

const BalanceHistory = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.PROFILE_HISTORY);
    const [active, isActive] = useState(false);
    const [onToggle, toggleAll, selectedIds] = useSelectableList();
    const bills = useSelector(profileSelectorBills);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadProfileBills());
    }, [dispatch]);

    if (!bills) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div>
                <Link to={'/profile'}>
                    <CurrentBalance isOpen={true}/>
                </Link>
                <BalanceHistoryFilter
                    title={<>
                        <div className={styles.payBlockInfo}>
                            <div className={!active && styles.active} onClick={() => isActive(!active)}>
                                <p>К ОПЛАТЕ</p>
                            </div>
                            <div className={active && styles.active} onClick={() => isActive(!active)}>
                                <p>ИСТОРИЯ ОПЕРАЦИЙ</p>
                            </div>
                        </div>
                    </>}
                />
            </div>
            <ListContainer className={styles.listContainer}>
                {!active && bills && bills.items.map((item, index) => (
                    <BalanceHistoryCard
                        responsive
                        billDate={item.billDate}
                        orderId={item.id}
                        operationType={OPERATION_TYPE.PAYMENT}
                        key={index}
                        amount={item.amount}
                    />
                ))}
                {/*{bills && bills.items.map((item, index) => (*/}
                {/*    <BalanceHistoryCard*/}
                {/*        responsive*/}
                {/*        billDate={item.billDate}*/}
                {/*        orderId={item.orderId}*/}
                {/*        operationType={OPERATION_TYPE.INCOME}*/}
                {/*        key={index}/>*/}
                {/*))}*/}
                {/*{active && <>*/}
                {/*    {bills && bills.items.map((item, index) => (*/}
                {/*        <BalanceHistoryCard*/}
                {/*            responsive*/}
                {/*            operationType={OPERATION_TYPE.PAYMENT}*/}
                {/*            billDate={item.billDate}*/}
                {/*            orderId={item.orderId}*/}
                {/*            key={index}*/}
                {/*            onClick={() => {*/}
                {/*                onToggle(item);*/}
                {/*            }}*/}
                {/*            checkbox={<CardCheckbox*/}
                {/*                onChange={onToggle}*/}
                {/*                checked={selectedIds.includes(item)}/>}*/}
                {/*        />*/}
                {/*    ))}*/}
                {/*</>*/}
                {/*}*/}
                {/*{active && bills.items.length > 0 &&*/}
                {/*    <div className={styles.buttons}>*/}
                {/*        {selectedIds.length === 0 && <Button className={styles.payButton}>Выберите счета к оплате</Button>}*/}
                {/*        {selectedIds.length > 0 && <Button className={styles.priceButton}>Оплатить 15 000 ₽</Button>}*/}
                {/*    </div>*/}
                {/*}*/}
            </ListContainer>
        </div>
    );
}

export default BalanceHistory;
