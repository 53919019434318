import React, { useState } from 'react';
import styles from './ObjectScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import classNames from 'class-names';
import ObjectScenePlayer from "../../../../components/ObjectScene/ObjectScenePlayer";
import Tabs from "../../../../components/Tabs";
import Tab from "../../../../components/Tabs/Tab";
import ObjectSceneInfo from "../../../../components/ObjectScene/ObjectSceneInfo";
import BroadcastManagementScene from "../BroadcastManagementScene";
import Button from "../../../../components/common/Button";
import { Link } from "react-router-dom";
import disableSoundIcon from '../../../../img/icons/disable-sound-icon.svg';
import enableSoundIcon from '../../../../img/icons/enable-sound-icon.svg';
import { radioDataSelector, radioParamsSelector } from "../../../../redux/modules/radio/radio-selectors";
import { loadRadioData, setRadioParams } from "../../../../redux/modules/radio/radio-actions";
import { RADIO_ENTITY } from "../../../../constants/radioEntities";
import { connect } from "react-redux";
import { useRadioMetadata } from "../../../../hooks/radio/useRadioMetadata";
import Spinner from "../../../../components/Spinner";
import { getOptionalValue } from "../../../../utils/getOptionalValue";
import { isRadioPageLoaded } from "../../../../redux/modules/radio/radio-helpers";

const breadcrumbsRoutes = (objectId) => (
    [{
        name: 'Корпоративное радио',
        href: '#'
    }, {
        name: 'Объекты',
        href: '/radio/objects'
    }, {
        name: 'Страница объекта',
        href: `/radio/objects/${objectId}`
    }])
;

const mapStateToProps = (state) => ({
    entityData: radioDataSelector(state),
    params: radioParamsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadObjectData: (id) => dispatch(loadRadioData(RADIO_ENTITY.OBJECT, id)),
    setParams: (params) => dispatch(setRadioParams(params)),
})

const ObjectScene = ({
    match,
    entityData,
    params,
    loadObjectData,
    setParams,
}) => {
    const { id } = match.params;
    useRadioMetadata();

    React.useEffect(() => {
        loadObjectData(id)
    }, [id, loadObjectData]);

    const [muted, setMuted] = useState(false);
    const [isRecording, setIsRecording] = useState(false);

    const isLoaded = isRadioPageLoaded(RADIO_ENTITY.OBJECT, entityData);

    const objectInfo = entityData && entityData.data;

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes(id)}/>
            {isLoaded ? (
                <>
                    <div className={styles.playerContainer}>
                        <div className={classNames(styles.flexContainer, styles.headContent)}>
                            <div className={styles.title}>
                                {getOptionalValue(objectInfo, 'title')}
                                <div className={styles.address}>
                                    {getOptionalValue(objectInfo, 'address')}
                                </div>
                            </div>
                            <div className={classNames(styles.muteContainer, {[styles.muted]: muted})} onClick={() => setMuted(!muted)}>
                                <div className={styles.soundButtonContainer}>
                                    <img alt={''} className={styles.soundButton} src={ !muted ? disableSoundIcon : enableSoundIcon }/>
                                </div>
                                <div className={styles.muteText}>{muted ? 'Включить' : 'Отключить'} звук</div>
                            </div>
                            <Link to={`/radio/objects/${id}/priority`} className={styles.priorityButton}>
                                <div className={styles.priorityIcon}/>
                                Приоритетные объявления
                            </Link>
                            <Link to={`/radio/object/background/${id}`} className={styles.backgroundButton}>
                                <div className={styles.backgroundIcon}>
                                    Фон
                                </div>
                            </Link>
                        </div>
                        <div className={classNames(styles.flexContainer, styles.info)}>
                            <div className={styles.flexCenter}>
                                <div className={styles.objectIcon}/>
                                ID {getOptionalValue(objectInfo, 'id')}
                            </div>
                            <div className={classNames(styles.statusContainer)}>
                                {getOptionalValue(objectInfo, 'status.name')}
                                {objectInfo.online && (<>
                                        <div className={styles.onlineStatus}/>
                                        Online
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={styles.pageContent}>
                            <ObjectScenePlayer id={id}
                                               isMuted={muted}
                                               setRecording={setIsRecording}
                                               isRecording={isRecording}/>
                            <div className={classNames(styles.favoriteAdContainer, styles.hideMobile, styles.hideTablet)}>
                                <div className={styles.favoriteAdTitle}>
                                    Избранное объявление
                                </div>
                                <div className={styles.favoriteAd}>
                                    Земля 43 га для ведения крестьянского (фермерского) хозяйства
                                    <div className={styles.favoriteAdIcon}/>
                                </div>
                                <div className={styles.microphoneIconContainer}
                                     onClick={() => setIsRecording(!isRecording)}>
                                    <div className={classNames(styles.microphoneIcon, {[styles.active]: isRecording})}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.tabsContainer}>
                        <Tabs>
                            <Tab title={'Управление эфиром'} hideOnMobile>
                                <BroadcastManagementScene objectId={id}/>
                            </Tab>
                            <Tab title={'Избранное'} hideOnDesktop>
                                <div className={styles.favoriteAdContainer}>
                                    <div className={styles.favoriteAd}>
                                        Земля 43 га для ведения крестьянского (фермерского) хозяйства
                                        <div className={styles.favoriteAdIcon}/>
                                    </div>
                                    <div className={classNames(styles.microphoneIconContainer, {[styles.active]: isRecording})}
                                         onClick={() => setIsRecording(!isRecording)}>
                                        <div className={classNames(styles.microphoneIcon, {[styles.active]: isRecording})}/>
                                    </div>
                                </div>
                            </Tab>
                            <Tab title={'Информация об объекте'}>
                                <Button className={styles.maintenanceButton}
                                        href={'/radio/object/maintenance'}>
                                    Техобслуживание
                                </Button>
                                <ObjectSceneInfo/>
                            </Tab>
                        </Tabs>
                    </div>
                </>
            ) : (
                <div className={styles.spinnerContainer}>
                    <Spinner/>
                </div>
            )}

        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectScene);
