import { createSelector } from "reselect";

const radioSelector = (state) => state.radio;

export const radioMetadataSelector = createSelector(
    radioSelector,
    ({metadata}) => metadata,
);

export const radioParamsSelector = createSelector(
    radioSelector,
    ({params}) => params,
);

export const radioDataSelector = createSelector(
    radioSelector,
    ({data}) => data,
);

export const radioFormDataSelector = createSelector(
    radioSelector,
    ({formData}) => formData,
);

export const objectCreateMetadataSelector = createSelector(
    radioSelector,
    ({objectCreateMetadata}) => objectCreateMetadata
)
