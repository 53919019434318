import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './RecordsControls.module.scss';
import Filter from "../Filter";
import classNames from 'class-names';

const Search = ({
    showSearch = true,
    showFilter = true,
    actions,
    containerClassName,
    children,
    controlsClassName,
    searchClassName,

    onChange,
    value,
    ...props
}) => {
    const [filterOpen, setFilterOpen] = useState(false);

    return (
        <div className={classNames(styles.container, containerClassName)}>
            <div className={classNames(styles.controlsContainer, controlsClassName)}>
                <div className={classNames(styles.searchContainer, searchClassName)}>
                    {showSearch && (
                        <div className={styles.inputContainer}>
                            <input
                                onChange={onChange}
                                className={styles.input}
                                placeholder="Поиск"
                            />
                            <div className={styles.searchIcon}/>
                        </div>
                    )}
                    {showFilter && <div className={classNames(styles.filterIcon, { [styles.open]: filterOpen })}
                                        onClick={() => setFilterOpen(!filterOpen)}/>}
                </div>
                {actions}
            </div>
            {showFilter && <Filter isOpen={filterOpen} closeFilter={() => setFilterOpen(false)}/>}
            {children}
        </div>
    );
};

Search.propTypes = {
    showSearch: PropTypes.bool,
    showFilter: PropTypes.bool,
    actions: PropTypes.node,
    containerClassName: PropTypes.string,
    controlsClassName: PropTypes.string
};

export default Search;
