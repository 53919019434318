import React, { useEffect } from 'react';
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../constants/pageTypes";
import Breadcrumbs from "../../../components/Breadcrumbs";
import styles from './ProfileScene.module.scss';
import CurrentBalance from "../../../components/Profile/CurrentBalance";
import EditInfoButton from "../../../components/Profile/EditInfoButton";
import { Link } from "react-router-dom";
import BalanceHistoryCard, { OPERATION_TYPE } from "../../../components/Cards/BalanceHistoryCard";
import ListContainer from "../../../components/ListContainer";
import { useSelectableList } from "../../../hooks/useSelectableList";
import CardCheckbox from "../../../components/Cards/CardCheckbox";
import Button from "../../../components/common/Button";
import MessageIcon from '../../../img/icons/message-profile-icon.svg';
import HistoryIcon from '../../../img/icons/history-profile-icon.svg';
import LogoutIcon from '../../../img/icons/logout-icon.svg';
import BalanceHistoryFilter from "../../../components/Profile/BalanceHistoryFilter";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../../redux/modules/auth/auth-thunk";
import { profileSelectorBills, profileSelectorData } from "../../../redux/modules/profile/profile-selectors";
import {loadProfile, loadProfileBills, setSelectedObject} from "../../../redux/modules/profile/profile-actions";
import Spinner from "../../../components/Spinner";

const breadcrumbsRoutes = [{
    name: 'Личный кабинет',
    href: '#'
}, {
    name: 'Персональные данные',
    href: '/profile'
}];

const testCardsIds = [...Array(4)].map(() => Math.floor(Math.random() * 1000));

const ProfileScene = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.PROFILE);
    const [onToggle, , selectedIds] = useSelectableList(testCardsIds);
    const dispatch = useDispatch();
    const data = useSelector(profileSelectorData);
    const bills = useSelector(profileSelectorBills);

    const onLogOut = () => {
        dispatch(logOut());
    };

    useEffect(() => {
        dispatch(loadProfile());
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadProfileBills());
    }, [dispatch]);

    if (!data) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.pageContent}>
                <div className={styles.blockInfo}>
                    <div className={styles.content}>
                        <EditInfoButton>
                            {data.name}
                        </EditInfoButton>
                        <p>{data.fio}</p>
                        <div className={styles.text}>
                            <p>{data.email}</p>
                            <p>|</p>
                            <p>+7{data.phone}</p>
                        </div>
                    </div>
                    <div className={styles.blockLinks}>
                        <Link className={styles.link} to={'/profile/feedback'}>
                            <img className={styles.linkIcon} src={MessageIcon}/>
                            <p>Напишите нам</p>
                        </Link>
                        <Link className={styles.link} to={'/profile/history'}>
                            <img className={styles.linkIcon} src={HistoryIcon}/>
                            <p>История операций</p>
                        </Link>
                        <div className={styles.link} onClick={onLogOut}>
                            <img className={styles.linkIcon} src={LogoutIcon}/>
                            <p>Выйти</p>
                        </div>
                    </div>
                </div>
                <div className={styles.historyContainer}>
                    <Link to={'/profile/history'}>
                        <CurrentBalance value={'Ближайшие счета к оплате'} isOpen={false}/>
                    </Link>
                    <BalanceHistoryFilter title="Операции" />
                    <ListContainer className={styles.historyList} autoHeight={false}>
                        {bills && bills.items.map((item, index) => (
                            <BalanceHistoryCard
                                operationType={OPERATION_TYPE.PAYMENT}
                                billDate={item.billDate}
                                orderId={item.id}
                                amount={item.amount}
                                // onClick={() => onToggle(item)}
                                key={index + ''}
                                // checkbox={
                                //     <CardCheckbox
                                //         onChange={onToggle}
                                //         checked={selectedIds.includes(item)} />
                                // }
                            />
                        ))}
                        {selectedIds.length > 0 &&
                            <Button className={styles.buttonPay}>Оплатить 15 000 ₽</Button>
                        }
                    </ListContainer>
                </div>
            </div>
        </div>
    );
};

export default ProfileScene;
