import { applyMiddleware, createStore } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from 'redux-thunk';
import { createLogger } from "redux-logger";
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { rootReducer } from "../reducers/rootReducer";

export const configureStore = (history, initialState) => {

    const store = createStore(
        rootReducer,
        initialState,
        composeWithDevTools(applyMiddleware(
            routerMiddleware(history),
            createLogger(),
            thunk,
        )),
    );

    return store;
};
