import React, { useState } from 'react';
import styles from './Tabs.module.scss';
import classNames from 'class-names';
import { useMediaQuery } from "beautiful-react-hooks";

const Tabs = ({ children }) => {
    const [activeTab, setActiveTab] = useState(0);
    const isDesktop = useMediaQuery('(min-width: 1024px)');

    const displayedTabs = children.filter(tab => {
        if(tab.props.hideOnMobile && !isDesktop)
            return false;
        return !(tab.props.hideOnDesktop && isDesktop);
    });

    return (
        <div className={styles.container}>
            <div className={styles.tabsHeadContainer}>
                {displayedTabs.map((child, index) => (
                    <div className={classNames(styles.tabTitle,
                        { [styles.active]: index === activeTab })}
                         key={index}
                         onClick={() => setActiveTab(index)}>
                        {child.props.title}
                    </div>
                ))}
            </div>
            {displayedTabs[activeTab]}
        </div>
    );
};

export default Tabs;
