import { UI_HIDE_MODAL, UI_OPEN_MODAL } from "../../action-types";

export const showModal = (data) => ({
    type: UI_OPEN_MODAL,
    payload: data
});

export const hideModal = () => ({
    type: UI_HIDE_MODAL
});
