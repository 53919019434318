import React, { useState, useEffect, useRef } from 'react';
import styles from './HeaderMenu.module.scss';
import classNames from 'class-names';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { PAGE_SECTIONS } from "../../../constants/pageTypes";
import crossIcon from '../../../img/icons/menu-icon-close.svg';
import sandwichIcon from '../../../img/icons/menu-icon.svg';

const mapStateToProps = ({ ui }) => ({
    currentSection: ui.currentSection
});

const HeaderMenu = ({ currentSection }) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const backgroundFill = useRef(null);

    useEffect(
        () => {
            if (!backgroundFill.current) return;

            const {classList} = backgroundFill.current;
            if (menuVisible) {
                classList.add(styles.visible);
            }
        }, [menuVisible]
    );

    const parentSection = currentSection.parent;
    const {CONTENT, RADIO, PROFILE, BACKGROUND} = PAGE_SECTIONS;

    return (
        <>
            <div className={styles.container}>
                <Link to={CONTENT.href}
                      className={classNames(styles.sectionRoute,
                          { [styles.active]: parentSection.name === CONTENT.name })}>
                    {CONTENT.name}
                </Link>
                <Link to={RADIO.href}
                      className={classNames(styles.sectionRoute,
                          { [styles.active]: parentSection.name === RADIO.name })}>
                    {RADIO.name}
                </Link>
                <Link to={BACKGROUND.href}
                      className={classNames(styles.sectionRoute,
                          { [styles.active]: parentSection.name === BACKGROUND.name })}>
                    {BACKGROUND.name}
                </Link>
                <Link to={PROFILE.href} className={classNames(styles.profileIcon, styles.sectionRoute,
                    {[styles.active]: parentSection.name === PROFILE.name})}>
                    <span className={styles.hideDesktop}>{PROFILE.name}</span>
                </Link>
                <img alt={''} className={classNames(styles.icon, {[styles.open]: menuVisible})} onClick={() => setMenuVisible(!menuVisible)} src={menuVisible ? crossIcon : sandwichIcon}/>
            </div>
            {menuVisible && <div ref={backgroundFill}
                                 className={styles.backgroundFill}
                                 onClick={() => setMenuVisible(false)}
            />}
            <div className={classNames(styles.menu, { [styles.menuVisible]: menuVisible })}>
                {Object.values(PAGE_SECTIONS).map((section, idx) =>
                    <React.Fragment key={idx}>
                        <Link to={section.href} className={styles.item}>
                            {section.name}
                        </Link>
                        {section.links && section.name === parentSection.name && section.links.map((link, index) =>
                            <Link to={link.href}
                                  key={index}
                                  className={styles.subItem}
                                  onClick={() => setMenuVisible(false)}>
                                {link.name}
                            </Link>)}
                    </React.Fragment>
                )}
                {/*<div className={styles.item}>
                    Коммуникации
                </div>*/}
            </div>
            {parentSection.links &&
            <div className={styles.tabs}>
                {parentSection.links.map((link, idx) => (
                    <Link to={link.href}
                          className={classNames(styles.tabsItem, {[styles.tabsItemSelected]: currentSection.name === link.name})}
                             key={idx}>
                        {link.name}
                    </Link>
                ))}
            </div>
            }
        </>
    );
};

export default connect(mapStateToProps)(HeaderMenu);
