import React from 'react';
import styles from './Filter.module.scss';
import classNames from 'class-names';
import DatePicker from "../common/DatePicker";
import Checkbox from "../common/Checkbox";

const Filter = ({ isOpen, closeFilter }) => {

    return (
        <div className={classNames(styles.container, { [styles.open]: isOpen })}>
            <div className={styles.flexContainer}>
                <DatePicker className={styles.datePicker}/>
                <form className={styles.form}>
                    <div className={styles.section}>
                        <div className={styles.sectionTitle}>
                            Статус
                        </div>
                        <div className={styles.sectionContent}>
                            <Checkbox name="status">
                                Архив
                            </Checkbox>
                            <Checkbox name="status">
                                Ожидает модерации
                            </Checkbox>
                            <Checkbox name="status">
                                В работе
                            </Checkbox>
                            <Checkbox name="status">
                                Заказ создан
                            </Checkbox>
                            <Checkbox name="status">
                                В производстве
                            </Checkbox>
                            <Checkbox name="status">
                                Ожидает подтверждения
                            </Checkbox>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.sectionTitle}>
                            Состояние
                        </div>
                        <div className={styles.sectionContent}>
                            <Checkbox name="state">
                                В эфире
                            </Checkbox>
                            <Checkbox name="state">
                                Не в эфире
                            </Checkbox>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.sectionTitle}>
                            Тип
                        </div>
                        <div className={styles.sectionContent}>
                            <Checkbox name="type">
                                Игровой
                            </Checkbox>
                            <Checkbox name="type">
                                Вокальный
                            </Checkbox>
                            <Checkbox name="type">
                                Информационный
                            </Checkbox>
                            <Checkbox name="type">
                                Объявление
                            </Checkbox>
                            <Checkbox name="type">
                                Имиджевый
                            </Checkbox>
                            <Checkbox name="type">
                                Тестовый_004
                            </Checkbox>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.sectionTitle}>
                            Категория
                        </div>
                        <div className={styles.sectionContent}>
                            <Checkbox name="category">
                                Автосалоны
                            </Checkbox>
                            <Checkbox name="category">
                                Автотовары
                            </Checkbox>
                            <Checkbox name="category">
                                Аптеки
                            </Checkbox>
                            <Checkbox name="category">
                                Аренда помещений
                            </Checkbox>
                            <Checkbox name="category">
                                Бильярд
                            </Checkbox>
                            <Checkbox name="category">
                                Боулинг
                            </Checkbox>
                            <Checkbox name="category">
                                Бытовая техника
                            </Checkbox>
                        </div>
                    </div>
                </form>
            </div>
            <div className={styles.buttonsContainer}>
                <div className={styles.button} onClick={closeFilter}>
                    Применить
                </div>
                <div className={styles.button} onClick={closeFilter}>
                    Сбросить
                </div>
            </div>
        </div>
    );
};

export default Filter;
