import React from 'react';
import PropTypes from 'prop-types';
import styles from './TimelineWrapper.module.scss';
import moment from "moment";
import Timeline, {
    TimelineHeaders,
    DateHeader
} from "react-calendar-timeline";
import 'react-calendar-timeline/lib/Timeline.css';
import { Link } from "react-router-dom";

const timeFormat = (data, unit, labelWidth) => {
    if (unit === 'day')
        if (labelWidth < 50)
            return data[0].format('DD');
        else if (labelWidth < 100)
            return data[0].format('DD.MM');
        else return data[0].format('DD.MM.YYYY');
    else
        return data[0].format('MM.YYYY');
};

const itemRenderer = ({ item, getItemProps }) => {
    const { height, left, top, width, position, zIndex } = getItemProps(item.itemProps).style;
    return (
        <Link to={'/radio/mediaplan'}>
            <div {...getItemProps(item.itemProps)}
                 style={{ height, left, top, width, position, zIndex, backgroundColor: item.color }}
                 className='rct-item'/>
        </Link>
    );
};

const TimelineWrapper = ({ data }) => {
    return (
        <div className={styles.container}>
            <Timeline
                groups={data.groups}
                items={data.items}
                defaultTimeStart={moment().add(-5, 'day')}
                defaultTimeEnd={moment().add(5, 'day')}
                canResize={false}
                itemRenderer={itemRenderer}
                traditionalZoom
                minZoom={60 * 60 * 24 * 10 * 1000}
                maxZoom={60 * 60 * 24 * 30 * 10 * 1000}>
                <TimelineHeaders>
                    <DateHeader labelFormat={timeFormat}
                                height={30}
                                className={styles.dateHeader}/>
                </TimelineHeaders>
            </Timeline>
        </div>
    );
};

TimelineWrapper.propTypes = {
    data: PropTypes.object
};

export default TimelineWrapper;
