import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './RecordDateCard.module.scss'
import GenericCard from "../GenericCard";
import Popup from "../../Popup";
import Button from "../../common/Button";

const RecordDateCard = ({ id, startDate, endDate }) => {
    const [popupShown, setPopupShown] = useState(false);

    return (
        <GenericCard
            className={styles.card}
            headClassName={styles.head}
            headContent={<>
                Дата выхода ролика: ID {id}
                <div className={styles.menuButton} onClick={() => setPopupShown(!popupShown)}>
                    {popupShown &&
                    <Popup closePopup={() => setPopupShown(false)} className={styles.popup}>
                        <div className='popup-menu-item'>
                            Удалить дату выхода
                        </div>
                    </Popup>}
                </div>
            </>}>
            <div className={styles.dataContainer}>
                <div className={styles.infoContainer}>
                    <div className={styles.infoBlock}>
                        <div className={styles.infoTitle}>
                            Начало:
                        </div>
                        <div className={styles.infoValue}>
                            {startDate}
                        </div>
                    </div>
                    <div className={styles.infoBlock}>
                        <div className={styles.infoTitle}>
                            Окончание:
                        </div>
                        <div className={styles.infoValue}>
                            {endDate}
                        </div>
                    </div>
                </div>
                <Button className={styles.deleteButton}>
                    Удалить
                </Button>
            </div>
        </GenericCard>)
};

RecordDateCard.propTypes = {
    id: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
};

export default RecordDateCard;
