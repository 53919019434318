import React, {useEffect} from 'react';
import styles from './DocumentsScene.module.scss';
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../constants/pageTypes";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Document from "../../../components/Profile/Document";
import {useDispatch, useSelector} from "react-redux";
import {profileSelectorDocuments} from "../../../redux/modules/profile/profile-selectors";
import {loadProfileDocuments} from "../../../redux/modules/profile/profile-actions";
import Spinner from "../../../components/Spinner";

const breadcrumbsRoutes = [{
    name: 'Личный кабинет',
    href: '#'
}, {
    name: 'Документы',
    href: '/profile/documents'
}];

const DocumentsScene = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.DOCUMENTS);
    const dispatch = useDispatch();
    const documents = useSelector(profileSelectorDocuments);

    useEffect(() => {
        dispatch(loadProfileDocuments());
    }, [dispatch]);

    if (!documents) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes} className={styles.breadcrumbs}/>
            <div className={styles.documentsList}>
                {documents && documents.map((item, index) =>
                    <Document title={item.title} link={item.link} key={index}/>)}
            </div>
        </div>
    );
};

export default DocumentsScene;
