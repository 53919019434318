import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GenericCard from "../GenericCard";
import styles from './RecordCard.module.scss';
import RecordStatus from "./RecordStatus";
import Popup from "../../Popup";
import { Link } from "react-router-dom";
import classNames from "class-names";
import recordIcon from "../../../img/icons/record-icon.svg";
import { RECORD_ONLINE_STATUS } from "../../../constants/recordTypes";

const mockData = {
    name: "02135-14 Пакет Праздничный День Святого Валентина Грааль",
    type: "Информационный",
    category: "Бытовая химия",
    enabled: true,
    desc: "Представляется логичным, что рекламная поддержка развивает конвергентный повторный контакт. Повышение жизненных стандартов искажает показ баннера. Более того, креативная концепция подсознательно синхронизирует сублимированный формат события. В общем, агентская комиссия индуктивно специфицирует инвестиционный продукт, полагаясь на инсайдерскую информацию. Производство существенно обуславливает принцип восприятия. Традиционный канал, следовательно, ригиден. Такое понимание ситуации восходит к Эл Райс.",
    status: "На согласовании",
    info: {
        frequency: "19200 Гц",
        size: "26кб",
        format: "MP3",
        bitrate: "3500 bps",
        codec: "MPEG",
        soundType: "Стерео",
        length: "12сек"
    },
    id: 51460,
    location: "ACTIVE",
    online: RECORD_ONLINE_STATUS.OFFLINE
};

const RecordCard = ({
    data = mockData,
    getRecordData,
    checkbox,
    getMenuOptions,
    icon = recordIcon,
    showStatus,
    ...props
}) => {
    const [popupShown, setPopupShown] = useState(false);
    const { name, id, dateFrom, dateTo, onDelete } = props;

    const withMenu = !!getMenuOptions;

    return (
        <GenericCard className={styles.card}
                     headClassName={classNames(styles.head, {[styles.headOnline]: data.online === RECORD_ONLINE_STATUS.ONLINE})}
                     headContent={<>
                         <div className={styles.headInfo}>
                             {withMenu && checkbox && <div className={styles.checkboxLeftContainer}>{checkbox}</div>}
                             <div className={styles.icon} style={{ backgroundImage: `url(${icon})` }}/>
                             ID {id}
                             {showStatus && ` • ${data.status}`}
                             {showStatus && <RecordStatus onlineStatus={data.online} status={data.status}/>}
                             {withMenu ? <div className={styles.menuButton} onClick={() => setPopupShown(!popupShown)}>
                                 {popupShown &&
                                     <Popup closePopup={() => setPopupShown(false)} className={styles.recordPopup}>
                                         {getMenuOptions(id, onDelete)}
                                     </Popup>
                                 }
                             </div> : checkbox}
                         </div>
                     </>}>
            <div className={styles.contentContainer}>
                <Link to={`/records/${data.id}`}
                      className={styles.title}>
                    {name}
                </Link>
                <div className={styles.dataContainer}>
                    <div className={styles.player}>
                        <div
                            className={classNames(styles.playerIcon, { [styles.playerIconDisabled]: !(data.enabled || !showStatus) })}/>
                        {(data.enabled || !showStatus) ? "12 сек" : ""}
                        {(data.enabled || !showStatus) ? <input type='range' className={styles.playerRange}/> : null}
                    </div>
                    {getRecordData ? getRecordData(data).map((item, index) =>
                        (<div className={styles.infoBlock} key={index}>
                            <div className={styles.infoTitle}>
                                {item.label}:
                            </div>
                            <div className={styles.infoValue}>
                                {item.value}
                            </div>
                        </div>)
                    ) : <>
                        <div className={styles.infoBlock}>
                            <div className={styles.infoTitle}>
                                Тип ролика:
                            </div>
                            <div className={styles.infoValue}>
                                {data.type}
                            </div>
                        </div>
                        <div className={styles.infoBlock}>
                            <div className={styles.infoTitle}>
                                Категория:
                            </div>
                            <div className={styles.infoValue}>
                                {data.category}
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </GenericCard>
    );
};

RecordCard.propTypes = {
    data: PropTypes.object,
    checkbox: PropTypes.node,
    withMenu: PropTypes.bool,
    icon: PropTypes.string,
    getMenuOptions: PropTypes.func,
    getRecordData: PropTypes.func
};

export default RecordCard;
