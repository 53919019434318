import React, { useEffect } from 'react';
import styles from './ListPlaylists.module.scss'
import Breadcrumbs from "../../../../../components/Breadcrumbs"
import Search from "../../../../../components/Search/BackgroundSearch/index"
import { Link, useParams } from "react-router-dom";
import PlaylistItem from "../../../../../components/BackgroundPlaylistItem/index"
import { useDispatch, useSelector } from "react-redux";
import { purchasedPlaylists } from "../../../../../redux/modules/background/background-selectors";
import { loadPurchasedPlaylists } from "../../../../../redux/modules/background/background-actions";
import {useDebouncedCallback} from "../../../../../hooks/useDebouncedCallback";
import Spinner from "../../../../../components/Spinner";
import MaintenanceCard from "../../../../../components/Cards/MaintenanceCard";
import ListContainer from "../../../../../components/ListContainer";
import {useRadioObjects} from "../../../../../hooks/radio/useRadioObjects";

const ListPlaylists = () => {
    const dispatch = useDispatch();
    const playlists = useSelector(purchasedPlaylists);
    const [objects] = useRadioObjects();
    let { objectId } = useParams();

    const breadcrumbsRoutes = [{
        name: 'Корпоративное радио',
        href: '#'
    }, {
        name: 'Объекты',
        href: '/radio/objects'
    }, {
        name: 'Страница объекта',
        href: `/radio/objects/${objectId}`
    }, {
        name: 'Фон',
        href: `/radio/object/background/${objectId}`
    },  {
        name: 'Список плейлистов',
        href: `/radio/object/background/${objectId}/playlists`
    }];

    useEffect(() => {
        dispatch(loadPurchasedPlaylists(objectId));
    }, [dispatch, objectId]);

    // const setQuery = useDebouncedCallback((query) => {
        // dispatch(loadPurchasedPlaylists({title: query}));
    // }, 500);

    const setOptions = (options) => {
        dispatch(loadPurchasedPlaylists(options));
    }

    const setFilters = (filter) => {
        dispatch(loadPurchasedPlaylists())
    }

    if (!playlists) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes} className={styles.breadcrumbs}/>
            {/*<Search searchClassName={styles.search}*/}
            {/*    listPlaylistsObject*/}
            {/*    onChange={({target}) => setQuery(target.value)}*/}
            {/*/>*/}
            <div className={styles.playlists}>
                {!playlists?.items?.length && (
                    <p className={styles.noPlaylists}>Плейлисты отсутствуют</p>
                )}
                {playlists?.items?.map(item => (
                    <Link className={styles.playlistLink} to={`/radio/object/background/${objectId}/playlists/calendar/${item.id}`}>
                        <PlaylistItem
                            id={item.id}
                            title={item.title}
                            image={item.image}
                            objectsCount={item.objectsCount}
                            totalObjectsCount={objects?.totalEntities}
                            // imagePlaylistPlay={playlist.imagePlaylistPlay}
                            isActive
                        />
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default ListPlaylists;
