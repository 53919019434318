import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import styles from './BalanceHistoryFilter.module.scss';
import Popup from "../../Popup";
import {loadProfileBills} from "../../../redux/modules/profile/profile-actions";
import {useDispatch} from "react-redux";

const dateNow = new Date();
const defaultDateStart = dateNow.toISOString().split('T')[0];
const defaultDateEnd = new Date(dateNow.setMonth(dateNow.getMonth() + 1)).toISOString().split('T')[0];

const BalanceHistoryFilter = ({ title }) => {
    const [filterOpen, setFilterOpen] = useState(false);
    const [dateStart, setDateStart] = useState(defaultDateStart);
    const [dateEnd, setDateEnd] = useState(defaultDateEnd);
    const dispatch = useDispatch();
    const closeFilter = () => setFilterOpen(false);

    useEffect(() => {
        dispatch(loadProfileBills());
    }, [dispatch]);

    const onChange = (type, value) => {
        switch (type) {
            case 'start':
               dispatch(loadProfileBills({
                   billDateFrom: value,
                   billDateTo: dateEnd,
               }));
            case 'end':
                dispatch(loadProfileBills({
                    billDateFrom: dateStart,
                    billDateTo: value,
                }));
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {title}
            </div>
            <div className={styles.filtersButton} onClick={() => setFilterOpen(!filterOpen)}>
                Фильтры
            </div>
            {filterOpen &&
            <Popup
                closePopup={closeFilter}
                className={styles.popup}
                containerClassName={styles.popupContainer}>
                    <div className={styles.popupItem} onClick={closeFilter}>
                        Списания
                    </div>
                    {/*<div className={styles.popupItem} onClick={closeFilter}>*/}
                    {/*    Зачисления*/}
                    {/*</div>*/}
                    {/*<div className={styles.popupItem} onClick={closeFilter}>*/}
                    {/*    Эта неделя*/}
                    {/*</div>*/}
                    {/*<div className={styles.popupItem} onClick={closeFilter}>*/}
                    {/*    Этот месяц*/}
                    {/*</div>*/}
                    <div className={styles.popupItem}>
                        <div className={styles.popupDates}>
                            <input defaultValue={dateStart}
                                   type="date"
                                   className={styles.dateInput}
                                   onChange={(e) => {
                                       setDateStart(e.target.value);
                                       onChange('start', e.target.value);
                                   }} />
                            —
                            <input
                                defaultValue={dateEnd}
                                type="date"
                                className={styles.dateInput}
                                onChange={(e) => {
                                    setDateEnd(e.target.value);
                                    onChange('end', e.target.value);
                                }}
                            />
                        </div>
                    </div>
            </Popup>}
        </div>
    );
};

BalanceHistoryFilter.propTypes = {
    title: PropTypes.string
};

export default BalanceHistoryFilter;
