import React from 'react';
import PropTypes from 'prop-types';
import styles from './CurrentBalance.module.scss';
import classNames from 'class-names';
import { useMediaQuery } from "beautiful-react-hooks";

const CurrentBalance = ({isOpen, value}) => {
    const isDesktop = useMediaQuery('(min-width: 1440px)');

    const buttonText = isOpen ? "Назад" : (isDesktop ? "Все операции" : "");

    return (
        <div className={styles.container}>
            <div className={styles.value}>
                {value}
            </div>
            <div className={styles.button}>
                <div className={classNames( isOpen && styles.buttonIconBack)}/>
                <div className={styles.buttonText}>
                    {buttonText}
                </div>
            </div>
        </div>
    );
};

CurrentBalance.propTypes = {
    isOpen: PropTypes.bool,
    value: PropTypes.string,
};

export default CurrentBalance;
