import {
    fetchMaintenanceReasons, fetchMaintenances, fetchMaintenanceStatuses,
    fetchProfileBills,
    fetchProfileData,
    fetchProfileDocuments, postProfileIdeas, postProfileMail, postProfileMaintenance, putProfileChangeEmail,
    putProfileChangePassword, putProfileChangePhone, putProfileChangeUser
} from '../../../api/profile-api';
import { showModal } from "../../actions/ui/modal/modal";

export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SET_PROFILE_DOCUMENTS = 'SET_PROFILE_DOCUMENTS';
export const SET_PROFILE_BILLS = 'SET_PROFILE_BILLS';
export const SET_PROFILE_MAINTENANCE_REASONS = 'SET_PROFILE_MAINTENANCE_REASONS';
export const SET_PROFILE_MAINTENANCE_STATUSES = 'SET_PROFILE_MAINTENANCE_STATUSES';
export const SET_PROFILE_MAINTENANCES = 'SET_PROFILE_MAINTENANCES';
export const SET_SELECTED_OBJECT = 'SET_SELECTED_OBJECT';

export const setProfileData = (profileData) => ({
    type: SET_PROFILE_DATA,
    payload: { profileData },
});

const setProfileDocuments = (profileDocuments) => ({
    type: SET_PROFILE_DOCUMENTS,
    payload: { profileDocuments },
});

const setProfileBills = (bills) => ({
    type: SET_PROFILE_BILLS,
    payload: { bills },
});

const setProfileMaintenanceReasons = (maintenanceReasons) => ({
    type: SET_PROFILE_MAINTENANCE_REASONS,
    payload: { maintenanceReasons },
});

const setProfileMaintenanceStatuses = (maintenanceStatuses) => ({
    type: SET_PROFILE_MAINTENANCE_STATUSES,
    payload: { maintenanceStatuses },
});

const setProfileMaintenances = (maintenances) => ({
    type: SET_PROFILE_MAINTENANCES,
    payload: { maintenances },
});

export const setSelectedObject = (selectedObject) => ({
    type: SET_SELECTED_OBJECT,
    payload: { selectedObject },
});

const handleProfileError = (error) => (dispatch) =>  {
    if (error.response && error.response.data) {
        const responseData = error.response.data;
        console.error({error});
        if (responseData.errorMessage) {
            dispatch(showModal({
                title: 'Ошибка',
                text: responseData.errorMessage,
                cancelText: 'Закрыть',
                submitText: 'Назад',
                onSubmit: () => window.history.back(),
            }))
        }
    } else {
        console.error(error);
    }
};

export const loadProfile = () => async (dispatch) => {
    try {
        const profileData = await fetchProfileData();
        dispatch(setProfileData(profileData.data));
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};

export const loadProfileDocuments = () => async (dispatch) => {
    try {
        const documentsData = await fetchProfileDocuments();
        dispatch(setProfileDocuments(documentsData.data));
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};

export const loadMaintenanceReasons = () => async (dispatch) => {
    try {
        const maintenanceReasons = await fetchMaintenanceReasons();
        dispatch(setProfileMaintenanceReasons(maintenanceReasons.data));
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};

export const loadMaintenanceStatuses = () => async (dispatch) => {
    try {
        const maintenanceStatuses = await fetchMaintenanceStatuses();
        dispatch(setProfileMaintenanceStatuses(maintenanceStatuses.data));
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};

export const loadMaintenances = (params) => async (dispatch) => {
    try {
        const maintenances = await fetchMaintenances(params);
        dispatch(setProfileMaintenances(maintenances.data));
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};

export const loadProfileBills = (options) => async (dispatch) => {
    try {
        const params = {
            _page: 0,
            _pageSize: 100,
            ...options,
        };
        const billsData = await fetchProfileBills(params);
        dispatch(setProfileBills(billsData.data));
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};

export const sendProfileMaintenance = (form) => async (dispatch) => {
    try {
        await postProfileMaintenance(form);
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};

export const sendProfileIdeas = (form) => async (dispatch) => {
    try {
        await postProfileIdeas(form);
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};

export const sendProfileMail = (form) => async (dispatch) => {
    try {
        await postProfileMail(form);
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};

export const sendProfileChangePassword = (form) => async (dispatch) => {
    try {
        await putProfileChangePassword(form);
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};

export const sendProfileChangeEmail = (form) => async (dispatch) => {
    try {
        await putProfileChangeEmail(form);
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};

export const sendProfileChangePhone = (form) => async (dispatch) => {
    try {
        await putProfileChangePhone(form);
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};

export const sendProfileChangeUser = (form) => async (dispatch) => {
    try {
        await putProfileChangeUser(form);
    } catch (e) {
        dispatch(handleProfileError(e));
    }
};
