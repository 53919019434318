import React, {useEffect} from "react";
import styles from "./PlaylistInfo.module.scss";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Link, useLocation, useParams } from "react-router-dom";
import image from "../../../img/icons/playlist-info-icon.svg";
import playlistCountObjects from "../../../img/icons/playlist-info-home-icon.svg"
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../constants/pageTypes";
import { useDispatch, useSelector } from "react-redux";
import { playlist } from "../../../redux/modules/background/background-selectors";
import {loadCurrentPlaylist} from "../../../redux/modules/background/background-actions";
import Spinner from "../../../components/Spinner";
import {useRadioObjects} from "../../../hooks/radio/useRadioObjects";
import AudioPlayer from "../../../components/AudioPlayer";

const breadcrumbsRoutes = (type, namePlaylist, id) => (
    [
      {
        name: "Фон",
        href: "/background",
      },
      {
        name: type !== 'RAO' ? `Плейлисты` : 'РАО',
        href: type !== 'RAO' ? "/background/playlists" : '/background/RAO',
      },
      {
        name: `${namePlaylist}`,
        href: `/background/playlist/${id}`,
      },
    ]
);

const PlaylistInfo = () => {
  const dispatch = useDispatch();
  const [data] = useRadioObjects();
  const { state } = useLocation();
  const playlistData = useSelector(playlist);
  useSetCurrentSection(state?.RAO ? PAGE_SUBSECTIONS.RAO : PAGE_SUBSECTIONS.PLAYLISTS);
  let { id } = useParams();

  useEffect(() => {
    dispatch(loadCurrentPlaylist(id));
  }, [dispatch, id]);

  if (!playlistData) {
    return (
        <div className={styles.spinnerContainer}>
            <Spinner/>
        </div>
    )
  }

  return (
    <div className={styles.container}>
      <Breadcrumbs className={styles.breadcrumbs}
          routes={state?.RAO ?
              breadcrumbsRoutes('RAO', playlistData.title, id) :
              breadcrumbsRoutes('NON_RAO', playlistData.title, id)} />
      <div className={styles.playlistInfo}>
        <div className={styles.blockPlaylist}>
          <div className={styles.block}>
          <img src={playlistData?.detailedImage ?? image} className={styles.image} />
            <div className={styles.leftBlock}>
                <AudioPlayer
                    trackId={id}
                    entityName="playlists"
                />
              <div className={styles.infoPlaylist}>
                  <div className={styles.infoBlock}>
                      <p className={styles.title}>{playlistData.title}</p>
                  </div>
                  {playlistData?.attached ?
                      <Link className={styles.link} to={`/background/playlist/${id}/edit`}>
                          <button className={styles.infoButton}>
                              <p>Приобрести за 495 ₽</p>
                          </button>
                      </Link>
                  : null}
              </div>
                <div className={styles.infoBlockObjects}>
                    <img src={playlistCountObjects} alt="countObjects" />
                    <span>{playlistData.objectsCount}/{data?.totalEntities}</span>
                    {playlistData?.payed ? (
                        <div className={styles.active}>
                            <div className={styles.activeCircle} />
                            <p>АКТИВНЫЙ</p>
                        </div>
                        ) : (
                            <div className={styles.noActive}>
                            <div className={styles.noActiveCircle} />
                            <p>НЕ АКТИВНЫЙ</p>
                        </div>
                    )}
                </div>
            </div>
          </div>
        </div>
          <div className={styles.titleLinks}>
              {/*{playlistData.objectsCount !== 0 ? (*/}
                  <Link className={styles.link} to={{
                      pathname: `/background/playlist/${id}/edit`,
                      state: {
                          RAO: true,
                          name: playlistData?.title,
                      }
                  }}>
                      <button className={styles.buttonDefault}>
                          Установить как плейлист по умолчанию
                      </button>
                  </Link>
                  {/*) : (*/}
              {/*    <button className={styles.buttonNoStart}>*/}
              {/*        <p>Не доступен к установке</p>*/}
              {/*        <div className={styles.buttonRightBlock}>*/}
              {/*            <div className={styles.question}>*/}
              {/*                <span>?</span>*/}
              {/*            </div>*/}
              {/*        </div>*/}
              {/*    </button>*/}
              {/*)}*/}
          </div>
      </div>
        <div className={styles.text}>
            <p>
                {playlistData?.description}
            </p>
        </div>
    </div>
  );
};

export default PlaylistInfo;
