import React from 'react';
import PropTypes from 'prop-types';
import styles from './GenericCard.module.scss';
import classNames from 'class-names';
import { Link } from "react-router-dom";

const GenericCard = ({ className, headClassName, headContent, children, href, onClick }) => {
    const Component = href ? Link : 'div';

    return (
        <Component to={href ? href : undefined}
                   onClick={onClick}
                   className={classNames(className, styles.container)}>
            <div className={classNames(styles.head, headClassName)}>
                {headContent}
            </div>
            {children}
        </Component>
    );
};

GenericCard.propTypes = {
    className: PropTypes.string,
    headClassName: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func
};

export default GenericCard;
