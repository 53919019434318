import React from 'react';
import PropTypes from 'prop-types';
import styles from './Select.module.scss';
import classNames from 'class-names';

const Select = ({ options, selected, name, label, onChange, className, key }) => (
    <div className={classNames(styles.container, className)}>
        {label &&
        <label htmlFor={name}>
            {label}
        </label>}
        <select name={name} onChange={onChange} value={selected}>
            {options.map((item, index) => {
                    const isObject = typeof item === 'object';
                    return (
                        <option value={isObject ? item.name ? item.name : item.value : item}
                                key={index}
                                id={item.id}>
                            {isObject ? item.name ? item.name : item.label : item}
                        </option>
                    )
                }
            )}
        </select>
    </div>
);


Select.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.exact({
                label: PropTypes.string,
                value: PropTypes.any
            })
        ])),
    selected: PropTypes.oneOfType([
        PropTypes.exact({
            label: PropTypes.string,
            value: PropTypes.any
        }),
        PropTypes.string
    ]),
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    key: PropTypes.number,
};

export default Select;
