import React, {useEffect, useState} from 'react';
import styles from './FeedbackScene.module.scss';
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../constants/pageTypes";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import classNames from 'class-names';
import {useDispatch} from "react-redux";
import {sendProfileMail} from "../../../redux/modules/profile/profile-actions";

const breadcrumbsRoutes = [{
    name: 'Личный кабинет',
    href: '#'
}, {
    name: 'Форма обратной связи',
    href: '/profile/feedback'
}];

const FEEDBACK_STAGE = {
    FORM: 'FORM',
    RESULT: 'RESULT',
};

const FeedbackScene = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.FEEDBACK);
    const [stage, setStage] = useState(FEEDBACK_STAGE.FORM);
    const [form, setForm] = useState({});
    const dispatch = useDispatch();

    const createChangeListener = (name) => (e) => {
        setForm({ ...form, [name]: e.target.value });
    };

    const onSubmitForm = () => {
        dispatch(sendProfileMail(form));
        setForm({});
        setStage(FEEDBACK_STAGE.RESULT);
    };

    const onOpenForm = () => {
        setStage(FEEDBACK_STAGE.FORM);
    };

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.pageContainer}>
                {stage === FEEDBACK_STAGE.FORM && <div className={styles.formContainer}>
                    <div className={styles.formElementLabel}>
                        Тема сообщения
                    </div>
                    <Input name={'topic'}
                           inputClassName={styles.formInput}
                           className={styles.formInputContainer}
                           onChange={createChangeListener('message')}
                    />
                    <div className={styles.formElementLabel}>
                        Текст сообщения
                    </div>
                    <Input name="text"
                           type="textarea"
                           className={styles.textareaContainer}
                           inputClassName={styles.textarea}
                           onChange={createChangeListener('subject')}
                    />
                    <Button className={classNames(styles.profileButton, styles.primary, styles.fullWidth)}
                            onClick={onSubmitForm}>
                        Отправить
                    </Button>
                </div>}
                {stage === FEEDBACK_STAGE.RESULT && <div className={styles.resultContainer}>
                    <div className={styles.resultTitle}>
                        Ваше обращение зарегистрировано
                    </div>
                    <div className={styles.resultDesc}>
                        Наши специалисты рассмотрят его в течение часа.
                    </div>
                    <div className={styles.resultButtons}>
                        <Button onClick={onOpenForm} className={classNames(styles.profileButton, styles.fullWidth)}>
                            Назад
                        </Button>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default FeedbackScene;
