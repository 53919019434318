import React, { useState } from 'react';
import GenericCard from "../GenericCard";
import styles from "./PriorityAdCard.module.scss";
import Popup from "../../Popup";

const PriorityAdCard = () => {
    const [showPopup, setShowPopup] = useState(false);

    return (
        <GenericCard className={styles.card} headClassName={styles.head}
                     headContent={<>
                         <div className={styles.headInfo}>
                             <div className={styles.starIcon}/>
                             <div className={styles.icon}/>
                             ID 51464
                         </div>
                         <div className={styles.menuButton} onClick={() => setShowPopup(!showPopup)}>
                             {showPopup &&
                             <Popup closePopup={(e) => {
                                 e.preventDefault();
                                 setShowPopup(false)
                             }} className={styles.cardPopup}>
                                 <div className="popup-menu-item">
                                     Избранное объявление
                                 </div>
                                 <div className="popup-menu-item">
                                     Удалить объявление
                                 </div>
                             </Popup>}
                         </div>
                     </>}>
            <div className={styles.contentContainer}>
                <div className={styles.textContainer}>
                    <div className={styles.title}>
                        Объявление номер один
                    </div>
                    <div className={styles.infoBlock}>
                        Дата создания
                        <div className={styles.infoValue}>
                            09.09.2019
                        </div>
                    </div>
                    <div className={styles.infoBlock}>
                        ID 51464 Ролик номер один
                    </div>
                </div>
                <div className={styles.playButton}/>
            </div>
        </GenericCard>
    );
};

export default PriorityAdCard;
