import React from 'react';
import PropTypes from 'prop-types';
import styles from './CalendarRepeatWeekly.module.scss';
import classNames from 'class-names';

const weekDays = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

const CalendarRepeatWeekly = props => {
    const [currentFilters, setCurrentFilters] = React.useState(['ВТ']);

    return (
        <div className={styles.container}>
            <h5 className={styles.title}>Когда повторяется</h5>
            <div className={styles.weekDays}>
                {weekDays.map(day => (
                    <div
                        key={day}
                        onClick={() => {
                            if (currentFilters.includes(day)) {
                                setCurrentFilters(currentFilters.filter(value => value !== day))
                            } else {
                                setCurrentFilters([...currentFilters, day])
                            }
                        }}
                        className={classNames(
                            styles.weekDayItem, 
                            {[styles.weekDayItemActive]: currentFilters.includes(day),
                            })}>
                        {day}
                    </div>
                ))}
            </div>
        </div>
    );
};

CalendarRepeatWeekly.propTypes = {
    selectedDate: PropTypes.instanceOf(Date)
};

export default CalendarRepeatWeekly;
