import { api } from "../App";

export const RADIO_URL = 'http://api.2.dev.yradio.ru/radio/v1';
const COLORS_URL = `${RADIO_URL}/colors`;
const OBJECT_STATUSES_URL = `${RADIO_URL}/object-statuses`;
const OBJECTS_URL = `${RADIO_URL}/objects`;
const MEDIAPLAN_LIST_URL = `${RADIO_URL}/mediaplans`;
const OBJECT_CREATE_METADATA_URL = `${RADIO_URL}/items`;

export const fetchRadioColors = async () => {
    return api.get(COLORS_URL);
};

export const fetchRadioObjectStatuses = async () => {
    return api.get(OBJECT_STATUSES_URL);
};

export const fetchRadioObjectList = async (params) => {
    return api.get(OBJECTS_URL, {params});
};

export const fetchRadioObject = async (id) => {
    return api.get(`${OBJECTS_URL}/${id}`);
};

export const fetchRadioMediaplanList = async (params) => {
    return api.get(MEDIAPLAN_LIST_URL, {params});
};

export const fetchRadioMediaplan = async (id) => {
    return api.get(`${MEDIAPLAN_LIST_URL}/${id}`);
};

export const postRadioMediaplan = async (data) => {
    return api.post(`${MEDIAPLAN_LIST_URL}`, data);
};

export const patchRadioMediaplan = async (data, id) => {
    return api.patch(`${MEDIAPLAN_LIST_URL}/${id}`, data);
};

export const fetchPriorityAds = async (id) => {
    return api.get(`${OBJECTS_URL}/${id}/announcements`);
};

export const fetchObjectCreateMetadata = () => {
    return api.get(`${OBJECT_CREATE_METADATA_URL}`);
}

export const fetchObjectVolumeLevels = (id) => {
    return api.get(`${OBJECTS_URL}/${id}/volume`);
}

export const putObjectVolumeLevel = (id, type, volume) => {
    return api.put(`${OBJECTS_URL}/${id}/volume/${type}`, {
        volume
    });
}

export const fetchObjectBroadcastPauses = (id) => {
    return api.get(`${OBJECTS_URL}/${id}/mute`);
}

export const deleteObjectBroadcastPauses = (id, pauseId) => {
    return api.delete(`${OBJECTS_URL}/${id}/mute/${pauseId}`);
}

export const postObjectBroadcastPause = (id, data) => {
    return api.post(`${OBJECTS_URL}/${id}/mute`, data);
}
