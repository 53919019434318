import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GenericCard from "../GenericCard";
import styles from './RecordCard.module.scss';
import RecordStatus from "./RecordStatus";
import Popup from "../../Popup";
import { Link } from "react-router-dom";
import classNames from "class-names";
import recordIcon from "../../../img/icons/record-icon.svg";
import { STUDIO_ENTITY, STUDIO_ENTITY_URL_MAP } from "../../../constants/studioEntities";
import AudioPlayer from "../../AudioPlayer";
import { showModal } from "../../../redux/actions/ui/modal/modal";
import { addTracksToArchive, removeTracksFromArchive } from "../../../redux/modules/studio/studio-actions";
import { connect } from "react-redux";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    showModal: (data) => dispatch(showModal(data)),
    addToArchive: (ids, entity) => dispatch(addTracksToArchive(ids, entity)),
    removeFromArchive: (ids, entity) => dispatch(removeTracksFromArchive(ids, entity)),
});

const StudioRecordCard = ({
                              record,
                              checkbox,
                              icon = recordIcon,
                              showStatus,

                              entity,
                              showModal,
                              addToArchive,
                              removeFromArchive,
                          }) => {
    const [popupShown, setPopupShown] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [menuOptions, setMenuOptions] = useState(null);


    const getRecordMenuOptions = (record) => (<>
        {entity === STUDIO_ENTITY.RECORDS && (
            <div className="popup-menu-item" onClick={() => {
                if (entity === STUDIO_ENTITY.RECORDS) {
                    if (record.onAir) {
                        showModal({
                            title: "Ролик в эфире!",
                            text: "Перемещение его в архив приведет снятию его с эфира из всех медиапланов.",
                            submitText: "Продолжить",
                            onSubmit: () => addToArchive([record.id], entity)
                        });
                    }
                    else {
                        addToArchive([record.id], entity)
                    }
                } else {
                    removeFromArchive([record.id], entity)
                }
            }}>
                Добавить в архив
            </div>
        )}
        {entity === STUDIO_ENTITY.ARCHIVE && (
            <div onClick={() => removeFromArchive([record.id], entity)} className="popup-menu-item">
                Убрать из архива
            </div>
        )}
        {
            audioUrl && (
                <a href={audioUrl} target="_blank">
                    <div className="popup-menu-item">
                        Скачать
                    </div>
                </a>
            )
        }
    </>);

    React.useEffect(() => {
        setMenuOptions(getRecordMenuOptions(record));
    }, [audioUrl])

    const playerActive = entity !== STUDIO_ENTITY.ARCHIVE;
    const category = record.categories ? record.categories[0] : null;

    return (
        <GenericCard className={styles.card}
                     headClassName={classNames(styles.head, { [styles.headOnline]: record.onAir })}
                     headContent={<>
                         <div className={styles.headInfo}>
                             <div className={styles.checkboxLeftContainer}>{checkbox}</div>
                             <div className={styles.icon} style={{ backgroundImage: `url(${icon})` }}/>
                             ID {record.id}
                             {showStatus && ` • ${record.status.name}`}
                             {showStatus && <RecordStatus onlineStatus={record.onAir} status={record.status.name}/>}
                             <div className={styles.menuButton} onClick={() => setPopupShown(!popupShown)}>
                                 {popupShown &&
                                 <Popup closePopup={() => setPopupShown(false)} className={styles.recordPopup}>
                                     {menuOptions}
                                 </Popup>}
                             </div>
                         </div>
                     </>}>
            <div className={styles.contentContainer}>
                <Link to={`/studio/${[STUDIO_ENTITY_URL_MAP[entity]]}/${record.id}`} className={styles.title}>
                    {record.title}
                </Link>
                <div className={styles.dataContainer}>
                    <div className={styles.player}>
                        <AudioPlayer
                            trackId={record.id}
                            entityName={entity}
                            setAudioUrl={setAudioUrl}
                        />
                    </div>

                    {record.type && <div className={styles.infoBlock}>
                        <div className={styles.infoTitle}>
                            Тип ролика:
                        </div>
                        <div className={styles.infoValue}>
                            {record.type.name}
                        </div>
                    </div>}
                    {category && <div className={styles.infoBlock}>
                        <div className={styles.infoTitle}>
                            Категория:
                        </div>
                        <div className={styles.infoValue}>
                            {category.name}
                        </div>
                    </div>}
                </div>
            </div>
        </GenericCard>
    );
}
;

export default connect(mapStateToProps, mapDispatchToProps)(StudioRecordCard);
