import React, {useContext, useEffect, useState} from 'react';
import styles from './ObjectMaintenanceAddScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ItemListTitle from "../../../../components/ItemListTitle";
import objectIcon from "../../../../img/icons/object-dark-grey-icon.svg";
import RecordStatus from "../../../../components/Cards/RecordCard/RecordStatus";
import { RECORD_ONLINE_STATUS, RECORD_STATUS } from "../../../../constants/recordTypes";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import {useDispatch, useSelector} from "react-redux";
import {
    profileSelectedObject,
    profileSelectorMaintenanceReasons
} from "../../../../redux/modules/profile/profile-selectors";
import {
    loadMaintenanceReasons,
    sendProfileMaintenance,
    setSelectedObject
} from "../../../../redux/modules/profile/profile-actions";
import Select from "../../../../components/common/Select";
import Spinner from "../../../../components/Spinner";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Объекты',
    href: '/radio/objects'
}, {
    name: 'Страница объекта',
    href: '/radio/object'
}, {
    name: 'Техобслуживание',
    href: '/radio/object/maintenance'
}, {
    name: 'Создание заявки ТО',
    href: '/radio/object/maintenance/add'
}];


const ObjectMaintenanceAddScene = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.OBJECTS);
    const selectedObject = useSelector(profileSelectedObject);
    const maintenanceReasons = useSelector(profileSelectorMaintenanceReasons);
    const [reason, setReason] = useState();
    const [comment, setComment] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadMaintenanceReasons());
    }, [dispatch]);

    const onSubmit = () => {
        const params = {
            objId: selectedObject[0]?.id,
            reasonId: reason,
            comment,
        };

        dispatch(sendProfileMaintenance(params));
        setComment({});
    }

    if (!maintenanceReasons) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes} className={styles.breadcrumbs}/>
            <ItemListTitle icon={objectIcon}
                           className={styles.pageTitle}
                           title={"09385-19 ТГПУ Очное заочное"}>
                ID 51464 <RecordStatus onlineStatus={RECORD_ONLINE_STATUS.ONLINE}
                                       status={RECORD_STATUS.READY}/>
            </ItemListTitle>
            <div className={styles.form}>
                <Select options={maintenanceReasons}
                        className={styles.selectWrapper}
                        onChange={(e) => {
                            const option = maintenanceReasons.find(item => item.name === e.target.value);
                            setReason(option.id);
                        }}
                />
                <Input name={'description'}
                       type={'textarea'}
                       label={'Примечане'}
                       onChange={(e) => setComment(e.target.value)}
                />
            </div>
            <div className={styles.buttonsContainer}>
                <Button href={'/radio/object/maintenance'} className={styles.hideDesktop}>
                    Отменить
                </Button>
                <Button href={'/radio/object/maintenance'} className={styles.submitButton} onClick={onSubmit}>
                    Отправить заявку
                </Button>
            </div>
        </div>
    );
};

export default ObjectMaintenanceAddScene;
