export const PAGE_TYPE = {
    ARCHIVE: "ARCHIVE",
    DEMO: "DEMO",
    RECORDS: "RECORDS",
    SINGLE_RECORD: "SINGLE_RECORD"
};

export const PAGE_SECTIONS = {
    CONTENT: {
        name: "Студия звукозаписи",
        href: '/studio/records',
        links: [{
                name: 'Ролики',
                href: '/studio/records'
            },
            {
                name: 'Архив',
                href: '/studio/archive'
            },
            {
                name: 'Demo',
                href: '/studio/demo'
            }
        ]
    },
    RADIO: {
        name: "Корпоративное радио",
        href: '/radio/objects',
        links: [{
                name: 'Объекты',
                href: '/radio/objects'
            },
            {
                name: 'Эфир',
                href: '/radio/broadcast'
            }
        ]
    },
    BACKGROUND: {
        name: "Фон",
        href: '/background/playlists',
        links: [{
                name: 'Плейлисты',
                href: '/background/playlists'
            },
            {
                name: 'РАО',
                href: '/background/RAO'
            }
        ]
    },
    PROFILE: {
        name: 'Профиль',
        href: '/profile',
        links: [{
                name: 'Личный кабинет',
                href: '/profile'
            },
            {
                name: 'История операций',
                href: '/profile/history'
            },
            {
                name: 'Тех. сопровождение',
                href: '/profile/tech-support'
            },
            {
                name: 'Сопровождающие док-ты',
                href: '/profile/documents'
            },
            {
                name: 'Предложение идей',
                href: '/profile/ideas'
            },
            {
                name: 'Форма обратной связи',
                href: '/profile/feedback'
            },
        ]
    }
};

export const PAGE_SUBSECTIONS = {
    PLAYLISTS: {
        parent: PAGE_SECTIONS.BACKGROUND,
        name: 'Плейлисты'
    },
    RAO: {
        parent: PAGE_SECTIONS.BACKGROUND,
        name: 'РАО'
    },
    OBJECTS: {
        parent: PAGE_SECTIONS.RADIO,
        name: 'Объекты'
    },
    BROADCAST: {
        parent: PAGE_SECTIONS.RADIO,
        name: 'Эфир'
    },
    PRIORITY: {
        parent: PAGE_SECTIONS.RADIO,
        name: 'Приоритетные объявления'
    },
    RECORDS: {
        parent: PAGE_SECTIONS.CONTENT,
        name: 'Ролики'
    },
    DEMO: {
        parent: PAGE_SECTIONS.CONTENT,
        name: 'Demo',
    },
    ARCHIVE: {
        parent: PAGE_SECTIONS.CONTENT,
        name: 'Архив'
    },
    PROFILE: {
        parent: PAGE_SECTIONS.PROFILE,
        name: 'Личный кабинет',
    },
    PROFILE_HISTORY: {
        parent: PAGE_SECTIONS.PROFILE,
        name: 'История операций',
    },
    TECH_SUPPORT: {
        parent: PAGE_SECTIONS.PROFILE,
        name: 'Тех. сопровождение',
    },
    DOCUMENTS: {
        parent: PAGE_SECTIONS.PROFILE,
        name: 'Сопровождающие док-ты',
    },
    IDEAS: {
        parent: PAGE_SECTIONS.PROFILE,
        name: 'Предложение идей',
    },
    FEEDBACK: {
        parent: PAGE_SECTIONS.PROFILE,
        name: 'Форма обратной связи',
    },
};
