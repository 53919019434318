import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App.jsx';
import * as serviceWorker from './serviceWorker';

//ESLint перестаёт ругаться, если вызывать метод как window.screen, но тогда возможны проблемы на мобильных
// eslint-disable-next-line no-restricted-globals
screen.orientation && screen.orientation.lock('portrait').then(
    success => {},
    error => console.warn('Could not lock orientation.')
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
