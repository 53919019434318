import React, { useState } from "react";
import styles from "./PlaylistOrder.module.scss";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {Link, useParams} from "react-router-dom";
import Input from "../../../components/common/Input";
import Button from "../../../components/common/Button";
import classNames from 'class-names';
import {PLAYLISTS} from "../../../constants/playlists";

const breadcrumbsRoutes = [
    {
        name: "Фон",
        href: "/background",
    },
    {
        name: "Плейлисты",
        href: "/background/playlists",
    },
    {
        name: "Заказ плейлиста",
        href: `/background/playlist/order`,
    }
];

const ORDER_STAGE = {
    FORM: 'FORM',
    RESULT: 'RESULT',
};

const PlaylistOrder = () => {
    const [stage, setStage] = useState(ORDER_STAGE.FORM);

    const onSubmitForm = () => {
        setStage(ORDER_STAGE.RESULT);
    };

    const onOpenForm = () => {
        setStage(ORDER_STAGE.FORM);
    };

    let { id } = useParams();

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes} className={styles.breadcrumbs}/>
            <div className={styles.pageContainer}>
                {stage === ORDER_STAGE.FORM && <div className={styles.formContainer}>
                    <div className={styles.formElementLabel}>
                        Название Плейлиста
                    </div>
                    <Input name={'topic'}
                           inputClassName={styles.formInput}
                           className={styles.formInputContainer}
                           placeholder="Название Плейлиста"/>
                    <div className={styles.choice}>
                        <div className={styles.choiceObjects}>
                            <p className={styles.objects}>Список объектов для установки</p>
                            <p className={styles.objectsCount}>Выбрано: 1 объект</p>
                        </div>
                        <Link to={`/background/playlist/${id}/edit`}>
                            <Button className={styles.choiceButton}>
                                Выбрать
                            </Button>
                        </Link>
                    </div>
                    <div className={styles.formElementLabel}>
                        Описание
                    </div>
                    <Input name="text"
                           type="textarea"
                           className={styles.textareaContainer}
                           inputClassName={styles.textarea}
                           placeholder="Описание"/>
                    <Button className={classNames(styles.orderButton, styles.primary, styles.fullWidth)}
                            onClick={onSubmitForm}>
                        Отправить
                    </Button>
                </div>}
                {stage === ORDER_STAGE.RESULT && <div className={styles.resultContainer}>
                    <div className={styles.resultTitle}>
                        Ваша заявка зарегистрирована
                    </div>
                    <div className={styles.resultDesc}>
                        Наши специалисты рассмотрят ее
                        в течение часа. Этот текст нужно заменить.
                    </div>
                    <div className={styles.resultButtons}>
                        <Link className={styles.link} to={`/background/playlists`}>
                            <Button onClick={onOpenForm} className={classNames(styles.orderButton, styles.fullWidth)}>
                                Хорошо
                            </Button>
                        </Link>
                    </div>
                </div>}
            </div>
        </div>
    );
};

export default PlaylistOrder;
