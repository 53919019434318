import React from 'react';
import styles from './Checkbox.module.scss';
import classNames from 'class-names';

const Checkbox = ({ children, type = 'filter', className, ...props }) => (
    <label className={classNames(type === 'filter' ? styles.filterCheckbox : styles.checkbox, className)}>
        {children}
        <input type="checkbox" {...props}/>
        <span className={styles.checkmark}/>
    </label>
);


export default Checkbox;
