import playlistPlayIcon from "../img/icons/playlist-play-icon.svg";
import playlistImg1 from "../img/icons/background-playlist-image-1.svg";
import playlistImg2 from "../img/icons/background-playlist-image-2.svg";
import playlistImg3 from "../img/icons/background-playlist-image-3.svg";
import playlistImg4 from "../img/icons/background-playlist-image-4.svg";
import playlistImg5 from "../img/icons/background-playlist-image-5.svg";
import playlistStopIcon from "../img/icons/playlist-stop-icon.svg";

export const PLAYLISTS = [
    {
        id: 1,
        title: 'По умолчанию',
        image: playlistImg1,
        countObjects: 3,
        imagePlaylistPlay: playlistPlayIcon,
        isActive: false,
    },
    {
        id: 2,
        title: 'Оранжевое Настроение',
        image: playlistImg2,
        countObjects: 8,
        imagePlaylistPlay: playlistPlayIcon,
        isActive: true
    },
    {
        id: 3,
        title: 'Осенняя грусть',
        image: playlistImg3,
        countObjects: 0,
        imagePlaylistPlay: playlistStopIcon,
        isActive: true
    },
    {
        id: 4,
        title: 'Новогодний драйв',
        image: playlistImg4,
        countObjects: 2,
        imagePlaylistPlay: playlistPlayIcon,
        isActive: true
    },
    {
        id: 5,
        title: 'Осенняя грусть',
        image: playlistImg5,
        countObjects: 10,
        imagePlaylistPlay: playlistPlayIcon,
        isActive: true
    },
    {
        id: 6,
        title: 'Весенняя палитра',
        image: playlistImg1,
        countObjects: 10,
        imagePlaylistPlay: playlistStopIcon,
        isActive: false
    },
    {
        id: 7,
        title: 'Оранжевое Настроение',
        image: playlistImg2,
        countObjects: 8,
        imagePlaylistPlay: playlistPlayIcon,
        isActive: true
    },
    {
        id: 8,
        title: 'Осенняя грусть',
        image: playlistImg3,
        countObjects: 0,
        imagePlaylistPlay: playlistPlayIcon,
        isActive: true
    },
    {
        id: 9,
        title: 'Новогодний драйв',
        image: playlistImg4,
        countObjects: 2,
        imagePlaylistPlay: playlistPlayIcon,
        isActive: true
    },
    {
        id: 10,
        title: 'Осенняя грусть',
        image: playlistImg5,
        countObjects: 10,
        imagePlaylistPlay: playlistStopIcon,
        isActive: false
    }
]