import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './RecordsControls.module.scss';
import Filter from "./Filter";
import classNames from 'class-names';
import {
    createStudioDataSelector,
    createStudioFiltersSelector,
    studioMetadataSelector
} from "../../redux/modules/studio/studio-selectors";
import { connect } from "react-redux";
import { resetStudioFilters, saveStudioFilters } from "../../redux/modules/studio/studio-actions";


const mapStateToProps = (state, ownProps) => {
    const { entity } = ownProps;

    return ({
        metadata: studioMetadataSelector(state),
        currentFilters: createStudioFiltersSelector(entity)(state),
        data: createStudioDataSelector(entity)(state),
    });
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { entity } = ownProps;

    return ({
        setFilters: (filters) => dispatch(saveStudioFilters(entity, filters)),
        resetFilters: () => dispatch(resetStudioFilters(entity)),
    });
}

const Search = ({
    actions,
    metadata,
    currentFilters,
    setFilters,
    resetFilters,
    data,
}) => {
    const [filterOpen, setFilterOpen] = useState(false);
    const [query, setQuery] = useState(currentFilters.title || '');

    const onResetFilters = () => {
        resetFilters();
        setFilterOpen(false);
    };

    const onSetFilters = (filters) => {
        setFilters(filters);
        setFilterOpen(false);
    };

    const onSearch = () => {
        setFilters({
            ...currentFilters,
            title: query,
        });
    };

    const onChange = async ({ target }) => {
        setQuery(target.value);
    }

    React.useEffect(() => {
        onSearch();
    }, [query])

    return (
        <div className={styles.container}>
            <div className={styles.controlsContainer}>
                <div className={styles.searchContainer}>
                    <div className={styles.inputContainer}>
                        <input
                            className={styles.input} placeholder="Поиск" value={query}
                            onChange={onChange}
                        />
                        <div className={styles.searchIcon} onClick={onSearch}/>
                    </div>
                    <div className={classNames(styles.filterIcon, { [styles.open]: filterOpen })}
                         onClick={() => setFilterOpen(!filterOpen)}/>
                </div>
                {actions}
            </div>
            {filterOpen && <Filter
                filters={metadata}
                selectedFilters={currentFilters}
                setFilters={onSetFilters}
                resetFilters={onResetFilters}
            />}
        </div>
    );
};

Search.propTypes = {
    actions: PropTypes.node,
    entity: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
