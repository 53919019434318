import React from 'react';
import PropTypes from 'prop-types';
import styles from './TimePickerRange.module.scss';
import classNames from 'class-names';

const TimePickerRange = ({ value, minValue, maxValue, onChange }) => {
    const onDecrease = () => value > minValue && onChange(value - 1);
    const onIncrease = () => value < maxValue && onChange(value + 1);

    return (
        <div className={styles.container}>
            <div className={classNames(styles.button, styles.buttonDecrease)}
                 onClick={onDecrease}/>
            <div className={styles.inactiveValue} onClick={onDecrease}>
                {value > minValue && value - 1}
            </div>
            <div className={styles.value}>
                {value}
            </div>
            <div className={styles.inactiveValue} onClick={onIncrease}>
                {value < maxValue && value + 1}
            </div>
            <div className={classNames(styles.button, styles.buttonIncrease)}
                 onClick={onIncrease}/>
        </div>
    );
};

TimePickerRange.propTypes = {
    value: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    onChange: PropTypes.func
};

export default TimePickerRange;
