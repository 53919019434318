import React, {useEffect, useState} from 'react';
import styles from './ObjectMaintenanceScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Button from "../../../../components/common/Button";
import Input from "../../../../components/common/Input";
import MaintenanceCard from "../../../../components/Cards/MaintenanceCard";
import Pagination from "../../../../components/Pagination";
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import ListContainer from "../../../../components/ListContainer";
import {useDispatch, useSelector} from "react-redux";
import {profileSelectedObject, profileSelectorMaintenances} from "../../../../redux/modules/profile/profile-selectors";
import {loadMaintenances} from "../../../../redux/modules/profile/profile-actions";
import {useHistory, useLocation} from "react-router-dom";

const ObjectMaintenanceScene = ({ archive }) => {
    useSetCurrentSection(PAGE_SUBSECTIONS.OBJECTS);
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const maintenances = useSelector(profileSelectorMaintenances);
    const selectedObject = useSelector(profileSelectedObject);
    const [isTechSupport, setIsTechSupport] = useState(location.state?.techSupport);

    const breadcrumbsRoutes = !isTechSupport ? [
        {
            name: 'Корпоративное радио',
            href: '#'
        }, {
            name: 'Объекты',
            href: '/radio/objects'
        }, {
            name: 'Страница объекта',
            href: `/radio/objects/${selectedObject?.[0]?.id}`
        }, {
            name: selectedObject?.[0]?.title ?? 'Техобслуживание',
            href: '/radio/object/maintenance'
        }
    ] : [
            {
                name: 'Тех. сопровождение',
                href: '/profile/tech-support'
            }, {
                name: selectedObject?.[0]?.title ?? 'Техобслуживание',
                href: '/radio/object/maintenance'
            }
    ];

    useEffect(() => {
        !archive ? (
            dispatch(loadMaintenances({ objectId: selectedObject[0]?.id }))
        ) : (
            dispatch(loadMaintenances({ status: 'CLOSED', objectId: selectedObject[0]?.id }))
        )
    }, [dispatch, archive, selectedObject]);

    const archiveRoutes = [...breadcrumbsRoutes, { name: 'Архив ТО', href: '/radio/object/maintenance/archive'}];

    const setPage = (page) => {
        dispatch(loadMaintenances( {
            _page: page,
            _pageSize: 10,
            objectId: selectedObject?.[0]?.id,
        }));
    }

    const onChangeDate = (e) => {
        const params = {
            dateCreatedFrom: e.target.value,
            objectId: selectedObject?.[0]?.id
        };

        dispatch(loadMaintenances(params));
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={archive ? archiveRoutes : breadcrumbsRoutes}/>
            <div className={styles.pageSettings}>
                {!archive &&
                    <div className={styles.buttonsContainer}>
                        <Button href={'/radio/object/maintenance/add'} className={styles.addButton}>
                            Создать заявку
                        </Button>
                        <Button href={'/radio/object/maintenance/archive'}
                                className={styles.archiveButton}>
                            Архив
                        </Button>
                    </div>
                }
                <div className={styles.dateInputContainer}>
                    <Input
                       name={'date'}
                       type={'date'}
                       className={styles.dateInput}
                       onChange={onChangeDate}
                       label={'Дата заявки'} />
                </div>
            </div>
            {isTechSupport && (
                <Button className={styles.buttonBack} onClick={() => history.goBack()}>
                    Назад
                </Button>
            )}
            <ListContainer className={styles.listContainer} emptyText="Заявки отсутствуют">
                {maintenances && maintenances?.items?.map(item =>
                    <MaintenanceCard item={item} key={item.id} />
                )}
            </ListContainer>
            {maintenances &&
                <Pagination
                    count={maintenances?.totalElements}
                    entityName="Заявок"
                    totalPages={maintenances?.totalPages}
                    resultsPerPage={maintenances?.pageSize}
                    currentPage={maintenances?.pageNumber}
                    setPage={setPage}
                    items={maintenances?.items}/>
            }
        </div>
    );
};

export default ObjectMaintenanceScene;
