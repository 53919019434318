import React, { useState } from 'react';
import styles from './BroadcastListScene.module.scss';
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import Search from "../../../../components/Search";
import BroadcastActions from "../../../../components/Search/actions/BroadcastActions";
import MediaplanCard from "../../../../components/Cards/MediaplanCard";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Pagination from "../../../../components/Pagination";
import ListContainer from "../../../../components/ListContainer";
import Checkbox from "../../../../components/common/Checkbox";
import { radioDataSelector, radioParamsSelector } from "../../../../redux/modules/radio/radio-selectors";
import { loadRadioData, setRadioParams } from "../../../../redux/modules/radio/radio-actions";
import { RADIO_ENTITY } from "../../../../constants/radioEntities";
import { connect } from "react-redux";
import { useRadioMetadata } from "../../../../hooks/radio/useRadioMetadata";
import { isRadioPageLoaded } from "../../../../redux/modules/radio/radio-helpers";
import Spinner from "../../../../components/Spinner";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Эфир',
    href: '/radio/broadcast'
}];

const mapStateToProps = (state) => ({
    data: radioDataSelector(state),
    params: radioParamsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    loadMediaplanList: () => dispatch(loadRadioData(RADIO_ENTITY.MEDIAPLAN_LIST)),
    setParams: (params) => dispatch(setRadioParams(params)),
})

const BroadcastListScene = ({
    data,
    params,
    loadMediaplanList,
    setParams,
}) => {
    useSetCurrentSection(PAGE_SUBSECTIONS.BROADCAST);
    useRadioMetadata();
    const [selectedOption, setSelectedOption] = useState('actual');

    React.useEffect(() => {
        loadMediaplanList();
    }, []);

    const setPage = (page) => {
        setParams({
            ...params,
            _page: page,
        });

        loadMediaplanList();
    };

    const isLoaded = isRadioPageLoaded(RADIO_ENTITY.MEDIAPLAN_LIST, data);

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.listOptions}>
                <Checkbox type={'card'}
                          checked={selectedOption === 'actual'}
                          onChange={() => setSelectedOption('actual')}
                          className={styles.checkbox}>
                    Актуальные
                </Checkbox>
                <Checkbox
                    type={'card'}
                    checked={selectedOption === 'non-actual'}
                    onChange={() => setSelectedOption('non-actual')}
                    className={styles.checkbox}>
                    Неактуальные
                </Checkbox>
            </div>
            <Search showFilter={false}
                    controlsClassName={styles.searchControls}
                    containerClassName={styles.searchContainer}
                    searchClassName={styles.searchInput}
                    actions={<BroadcastActions/>}/>
            {isLoaded ? (
                    <ListContainer noLoader className={styles.listContainer}>
                        {data.list.map(item => (
                            <MediaplanCard data={item} key={item.id}/>
                        ))}
                    </ListContainer>
            ) : (
                <div className={styles.spinnerContainer}>
                    <Spinner/>
                </div>
            )}
            {isLoaded && <Pagination
                entityName={'Медиапланов'}
                totalPages={data.totalPages}
                count={data.totalEntities}
                resultsPerPage={data.pageSize}
                currentPage={data.pageNumber}
                setPage={setPage}
            />}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastListScene);
