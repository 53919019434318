import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TimePicker.module.scss';
import TimePickerRange from "./TimePickerRange";
import classNames from 'class-names';
import moment from "moment";

const TimePicker = ({ className, date, onChange, name }) => {
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [isInitialized, setInitialized] = useState(false);

    useEffect(() => {
        const setValues = async () => {
            if (date) {
                const momentDate = moment(date);

                await setHours(momentDate.hours());
                await setMinutes(momentDate.minutes());
                await setInitialized(true);
            }
        }

        setValues();
    }, [date]);

    useEffect(() => {
        if (onChange && isInitialized) {
            const momentDate = moment(date);
            if (momentDate.minutes() !== minutes || momentDate.hours() !== hours) {
                onChange({ name, hours, minutes })
            }
        }
    }, [name, hours, minutes, onChange, isInitialized, date])

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.rangesContainer}>
                <TimePickerRange minValue={0}
                                 maxValue={23}
                                 onChange={value => setHours(value)}
                                 value={hours}/>
                <TimePickerRange minValue={0}
                                 maxValue={60}
                                 onChange={value => setMinutes(value)}
                                 value={minutes}/>
            </div>
        </div>
    );
};

TimePicker.propTypes = {
    className: PropTypes.string
};

export default TimePicker;
