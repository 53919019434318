import React from 'react';
import PropTypes from 'prop-types';
import styles from './VolumeRange.module.scss';

const VolumeRange = ({ title, value, onChange }) => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {title}
            </div>
            <div className={styles.rangeContainer}>
                <div className={styles.value}>
                    {value}
                </div>
                <input className={styles.range}
                       type='range'
                       value={value}
                       onChange={onChange}/>
            </div>
        </div>
    );
};

VolumeRange.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func
};

export default VolumeRange;
