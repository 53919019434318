import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { accessTokenSelector } from "../../../redux/modules/auth/auth-selectors";
import axios from "axios";

const mapStateToProps = (state) => ({
    accessToken: accessTokenSelector(state)
});

const PrivateRoute = ({ component, render, accessToken, ...props }) => {
    const [authorized, setAuthorized] = React.useState(!!accessToken);

    React.useEffect(() => {
        if (accessToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            setAuthorized(true);
        }
    }, [accessToken])

    const Component = render ? render : component;
    return <Route {...props} render={props =>
        authorized ? <Component {...props} /> : <Redirect to={'/auth'}/>
    }/>
};

PrivateRoute.propTypes = {
    component: PropTypes.element,
    render: PropTypes.func,
    ...Route.propTypes
};

export default connect(mapStateToProps)(PrivateRoute);
