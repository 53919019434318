export const REFERENCE_TEST_DATA = [
    {
        color: '#BE6D32',
        disabled: false,
        contents: [
            {
                title: 'Реклама Мирамистина',
                duration: '20 сек',
                startTime: '14:00:01'
            },
            {
                title: 'Реклама Пиццы',
                duration: '14 сек',
                startTime: '14:01:02'
            },
        ]
    },
    {
        color: null,
        disabled: true,
        contents: [
            {
                title: 'Продажа земли',
                duration: '34 сек',
                startTime: '14:02:03'
            },
        ]
    },
    {
        color: '#1D6C1B',
        disabled: false,
        contents: [
            {
                title: 'Погода в самаре',
                duration: '41 сек',
                startTime: '14:03:04'
            }
        ]
    },
    {
        color: '#4B1E6F',
        disabled: false,
        contents: [
            {
                title: 'Мне сегодня 30 лет',
                duration: '41 сек',
                startTime: '14:15:04'
            },
            {
                title: 'Daft Punk - Harder, Bett...',
                duration: '14 сек',
                startTime: '14:16:04'
            },
        ]
    },{
        color: '#BE6D32',
        disabled: false,
        contents: [
            {
                title: 'Реклама Мирамистина',
                duration: '20 сек',
                startTime: '14:00:01'
            },
            {
                title: 'Реклама Пиццы',
                duration: '14 сек',
                startTime: '14:01:02'
            },
        ]
    },
    {
        color: null,
        disabled: true,
        contents: [
            {
                title: 'Продажа земли',
                duration: '34 сек',
                startTime: '14:02:03'
            },
        ]
    },
    {
        color: '#1D6C1B',
        disabled: false,
        contents: [
            {
                title: 'Погода в самаре',
                duration: '41 сек',
                startTime: '14:03:04'
            }
        ]
    },
    {
        color: '#4B1E6F',
        disabled: false,
        contents: [
            {
                title: 'Мне сегодня 30 лет',
                duration: '41 сек',
                startTime: '14:15:04'
            },
            {
                title: 'Daft Punk - Harder, Bett...',
                duration: '14 сек',
                startTime: '14:16:04'
            },
        ]
    },
];
