import React, { useState } from 'react';
import styles from './MediaplanAddObjectsScene.module.scss';
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Search from "../../../../components/Search";
import Checkbox from "../../../../components/common/Checkbox";
import ObjectCard from "../../../../components/Cards/RadioObjectCard";
import CardCheckbox from "../../../../components/Cards/CardCheckbox";
import Button from "../../../../components/common/Button";
import Pagination from "../../../../components/Pagination";
import classNames from 'class-names';
import CheckboxWithLabel from "../../../../components/common/Checkbox/CheckboxWithLabel";
import ListContainer from "../../../../components/ListContainer";
import { useSelectableList } from "../../../../hooks/useSelectableList";
import { useRadioObjects } from "../../../../hooks/radio/useRadioObjects";
import { radioFormDataSelector } from "../../../../redux/modules/radio/radio-selectors";
import { setRadioFormData } from "../../../../redux/modules/radio/radio-actions";
import { connect } from "react-redux";
import Spinner from "../../../../components/Spinner";
import { useHistory } from "react-router-dom";
import { RADIO_ENTITY } from "../../../../constants/radioEntities";

const MEDIAPLAN_CREATE_URL = '/radio/mediaplan/create';

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Эфир',
    href: '/radio/broadcast'
}, {
    name: 'Создание медиаплана',
    href: MEDIAPLAN_CREATE_URL
}, {
    name: 'Добавление объектов',
    href: '/radio/mediaplan/objects/add'
}];

const mapStateToProps = (state) => ({
    formData: radioFormDataSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    setFormData: (data) => dispatch(setRadioFormData(data)),
});

const MediaplanAddObjectsScene = ({formData, setFormData}) => {
    useSetCurrentSection(PAGE_SUBSECTIONS.BROADCAST);

    /*const [searchType, setSearchType] = useState('name');*/

    const [onToggle, selectAll, selectedIds, unselectAll, setSelectedIds] = useSelectableList();

    const [data, isLoaded, setPage, setQuery] = useRadioObjects();
    const history = useHistory();

    React.useEffect(() => {
        if (!formData?.entityName || formData?.entityName !== RADIO_ENTITY.MEDIAPLAN) {
            history.push(MEDIAPLAN_CREATE_URL);
        } else {
            setSelectedIds(formData?.data?.objects);
        }
    }, [formData.data, formData.entityName, history, setSelectedIds]);

    const toggleAll = () => {
        const ids = data.list.map(({ id }) => id);
        if (selectedIds.length === ids.length) {
            setSelectedIds([]);
        } else {
            setSelectedIds(ids);
        }
    };

    const onSave = () => {
        setFormData({
            ...formData,
            data: {
                ...formData.data,
                objects: selectedIds,
            }
        });
        console.log(setFormData({
            ...formData,
            data: {
                ...formData.data,
                objects: selectedIds,
            }
        }));

        history.push(MEDIAPLAN_CREATE_URL)
    };

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            {/*<div className={styles.searchType}>
                <Checkbox name={'name'}
                          type='card'
                          checked={searchType === 'name'}
                          onChange={() => setSearchType('name')}>
                    По названию
                </Checkbox>
                <Checkbox name={'address'}
                          type='card'
                          checked={searchType === 'address'}
                          onChange={() => setSearchType('address')}>
                    По адресу
                </Checkbox>
            </div>*/}
            <div className={styles.controlsContainer}>
                <Search showFilter={false}
                        containerClassName={styles.searchContainer}
                        onChange={({target}) => setQuery(target.value)}
                        actions={
                            <div className={classNames(styles.buttonsContainer, styles.hideMobile)}>
                                <Button className={styles.button} href={MEDIAPLAN_CREATE_URL}>
                                    Отменить
                                </Button>
                                <Button className={styles.button} onClick={onSave}>
                                    Выбрать
                                </Button>
                            </div>}/>
            </div>
            {isLoaded ? (<>
                    <CheckboxWithLabel onChange={toggleAll}
                                       checked={selectedIds.length === data.list.length}
                                       labelChecked={'Снять'}
                                       labelUnchecked={'Выбрать'}/>
                    <ListContainer noLoader className={styles.listContainer}>
                        {data.list.map(item => (
                            <ObjectCard
                                data={item}
                                key={item.id}
                                onClick={() => onToggle(item.id)}
                                checkbox={<CardCheckbox
                                    onChange={onToggle}
                                    value={item.id}
                                    checked={selectedIds.includes(item.id)}/>}
                            />
                        ))}
                    </ListContainer>
                </>
            ) : (
                <div className={styles.spinnerContainer}>
                    <Spinner/>
                </div>
            )}
            {isLoaded && <Pagination
                entityName={'Объектов'}
                totalPages={data.totalPages}
                count={data.totalEntities}
                resultsPerPage={data.pageSize}
                currentPage={data.pageNumber}
                setPage={setPage}
            />}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaplanAddObjectsScene);
