import React from 'react';
import PropTypes from 'prop-types';
import styles from './BroadcastReferenceItem.module.scss';
import classNames from 'class-names';

const BroadcastReferenceItem = ({ data }) => {

    const getMarkerClassName = (index) => ({
        [styles.first]: index === 0,
        [styles.last]: index === data.contents.length - 1
    });

    return (
        <>
            {data.contents.map((item, index) => (
                <div className={classNames(styles.container, {[styles.disabled]: data.disabled})} key={index}>
                    <div className={classNames(styles.colorMarkerContainer, getMarkerClassName(index))}>
                        <div className={styles.colorMarker}
                             style={{ backgroundColor: data.color }}/>
                    </div>
                    <div className={styles.infoText}>
                        {item.startTime}
                    </div>
                    <div className={classNames(styles.infoText, styles.infoTitle)}>
                        {item.title}
                    </div>
                    <div className={styles.infoText}>
                        {item.duration}
                    </div>
                </div>
            ))}
        </>
    );
};

BroadcastReferenceItem.propTypes = {
    data: PropTypes.object.isRequired
};

export default BroadcastReferenceItem;
