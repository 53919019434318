import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardCheckbox.module.scss';
import Checkbox from '../../common/Checkbox';
import classNames from 'class-names';

const CardCheckbox = ({ checked, onChange, className, classNameCheckbox }) => {
    return (
        <div className={classNames(styles.container, className)}>
            <Checkbox className={classNameCheckbox} type='card' checked={checked} onChange={onChange}/>
        </div>
    );
};

CardCheckbox.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    onChange: PropTypes.func
};

export default CardCheckbox;
