import React from 'react';
import styles from './NewCheckbox.module.scss';
import classNames from 'class-names';
import { randomNumber } from "../../../../utils/random";

const Checkbox = ({ children, type = 'filter', className, ...props }) => {
    const [id] = React.useState(randomNumber(0, 10000000));
    return (
        <label htmlFor={id} className={classNames(type === 'filter' ? styles.filterCheckbox : styles.checkbox, className)}>
            {children}
            <input id={id} type="checkbox" {...props}/>
            <span className={styles.checkmark}/>
        </label>
    );
};


export default Checkbox;
