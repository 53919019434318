import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ListContainer.module.scss';
import classNames from 'class-names';
import Spinner from "../Spinner";
import { randomNumber } from "../../utils/random";

const ListContainer = ({
    children,
    className,
    autoHeight,
    noLoader,
    emptyText = 'Записи отсутствуют',
}) => {
    const element = useRef(null);
    const [minHeight, setMinHeight] = useState('0');
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if(!autoHeight && element && element.current) {
            const distanceToTop = window.pageYOffset + element.current.getBoundingClientRect().top;
            setMinHeight(`${window.innerHeight - distanceToTop}px`);
        }
    }, [setMinHeight, autoHeight, children]);

    useEffect(() => {
        const timeout = setTimeout(
            () => setLoading(false), noLoader ? 0 : randomNumber(0.7, 1.5) * 1000
        );
        return () => clearTimeout(timeout);
    }, [noLoader]);

    useEffect(() => {
        const timeout = !isLoading && setTimeout(
            () => element.current.classList.add(styles.visible), 300
        );

        return () => clearTimeout(timeout);
    }, [isLoading]);

    return (
        <>
            {isLoading &&
                (<div className={styles.spinnerContainer}>
                    <Spinner />
                </div>)}
            <div className={classNames(styles.container, className)}
                 style={{ minHeight }}
                 ref={element}>
                {(children && children.length) ? children : (
                    <div className={styles.emptyText}>
                        {emptyText}
                    </div>
                )}
            </div>
        </>
    )
};

ListContainer.propTypes = {
    className: PropTypes.string,
    autoHeight: PropTypes.bool
};

export default ListContainer;
