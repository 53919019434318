import {
    SET_PROFILE_DATA,
    SET_PROFILE_DOCUMENTS,
    SET_PROFILE_BILLS,
    SET_PROFILE_MAINTENANCE_REASONS,
    SET_PROFILE_MAINTENANCE_STATUSES,
    SET_PROFILE_MAINTENANCES,
    SET_SELECTED_OBJECT,
} from './profile-actions';

const profileInitialState = {
    profileData: null,
    profileDocuments: null,
    bills: null,
    maintenanceReasons: null,
    maintenanceStatuses: null,
    maintenances: null,
    selectedObject: [],
};

export const profileReducer = (state = profileInitialState, action) => {
    switch (action.type) {
        case SET_PROFILE_DATA:
        return { ...state, profileData: action.payload.profileData };

        case SET_PROFILE_DOCUMENTS:
            return {
                ...state,
                profileDocuments: action.payload.profileDocuments,
            };

        case SET_PROFILE_BILLS:
            return {
                ...state,
                bills: action.payload.bills,
            };

        case SET_PROFILE_MAINTENANCE_REASONS:
            return { ...state, maintenanceReasons: action.payload.maintenanceReasons };

        case SET_PROFILE_MAINTENANCE_STATUSES:
            return { ...state, maintenanceStatuses: action.payload.maintenanceStatuses };

        case SET_PROFILE_MAINTENANCES:
            return { ...state, maintenances: action.payload.maintenances };

        case SET_SELECTED_OBJECT:
            return { ...state, selectedObject: action.payload.selectedObject };

        default:
            return state;
    }
};
