import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'class-names';
import styles from './AuthInput.module.scss';

const AuthInput = ({ icon, label, type, hint, className, ...props }) => {
    const [showPassword, setShow] = useState(false);

    const inputType = type === "password" ? (showPassword ? "text" : "password") : type;

    return (
        <>
            <div className={classNames(styles.container, className)}>
                {label ? <div className={styles.textLabel}>{label}</div> : <div className={styles.icon}
                                                                                style={{backgroundImage: `url(${icon})`}}/>}
                {type === "password" && <div className={styles.eyeIcon} onClick={() => setShow(!showPassword)}/>}
                <input className={styles.input} type={inputType} {...props}/>
            </div>
            {hint && <div className={styles.hint}>{hint}</div> }
        </>
    );
};

AuthInput.propTypes = {
    label: PropTypes.string,
    hint: PropTypes.string
};

export default AuthInput;
