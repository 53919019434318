import React, { useState } from 'react';
import styles from './BroadcastReferenceScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ItemListTitle from "../../../../components/ItemListTitle";
import objectIcon from "../../../../img/icons/object-dark-grey-icon.svg";
import RecordStatus from "../../../../components/Cards/RecordCard/RecordStatus";
import { RECORD_ONLINE_STATUS, RECORD_STATUS } from "../../../../constants/recordTypes";
import Button from "../../../../components/common/Button";
import Input from "../../../../components/common/Input";
import TimePicker from "../../../../components/TimePicker";
import classNames from 'class-names';
import BroadcastReference from "../../../../components/BroadcastReference";
import { REFERENCE_TEST_DATA } from "../../../../constants/referenceTestData";
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Объекты',
    href: '/radio/objects'
}, {
    name: 'Страница объекта',
    href: '/radio/object'
}, {
    name: 'Эфирная справка',
    href: '/radio/object/reference'
}];

const BroadcastReferenceScene = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.OBJECTS);

    const [datepickerVisible, setDatepickerVisible] = useState(false);

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.pageHeader}>
                <ItemListTitle icon={objectIcon}
                               className={styles.pageTitle}
                               title={"09385-19 ТГПУ Очное заочное"}>
                    ID 51464 <RecordStatus onlineStatus={RECORD_ONLINE_STATUS.ONLINE}
                                           status={RECORD_STATUS.READY}/>
                </ItemListTitle>
                <div className={styles.formControls}>
                    Дата и время
                    <Button onClick={() => setDatepickerVisible(!datepickerVisible)} className={styles.formButton}>
                        {datepickerVisible ? "Скрыть" : "Добавить"}
                    </Button>
                </div>
                <div className={classNames(styles.datepickerContainer, { [styles.visible]: datepickerVisible })}>
                    <TimePicker className={classNames(styles.timePicker, styles.hideDesktop)}/>
                    <Input name={'startDate'}
                           className={styles.dateInput}
                           label={'Дата'}
                           type={'date'}/>
                    <Input name={'startTime'}
                           className={classNames(styles.dateInput, styles.hideMobile)}
                           label={'Время'}
                           type={'time'}/>
                    <Button className={classNames(styles.datepickerButton, styles.hideDesktop)}>
                        Применить
                    </Button>
                </div>
            </div>
            <div className={styles.hintText}>
                Нажав на любую строчку вы можете прослушать выбранную запись
            </div>
            <BroadcastReference data={REFERENCE_TEST_DATA}/>
        </div>
    );
};

export default BroadcastReferenceScene;
