import React from 'react';
import Spinner from "../../components/Spinner";
import styles from './AuthScene.module.scss';
import { Redirect, useLocation } from "react-router-dom";
import { authorize as authorizeAction, checkAuth as checkAuthAction } from "../../redux/modules/auth/auth-thunk";
import { connect } from "react-redux";
import { accessTokenSelector } from "../../redux/modules/auth/auth-selectors";

const AuthScene = ({authorize, checkAuth, isAuthorized}) => {
    const location = useLocation();

    React.useEffect(() => {
        if (!isAuthorized) {
            const searchParams = new URLSearchParams(location.search);
            const code = searchParams.get('code');
            const state = searchParams.get('state');

            if (!code || !state) {
                checkAuth();
            } else {
                authorize({ code, state });
            }
        }
    }, [authorize, checkAuth, isAuthorized, location.search]);

    if (isAuthorized) {
        return <Redirect to={'/'} />;
    }

    return (
        <div className={styles.container}>
            <Spinner />
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAuthorized: !!accessTokenSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    checkAuth: () => dispatch(checkAuthAction()),
    authorize: ({code, state}) => dispatch(authorizeAction({code, state})),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthScene);
