import React, {useEffect, useState} from "react";
import styles from "./PlaylistEdit.module.scss";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Search from "../../../components/Search";
import Checkbox from "../../../components/common/Checkbox";
import ObjectCard from "../../../components/Cards/ObjectEditPlaylistCard";
import CardCheckbox from "../../../components/Cards/CardCheckbox";
import Button from "../../../components/common/Button";
import classNames from 'class-names';
import ListContainer from "../../../components/ListContainer";
import { useSelectableList } from "../../../hooks/useSelectableList";
import { showModal } from "../../../redux/actions/ui/modal/modal";
import {connect, useDispatch, useSelector} from "react-redux";
import {useSetCurrentSection} from "../../../hooks/useSetCurrentSection";
import {PAGE_SUBSECTIONS} from "../../../constants/pageTypes";
import {useRadioObjects} from "../../../hooks/radio/useRadioObjects";
import Spinner from "../../../components/Spinner";
import {loadObjectPlaylists, sendObjectPlaylist} from "../../../redux/modules/background/background-actions";
import Pagination from "../../../components/Pagination";
import {objectPlaylists} from "../../../redux/modules/background/background-selectors";
import {radioDataSelector} from "../../../redux/modules/radio/radio-selectors";

const breadcrumbsRoutes = (id, namePlaylist) => [
    {
        name: "Фон",
        href: "/background",
    },
    {
        name: "Плейлисты",
        href: "/background/playlists",
    },
    {
        name: `${namePlaylist}`,
        href: `/background/playlists/${id}`,
    },
    {
        name: "Редактировать",
        href: `/background/playlists/${id}/edit`,
    }
];

const mapDispatchToProps = (dispatch) => ({
    showModal: (data) => dispatch(showModal(data)),
});

const PlaylistEdit = ({ showModal }) => {
    const location = useLocation();
    useSetCurrentSection(location?.state?.RAO ? PAGE_SUBSECTIONS.RAO : PAGE_SUBSECTIONS.PLAYLISTS);
    const data = useSelector(radioDataSelector);
    const [ , isLoaded, setPage, setQuery] = useRadioObjects();
    const [onToggle, ,selectedIds]  = useSelectableList(data?.list?.length, true);
    const [searchType, setSearchType] = useState('name');
    // const playlistsOnObject = useSelector(objectPlaylists);
    const history = useHistory();
    const dispatch = useDispatch();
    let { id } = useParams();

    // useEffect(() => {
        // data && data.list.forEach(item => {
        //     dispatch(loadObjectPlaylists(item?.id));
        // });
    // },[data, dispatch, id]);

    const params = {
        playlistId: id,
        usedByDefault: true,
    };

    const onSubmit = () => {
        selectedIds.forEach(id => {
            dispatch(sendObjectPlaylist(id, params));
        })
    };

    if (!data) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner/>
            </div>
        )
    }
    // else {
        // data.list.forEach(item => {
        //     dispatch(loadObjectPlaylists(item?.id));
        //     if (playlistsOnObject && playlistsOnObject?.playlist?.id === id) {
        //         onToggle(item?.id);
        //     }
        // });
    // }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes(id, location?.state?.name)} className={styles.breadcrumbs}/>
            <div className={styles.searchType}>
                <Checkbox name={'name'}
                          type='card'
                          checked={searchType === 'name'}
                          onChange={() => setSearchType('name')}>
                    По названию
                </Checkbox>
                <Checkbox name={'address'}
                          type='card'
                          checked={searchType === 'address'}
                          onChange={() => setSearchType('address')}>
                    По адресу
                </Checkbox>
            </div>
            <div className={styles.controlsContainer}>
                <Search showFilter={false}
                        onChange={({target}) => setQuery(target.value)}
                        containerClassName={styles.searchContainer}
                        actions={
                            <div className={classNames(styles.buttonsContainer, styles.hideMobile)}>
                                <Button onClick={() => history.goBack()}>
                                    Отменить
                                </Button>
                                <Button onClick={() => {
                                    showModal({
                                        title: "",
                                        text: "Плейлист установлен в качестве плейлиста по умолчанию на выбранных объектах",
                                        submitText: "Хорошо",
                                        cancelText: "Отмена",
                                        onSubmit: () => {
                                            onSubmit();
                                            history.goBack();
                                        }
                                    })}}>
                                    Выбрать
                                </Button>
                            </div>
                        }/>
            </div>
            <div className={classNames(styles.buttonsContainer, styles.hideDesktop)}>
                <Button onClick={() => history.goBack()}>
                    Отменить
                </Button>
                <Button
                    onClick={() => {
                        showModal({
                            title: " ",
                            text: "Плейлист установлен в качестве плейлиста по умолчанию на выбранных объектах",
                            submitText: "Хорошо",
                            cancelText: "Отмена",
                            onSubmit: () => {
                                onSubmit();
                                history.goBack();
                            }
                        })}}>
                    Выбрать
                </Button>
            </div>
            <ListContainer className={styles.listContainer} noLoader>
                {isLoaded && data.list.map((item, index) => (
                    <ObjectCard
                        data={item}
                        index={index}
                        onClick={() => onToggle(item.id)}
                        key={index}
                        checkbox={
                            <CardCheckbox
                                onChange={onToggle}
                                value={item.id}
                                checked={selectedIds.includes(item.id)}
                            />
                        }
                    />
                ))}
            </ListContainer>
            {isLoaded && <Pagination
                entityName={'Объектов'}
                totalPages={data.totalPages}
                count={data.totalEntities}
                resultsPerPage={data.pageSize}
                currentPage={data.pageNumber}
                setPage={setPage}
            />}
        </div>
    );
}

export default connect(undefined, mapDispatchToProps)(PlaylistEdit);
