import { addAuthCookie } from "../../../utils/authCookies";
import { setAuthorized, setTokens } from "./auth-actions";
import { accessTokenSelector, authUniqueStateSelector, refreshTokenSelector } from "./auth-selectors";
import { randomString } from "../../../utils/random";
import { clearLocalStorage, removeFromLocalStorage, saveToLocalStorage } from "../../../utils/localStorage";
import { loadInitialTokens, logOutRequest, redirectToAuth } from "../../../api/auth-api";

export const logIn = ({ login, password }) => async (dispatch) => {
    addAuthCookie();

    dispatch(setAuthorized(true));
}

export const checkAuth = () => async (dispatch, getState) => {
    const accessToken = accessTokenSelector(getState());
    const refreshToken = refreshTokenSelector(getState());

    if (!accessToken && !refreshToken) {
        const uniqueState = randomString(16);
        saveToLocalStorage('uniqueState', uniqueState);
        redirectToAuth(uniqueState);
    }
}

export const authorize = ({code: authCode, state: uniqueCode}) => async (dispatch, getState) => {
    const savedCode = authUniqueStateSelector(getState());
    console.warn('Trying to authorize with given code and state');

    if (savedCode !== uniqueCode) {
        console.warn(`code ${uniqueCode} does not match ${savedCode}`);
        clearLocalStorage();
        dispatch(checkAuth());
    } else {
        try {
            const {data} = await loadInitialTokens(authCode);

            const accessToken = data['access_token'];
            const refreshToken = data['refresh_token'];


            dispatch(setTokens({accessToken, refreshToken}));
            saveToLocalStorage('accessToken', accessToken);
            saveToLocalStorage('refreshToken', refreshToken);
        } catch (e) {
            clearLocalStorage();
            dispatch(checkAuth());
            console.error(e);
        }
    }
}

export const logOut = (withRequest = true) => async (dispatch) => {
    removeFromLocalStorage('accessToken');
    removeFromLocalStorage('refreshToken');
    removeFromLocalStorage('uniqueState');
    withRequest && await logOutRequest();
    window.location.href = window.location.origin;
}

export const register = ({ phone, email, password, inn, name }) => async (dispatch) => {

    addAuthCookie();
    dispatch(setAuthorized(true));
}
