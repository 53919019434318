export const RADIO_ENTITY = {
    OBJECT_LIST: 'object_list',
    MEDIAPLAN_LIST: 'mediaplan_list',
    OBJECT: 'object',
    MEDIAPLAN: 'mediaplan',
    PRIORITY_ADS: 'priority_ads',
};

export const RADIO_LIST_ENTITIES = [
    RADIO_ENTITY.OBJECT_LIST,
    RADIO_ENTITY.MEDIAPLAN_LIST,
    RADIO_ENTITY.PRIORITY_ADS,
];
