import React, { useState } from 'react';
import PropTypes from 'prop-types';
import GenericCard from "../GenericCard";
import styles from './MediaplanObjectCard.module.scss';
import Popup from "../../Popup";
import Button from "../../common/Button";

const MediaplanObjectCard = ({ checkbox, id, name, address, onClick, onDelete }) => {
    const [popupShown, setPopupShown] = useState(false);

    return (
        <GenericCard headClassName={styles.head}
                     onClick={onClick}
                     className={styles.card}
                     headContent={<div className={styles.headInfo}>
                         <div className={styles.checkboxContainer}>
                             {checkbox}
                         </div>
                         <div className={styles.icon}/>
                         ID {id} • {name}
                         <div className={styles.menuButton} onClick={() => setPopupShown(!popupShown)}>
                             {popupShown &&
                             <Popup closePopup={() => setPopupShown(false)} className={styles.popup}>
                                 <div className='popup-menu-item' onClick={()=> onDelete(id)}>
                                     Удалить
                                 </div>
                             </Popup>}
                         </div>
                     </div>}>
            <div className={styles.dataContainer}>
                <div className={styles.infoContainer}>
                    <div className={styles.infoBlock}>
                        <div className={styles.infoTitle}>
                            Адрес объекта:
                        </div>
                        <div className={styles.infoValue}>
                            {address}
                        </div>
                    </div>
                </div>
                <Button className={styles.deleteButton} onClick={()=> onDelete(id)}>
                    Удалить
                </Button>
            </div>
        </GenericCard>
    );
};

MediaplanObjectCard.propTypes = {
    checkbox: PropTypes.node
};

export default MediaplanObjectCard;
