import { radioMetadataSelector, radioParamsSelector } from "./radio-selectors";
import {
    fetchRadioColors, fetchRadioMediaplan,
    fetchRadioMediaplanList, fetchRadioObject,
    fetchRadioObjectList,
    fetchRadioObjectStatuses, fetchPriorityAds, patchRadioMediaplan, postRadioMediaplan, fetchObjectCreateMetadata
} from "../../../api/radio-api";
import { RADIO_ENTITY, RADIO_LIST_ENTITIES } from "../../../constants/radioEntities";
import { DEFAULT_LIST_PARAMS } from "./radio-reducer";
import { showModal } from "../../actions/ui/modal/modal";

export const SET_RADIO_METADATA = 'SET_RADIO_METADATA';
export const SET_RADIO_DATA = 'SET_RADIO_DATA';
export const SET_RADIO_FORM_DATA = 'SET_RADIO_FORM_DATA';
export const SET_RADIO_PARAMS = 'SET_RADIO_PARAMS';
export const RESET_RADIO_PARAMS = 'RESET_RADIO_PARAMS';
export const OBJECT_CREATE_METADATA = 'OBJECT_CREATE_METADATA';

const setRadioMetadata = (metadata) => ({
    type: SET_RADIO_METADATA,
    payload: metadata,
})

const setRadioData = (data) => ({
    type: SET_RADIO_DATA,
    payload: data,
});

export const setRadioFormData = (data) => ({
    type: SET_RADIO_FORM_DATA,
    payload: data,
});

export const setRadioParams = (params) => ({
    type: SET_RADIO_PARAMS,
    payload: params,
});

const setObjectCreateMetadata = (payload) => ({
    type: OBJECT_CREATE_METADATA,
    payload
})

const handleRadioError = (error) => (dispatch) =>  {
    if (error.response && error.response.data) {
        const responseData = error.response.data;
        const errorMessage = responseData.errorMessage || `${responseData.status} - ${responseData.message}`;
        console.error({error});
        if (errorMessage) {
            dispatch(showModal({
                title: 'Ошибка',
                text: errorMessage,
                cancelText: 'Закрыть',
                submitText: 'Назад',
                onSubmit: () => window.history.back(),
            }))
        }
    } else {
        console.error(error);
    }
};

export const loadRadioMetadata = () => async (dispatch, getState) => {
    const currentMetadata = radioMetadataSelector(getState());

    if (currentMetadata) {
        return;
    }

    try {
        const {data: colors} = await fetchRadioColors();
        const {data: objectStatuses} = await fetchRadioObjectStatuses();

        const metadata = {
            colors,
            objectStatuses,
        };

        dispatch(setRadioMetadata(metadata));
    } catch (e) {
        dispatch(handleRadioError(e));
    }
};

const resetRadioParams = (entityName) => (dispatch, getState) => {
    const params = radioParamsSelector(getState());

    if (!params.entityName || params.entityName !== entityName) {
        if (RADIO_LIST_ENTITIES.includes(entityName)) {
            dispatch(setRadioParams({
                ...DEFAULT_LIST_PARAMS,
                entityName,
            }));
        } else {
            dispatch(setRadioParams({
                entityName,
            }));
        }
    }
};

export const loadRadioData = (
    entityName,
    id,     //опционально, для страниц зависящих от id
) => async (dispatch) => {
    dispatch(resetRadioParams(entityName));

    const loadAction = {
        [RADIO_ENTITY.OBJECT_LIST]: loadRadioObjectList,
        [RADIO_ENTITY.OBJECT]: loadRadioObject,
        [RADIO_ENTITY.MEDIAPLAN_LIST]: loadRadioMediaplanList,
        [RADIO_ENTITY.MEDIAPLAN]: loadRadioMediaplan,
        [RADIO_ENTITY.PRIORITY_ADS]: loadRadioPriorityAds,
    }[entityName];

    dispatch(loadAction(id));
};

const loadRadioObject = (id) => async (dispatch) => {
    try {
        const {data} = await fetchRadioObject(id);

        dispatch(setRadioData({
            isLoading: false,
            data,
            id,
            entityName: RADIO_ENTITY.OBJECT,
        }));
    } catch (e) {
        dispatch(handleRadioError(e));
    }
}

const loadRadioPriorityAds = (id) => async (dispatch) => {
    try {
        const {data} = await fetchPriorityAds(id);

        dispatch(setRadioData({
            isLoading: false,
            list: data,
            id,
            entityName: RADIO_ENTITY.PRIORITY_ADS,
        }));
    } catch (e) {
        dispatch(handleRadioError(e));
    }
}

const loadRadioMediaplan = (id) => async (dispatch) => {
    try {
        const {data} = await fetchRadioMediaplan(id);

        dispatch(setRadioData({
            isLoading: false,
            data,
            id,
            entityName: RADIO_ENTITY.MEDIAPLAN,
        }));
    } catch (e) {
        dispatch(handleRadioError(e));
    }
}

const loadRadioObjectList = () => async (dispatch, getState) => {
    const params = radioParamsSelector(getState());

    try {
        const {data} = await fetchRadioObjectList(params);

        dispatch(setRadioData({
            isLoading: false,
            list: data.items,
            pageNumber: data.pageNumber,
            totalPages: data.totalPages,
            totalEntities: data.totalElements,
            entityName: RADIO_ENTITY.OBJECT_LIST,
        }));
    } catch (e) {
        dispatch(handleRadioError(e));
    }
};

const loadRadioMediaplanList = () => async (dispatch, getState) => {
    const params = radioParamsSelector(getState());

    try {
        const {data} = await fetchRadioMediaplanList(params);

        dispatch(setRadioData({
            isLoading: false,
            list: data.items,
            pageNumber: data.pageNumber,
            totalPages: data.totalPages,
            totalEntities: data.totalElements,
            entityName: RADIO_ENTITY.MEDIAPLAN_LIST,
        }))
    } catch (e) {
        dispatch(handleRadioError(e));
    }
};

export const createRadioMediaplan = (mediaplan) => async (dispatch) => {
    try {
        const {data} = await postRadioMediaplan(mediaplan);
    } catch (e) {
        dispatch(handleRadioError(e));
    }
};

export const editRadioMediaplan = (mediaplan, id) => async (dispatch) => {
    try {
        await patchRadioMediaplan(mediaplan, id);
        window.history.back();
    } catch (e) {
        dispatch(handleRadioError(e));
    }
};

export const loadObjectCreateMetadata = () => async (dispatch) => {
    try {
        const {data} = await fetchObjectCreateMetadata();
        dispatch(setObjectCreateMetadata(data));
    } catch (e) {
        dispatch(handleRadioError(e));
    }
}
