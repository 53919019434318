import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './Pagination.module.scss';
import classNames from 'class-names';
import { range } from "../../utils/range";

const Pagination = ({
    count,
    currentPage: pageIndex,
    resultsPerPage,
    setPage,
    items,
    selectable,
    setSelected,
    selectedIds,
    entityName,

    totalPages,
}) => {

    const currentPage = pageIndex + 1;

    const [fixed, setFixed] = useState(true);
    const containerRef = useRef(null);

    const scrollListener = useCallback(() => {
            const { bottom: containerBottom } = containerRef.current.getBoundingClientRect();
            if (containerBottom >= document.documentElement.clientHeight)
                setFixed(true);
            else setFixed(false);
        },
        []);

    useEffect(() => {
        document.addEventListener('scroll', scrollListener);
        return () => {
            document.removeEventListener('scroll', scrollListener)
        }
    });

    const pageCount = totalPages || Math.max(Math.ceil(count / resultsPerPage), 1);

    const pageNumbers = [];
    if (currentPage <= 5) {
        pageNumbers.push(...range(currentPage, 1));
    } else {
        pageNumbers.push(...range(6, currentPage - 5));
    }
    if (pageCount - currentPage <= 5) {
        pageNumbers.push(...range(pageCount - currentPage, currentPage + 1));
    } else {
        pageNumbers.push(...range(5, currentPage + 1));
    }

    const prevPage = () => setPage(pageIndex - 1);

    const nextPage = () => setPage(pageIndex + 1);

    const toggleAll = selectable ? () => {
            if (selectedIds.length === resultsPerPage)
                setSelected([]);
            else {
                const ids = items.map(item => item.id);
                setSelected(items.map(item => item.id));
            }
        }
        : () => {
        };

    return (
        <div className={styles.stickyWrapper} ref={containerRef}>
            <div className={classNames(styles.container, { [styles.fixed]: fixed, [styles.noWrap]: !selectable })}>
                {selectable && <div className={styles.buttonContainer}>
                    <div className={styles.button} onClick={toggleAll}>
                        {selectedIds.length === resultsPerPage ? "Снять" : "Выбрать"} все
                    </div>
                </div>}
                <div className={classNames(styles.recordsCount, { [styles.right]: !selectable })}>
                    {entityName} найдено: <span className={styles.recordsNumber}>{count}</span>
                </div>
                <div className={styles.pages}>
                    {currentPage > 1 && <div className={styles.backIcon} onClick={prevPage}/>}
                    {pageNumbers.map((item, index) => (
                        <span
                            onClick={() => setPage(item - 1)}
                            className={classNames(styles.pageNumber, { [styles.pageCurrent]: item === currentPage })}
                            key={index}>
                        {item}
                    </span>
                    ))}
                    {currentPage < pageCount && <div className={styles.nextIcon} onClick={nextPage}/>}
                </div>
            </div>
        </div>
    );
};

Pagination.propTypes = {
    selectAll: PropTypes.func,
    count: PropTypes.number,
    currentPage: PropTypes.number,
    resultsPerPage: PropTypes.number,
    setPage: PropTypes.func
};

export default Pagination;
