import React, {useEffect, useState} from 'react';
import styles from './TechSupport.module.scss';
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../constants/pageTypes";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Input from "../../../components/common/Input";
import Select from "../../../components/common/Select";
import Button from "../../../components/common/Button";
import { useMediaQuery } from "beautiful-react-hooks";
import ListContainer from "../../../components/ListContainer";
import ObjectCard from "../../../components/Cards/ObjectCard";
import Search from "../../../components/Search";
import classNames from 'class-names';
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/Spinner";
import {
    profileSelectedObject,
    profileSelectorMaintenanceReasons,
} from "../../../redux/modules/profile/profile-selectors";
import {
    loadMaintenanceReasons,
    sendProfileMaintenance, setSelectedObject
} from "../../../redux/modules/profile/profile-actions";
import { useRadioObjects } from "../../../hooks/radio/useRadioObjects";
import {useHistory} from "react-router-dom";
import {radioParamsSelector} from "../../../redux/modules/radio/radio-selectors";
import {useSelectableList} from "../../../hooks/useSelectableList";
import CardCheckbox from "../../../components/Cards/CardCheckbox";

const breadcrumbsRoutes = [{
    name: 'Личный кабинет',
    href: '#'
}, {
    name: 'Техническое сопровождение',
    href: '/profile/tech-support'
}];

const TECH_SUPPORT_STAGE = {
    FORM: 'FORM',
    OBJECTS: 'OBJECTS',
    RESULT: 'RESULT'
};

const TechSupportScene = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.TECH_SUPPORT);
    const isTablet = useMediaQuery('(min-width: 768px)');
    const paramsObjectList = useSelector(radioParamsSelector);
    const maintenanceReasons = useSelector(profileSelectorMaintenanceReasons);
    const selectedObject = useSelector(profileSelectedObject);
    const [data, isLoaded, , setQuery, setAddPageSize] = useRadioObjects();
    const [reason, setReason] = useState(maintenanceReasons && maintenanceReasons[0].id);
    const [onToggle, , selectedId] = useSelectableList(0, false);
    const [comment, setComment] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();

    const [stage, setStage] = useState(TECH_SUPPORT_STAGE.FORM);

    const showForm = isTablet ? (stage !== TECH_SUPPORT_STAGE.RESULT) : (stage === TECH_SUPPORT_STAGE.FORM);
    const showObjects = isTablet ? (stage !== TECH_SUPPORT_STAGE.RESULT) : (stage === TECH_SUPPORT_STAGE.OBJECTS);
    const showResult = stage === TECH_SUPPORT_STAGE.RESULT;

    const location = {
        pathname: '/radio/object/maintenance',
        state: { techSupport: true }
    }

    useEffect(() => {
        dispatch(loadMaintenanceReasons());
    }, [dispatch]);

    const onSubmitObject = () => {
        if (selectedObject.length) {
            setStage(TECH_SUPPORT_STAGE.FORM);
        }
    };

    const onFormOpen = () => {
        setStage(TECH_SUPPORT_STAGE.FORM);
    };

    const onSend = () => {
        setStage(TECH_SUPPORT_STAGE.RESULT);
        const params = {
            objId: selectedObject[0].id,
            reasonId: reason,
            comment,
        };

        dispatch(sendProfileMaintenance(params));
        setComment({});
    }

    const onChange = (item) => {
        selectedObject.includes(item) ?
            dispatch(setSelectedObject([])) :
            dispatch(setSelectedObject([item]));
        onToggle(item.id);
    }

    if (!maintenanceReasons) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes} className={styles.breadcrumbs}/>
            <div className={styles.pageContainer}>
                {showForm &&
                    <div className={styles.formContainer}>
                        <div className={styles.title}>
                            Объект
                        </div>
                        <div className={styles.selectedObjectInfo}>
                            <div className={styles.selectedObjectLabel}>
                                Выбран:
                                {selectedObject.length ? (
                                    <span className={styles.selectedObjectName}>
                                        {selectedObject[0]?.title}
                                    </span>
                                    )
                                : ' -'}
                            </div>
                            <div onClick={() => setStage(TECH_SUPPORT_STAGE.OBJECTS)}
                                className={styles.selectObjectButton}>
                                Выбрать
                            </div>
                        </div>
                        <div className={styles.formElementLabel}>
                            Причина обращения
                        </div>
                        <Select options={maintenanceReasons}
                                className={styles.selectWrapper}
                                onChange={(e) => {
                                    const option = maintenanceReasons.find(item => item.name === e.target.value);
                                    setReason(option.id);
                                }}
                        />
                        <div className={styles.formElementLabel}>
                            Комментарий
                        </div>
                        <Input type="textarea"
                               name="comment"
                               className={styles.textareaContainer}
                               inputClassName={styles.textarea}
                               onChange={(e) => setComment(e.target.value)}
                        />
                        <Button
                            className={classNames(styles.techButton, styles.primary)}
                            onClick={onSend}>
                            Отправить
                        </Button>
                    </div>
                }
                {showObjects && <div className={styles.objectsListContainer}>
                    {selectedObject.length ? (
                        <div className={styles.maitenanceButtonContainer}>
                            <Button
                                className={classNames(styles.techButton, styles.primary)}
                                onClick={() => history.push(location)}
                            >
                                Список всех заявок
                            </Button>
                        </div>
                    ) : null}
                    <Search
                        showFilter={false}
                        searchClassName={styles.searchField}
                        onChange={({target}) => setQuery(target.value)} />
                    <ListContainer noLoader>
                        {isLoaded && data?.list?.map(item =>
                            <ObjectCard
                                responsive={false}
                                data={item}
                                isColorNew
                                key={item.id}
                                onClick={(e) => {
                                    onChange(item);
                                    e.preventDefault();
                                }}
                                checkbox={
                                    <CardCheckbox
                                        onChange={() => onChange(item)}
                                        checked={selectedId.includes(item.id)}
                                    />
                                }
                            />
                        )}
                        {data?.list?.length > 10 && (
                            <Button
                                className={classNames(styles.techButton, styles.primary)}
                                onClick={() => setAddPageSize(paramsObjectList._pageSize + 10)}>
                                Показать еще
                            </Button>
                        )}
                    </ListContainer>
                    <div className={styles.objectsListButtons}>
                        <Button className={styles.techButton} onClick={onFormOpen}>Отмена</Button>
                        <Button className={classNames(styles.techButton, styles.primary)}
                                onClick={onSubmitObject}>Готово</Button>
                    </div>
                </div>}
                {showResult && <div className={styles.resultContainer}>
                    <div className={styles.resultTitle}>
                        Ваше обращение зарегистрировано
                    </div>
                    <div className={styles.resultDesc}>
                        Наши специалисты рассмотрят его в течение часа.
                    </div>
                    <div className={styles.resultButtons}>
                        <Button onClick={() => {
                            onFormOpen();
                            history.push(location);
                        }}
                                className={classNames(styles.techButton, styles.primary, styles.fullWidth)}>
                            Добавить новое
                        </Button>
                        <Button onClick={onFormOpen} className={classNames(styles.techButton, styles.fullWidth)}>
                            Список заявок
                        </Button>
                    </div>
                </div>}
            </div>
        </div>
    );
}

export default TechSupportScene
