import React from 'react';
import PropTypes from 'prop-types';
import styles from './ItemListTitle.module.scss';
import classNames from 'class-names';

const ItemListTitle = ({ children, title, icon, className }) => {
    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.info}>
                <div className={styles.icon} style={{ backgroundImage: `url(${icon})` }}/>
                {children}
            </div>
            <div className={styles.title}>
                {title}
            </div>
        </div>
    );
};

ItemListTitle.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string
};

export default ItemListTitle;
