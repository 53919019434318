import React from 'react';
import PropTypes from 'prop-types';
import styles from './EditInfoButton.module.scss';
import { Link } from "react-router-dom";

const EditInfoButton = ({onClick, children}) => {
    return (
        <Link to={'/profile/edit-data'} className={styles.container} onClick={onClick}>
            {children}
        </Link>
    );
};

EditInfoButton.propTypes = {
    onClick: PropTypes.func
};

export default EditInfoButton;
