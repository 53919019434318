import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ObjectSceneDropdown.module.scss';
import classNames from 'class-names';

const ObjectSceneDropdown = ({ buttonContent, children, className }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div className={classNames(styles.button, className)} onClick={() => setOpen(!open)}>
                {buttonContent(open)}
                <div className={classNames(styles.dropdownIcon, {[styles.open]: children && open})}/>
            </div>
            {open && children}
        </>
    );
};

ObjectSceneDropdown.propTypes = {
    buttonContent: PropTypes.func
};

export default ObjectSceneDropdown;
