import React from 'react';
import styles from './ObjectSceneInfo.module.scss';
import classNames from 'class-names';
import ObjectSceneDropdown from "../ObjectSceneDropdown";
import { Link } from "react-router-dom";
import Button from "../../common/Button";
import tariff from '../../../img/icons/rate.svg';
import eqiupmentRent from '../../../img/icons/Equipment_rent.svg';
import music from '../../../img/icons/music.svg';
import internet from '../../../img/icons/Internet.svg';
import RaoVois from '../../../img/icons/RAO+VOIS.svg';
import documentLoad from '../../../img/icons/document_load_object.svg'

const ObjectSceneInfo = () => {
    return (
        <div className={styles.container}>
            <div className={styles.infoBlock}>
                <div className={styles.name}>
                    Информация о объекте
                </div>
                <div className={styles.value}>
                    Эфир выключен до 2019-02-28 9:00:00
                </div>
            </div>
            <div className={styles.infoBlock}>
                <div className={styles.name}>
                    Длительность текущего сеанса связи
                </div>
                <div className={styles.value}>
                    00:01:43
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Состояние транзакции
                </div>
                <div className={styles.value}>
                    Обработка
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Последняя транзакция
                </div>
                <div className={styles.value}>
                    09.09.2019
                </div>
            </div>
            <div className={styles.sectionTitle}>
                Общие сведения
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Регион
                </div>
                <div className={styles.value}>
                    Московская обл.
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Проходимость объекта
                </div>
                <div className={styles.value}>
                    1000
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Дата создания
                </div>
                <div className={styles.value}>
                    09.09.2019
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Категория проходимости
                </div>
                <div className={styles.value}>
                    А+
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Часы работы объекта
                </div>
                <div className={styles.value}>
                    9:00 - 10:00
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Часы работы рекламы
                </div>
                <div className={styles.value}>
                    10:00 - 11:00
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Часы работы фона
                </div>
                <div className={styles.value}>
                    11:00 - 12:00
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Широта объекта
                </div>
                <div className={styles.value}>
                    10° 00' с. ш.
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Долгота объекта
                </div>
                <div className={styles.value}>
                    8° 00' в. д.
                </div>
            </div>
            <div className={styles.sectionTitle}/>
            <div className={styles.infoBlock}>
                <div className={styles.name}>
                    ФИО ответственного
                </div>
                <div className={styles.value}>
                    Жмышенко Валерий Альбертович
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Дни работы
                </div>
                <div className={styles.value}>
                    Пн, Вт, Ср.
                </div>
            </div>
            <div className={classNames(styles.infoBlock, styles.half)}>
                <div className={styles.name}>
                    Часы работы
                </div>
                <div className={styles.value}>
                    8:00 - 18:00
                </div>
            </div>
            <ObjectSceneDropdown buttonContent={(open) =>
                <>
                    <div className={styles.rateIcon}/>
                    {open ? "Скрыть информацию о тарифе" : "Тариф"}
                </>}>
                <div className={styles.infoBlock}>
                    <div className={classNames(styles.name, styles.header)}>
                        <img className={styles.tariffImage} src={tariff}/>
                        <p className={styles.tariff}>Тариф 2 (2 990 ₽)</p>
                    </div>
                    <div className={classNames(styles.value, styles.header)}>
                        <p>Краткое описание тарифа при необходимости</p>
                    </div>
                </div>
                <div className={classNames(styles.infoBlock, styles.half)}>
                    <div className={styles.name}>
                        <img className={styles.tariffBlockImage} src={eqiupmentRent}/>
                        <p className={styles.tariffBlock}>Аренда оборудования</p>
                    </div>
                    <div className={styles.value}>
                        <p>2 точки (1980 ₽)</p>
                    </div>
                </div>
                <div className={classNames(styles.infoBlock, styles.half)}>
                    <div className={styles.name}>
                        <img className={styles.tariffBlockImage} src={internet}/>
                        <p className={styles.tariffBlock}>Интернет</p>
                    </div>
                    <div className={styles.value}>
                        <p>Нет</p>
                    </div>
                </div>
                <div className={classNames(styles.infoBlock, styles.half)}>
                    <div className={styles.name}>
                        <img className={styles.tariffBlockImage} src={music}/>
                        <p className={styles.tariffBlock}>Музыкальные пакеты</p>
                    </div>
                    <div className={styles.value}>
                        <p>5 (2 475 ₽)</p>
                    </div>
                </div>
                <div className={classNames(styles.infoBlock, styles.half)}>
                    <div className={styles.name}>
                        <img className={styles.tariffBlockImage} src={RaoVois}/>
                        <p className={styles.tariffBlock}>РАО + ВОИС</p>
                    </div>
                    <div className={styles.value}>
                        <p>Да (10 000 ₽)</p>
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div className={styles.name}>
                        <div className={styles.documentBlock}>
                            <div className={styles.documentLoad}>
                                <img className={styles.tariffBlockImage} src={documentLoad}/>
                                <div>
                                    <p>Документ описание тарифа можно скачать 1.pdf</p>
                                    <p>Документ описание тарифа можно скачать 2.pdf</p>
                                </div>
                            </div>
                            <div className={styles.payment}><p>Ежемесячный платёж 17445 ₽</p></div>
                        </div>
                    </div>
                    <div className={styles.value}>
                    </div>
                </div>
            </ObjectSceneDropdown>
            <Link to={'/radio/object/maintenance'} className={styles.maintenanceLink}>
                <ObjectSceneDropdown buttonContent={() => <>
                    <div className={styles.maintenanceIcon}/>
                    Техническое обслуживание</>}/>
            </Link>
        </div>
    );
};

export default ObjectSceneInfo;
