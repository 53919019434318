import React from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

const Modal = ({ data, onClose }) => {
    const onSubmit = () => {
        data.onSubmit();
        onClose()
    };

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                {data.title}
            </div>
            <div className={styles.text}>
                {data.text}
            </div>
            <div className={styles.buttonsContainer}>
                {data.cancelText && <div className={styles.button} onClick={onClose}>
                    {data.cancelText}
                </div>}
                <div className={styles.button} onClick={onSubmit}>
                    {data.submitText}
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    data: PropTypes.shape({
        visible: PropTypes.bool,
        onSubmit: PropTypes.func,
        title: PropTypes.string,
        text: PropTypes.string,
        submitText: PropTypes.string,
        cancelText: PropTypes.string,
    }),
    onClose: PropTypes.func
};

export default Modal;
