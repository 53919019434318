import React, { useState, useEffect } from 'react';
import styles from './ScrollButton.module.scss';
import classNames from 'class-names';

const ScrollButton = () => {
    const [visible, setVisible] = useState(false);

    const scrollListener = () => {
        setVisible(window.pageYOffset > 150);
    };

    useEffect(() => {
        document.addEventListener('scroll', scrollListener);
        return () => document.removeEventListener('scroll', scrollListener);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div onClick={scrollToTop}
             className={classNames(styles.button, { [styles.visible]: visible })}/>
    );
};

export default ScrollButton;
