import {
    SET_BACKGROUND_PLAYLISTS, SET_BACKGROUND_RAO,
    SET_CURRENT_PLAYLIST, SET_OBJECT_PLAYLISTS, SET_PURCHASED_PLAYLISTS, SET_TIMELINE_PLAYLISTS,
} from './background-actions';

const backgroundInitialState = {
    backgroundPlaylists: null,
    backgroundRAO: null,
    playlist: null,
    timelinePlaylists: null,
    objectPlaylists: null,
    purchasedPlaylists: null,
};

export const backgroundReducer = (
    state = backgroundInitialState,
    action,
) => {
    switch (action.type) {
        case SET_BACKGROUND_PLAYLISTS:
            return {
                ...state,
                backgroundPlaylists: action.payload.backgroundPlaylists,
            };

        case SET_BACKGROUND_RAO:
            return {
                ...state,
                backgroundRAO: action.payload.backgroundRAO,
            };

        case SET_CURRENT_PLAYLIST:
            return {
                ...state,
                playlist: action.payload.playlist,
            };

        case SET_OBJECT_PLAYLISTS:
            return {
                ...state,
                objectPlaylists: action.payload.objectPlaylists,
            };

        case SET_TIMELINE_PLAYLISTS:
            return {
                ...state,
                timelinePlaylists: action.payload.timelinePlaylists,
            };

        case SET_PURCHASED_PLAYLISTS:
            return {
                ...state,
                purchasedPlaylists: action.payload.purchasedPlaylists,
            };

        default:
            return state;
    }
};
