import React from 'react';
import GenericCard from "../GenericCard";
import styles from './MaintenanceCard.module.scss';
import classNames from 'class-names';

const MaintenanceCard = ({ className, id, item }) => {
    const getDays = () => Math.round(Number(item?.timer.slice(0, 2)) / 24);

    return (
        <GenericCard className={classNames(styles.card, className)}
                     headClassName={styles.head}
                     headContent={
                         <>
                             <div className={styles.icon}/>
                             ID-{item.id} • {item.status}
                         </>}>
            <div className={styles.infoContainer}>
                <div className={styles.infoBlock}>
                    <div className={styles.infoTitle}>
                        Причина:
                    </div>
                    <div className={styles.infoValue}>
                        {item?.reason?.name}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div className={styles.infoTitle}>
                        Дата создания:
                    </div>
                    <div className={styles.infoValue}>
                        {item?.dateCreated}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div className={styles.infoTitle}>
                        Дата закрытия:
                    </div>
                    <div className={styles.infoValue}>
                        {item?.dateClosed}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div className={styles.infoTitle}>
                        Время в работе:
                    </div>
                    <div className={styles.infoValue}>
                        {getDays()} д
                    </div>
                </div>
            </div>
        </GenericCard>
    );
};

export default MaintenanceCard;
