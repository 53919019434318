import React from 'react';
import styles from './BroadcastPauseCard.module.scss';
import GenericCard from "../GenericCard";
import moment from "moment";

const BroadcastPauseCard = ({id, momentFrom, momentTo, title, onDelete}) => {
    return (
        <GenericCard className={styles.card}
                     headClassName={styles.head}
                     headContent={<>
                         <div className={styles.icon}/>
                         ID {id}</>}>
            <div className={styles.dataContainer}>
                <div className={styles.infoContainer}>
                    <div className={styles.infoBlock}>
                        <div className={styles.infoTitle}>
                            Название:
                        </div>
                        <div className={styles.infoValue}>
                            {title}
                        </div>
                    </div>
                    <div className={styles.infoBlock}>
                        <div className={styles.infoTitle}>
                            Приостановка с:
                        </div>
                        <div className={styles.infoValue}>
                            {moment(momentFrom).format('DD-MM-YYYY HH:mm')}
                        </div>
                    </div>
                    <div className={styles.infoBlock}>
                        <div className={styles.infoTitle}>
                            Приостановка по:
                        </div>
                        <div className={styles.infoValue}>
                            {moment(momentTo).format('DD-MM-YYYY HH:mm')}
                        </div>
                    </div>
                </div>
                <div className={styles.deleteButton} onClick={() => onDelete(id)}/>
            </div>
        </GenericCard>
    );
};

export default BroadcastPauseCard;
