// TODO: перенести в модуль авторизации
export const SET_AUTHORIZED = "SET_AUTHORIZED";
export const SET_TOKENS = "SET_TOKENS";
export const RESET_TOKENS = "RESET_TOKENS";

export const SET_STUDIO_RECORD_DATA = "SET_STUDIO_RECORD_DATA";

export const UI_SET_CURRENT_SECTION = "UI_SET_CURRENT_SECTION";

export const UI_OPEN_MODAL = "UI_OPEN_MODAL";
export const UI_HIDE_MODAL = "UI_HIDE_MODAL";
