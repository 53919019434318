import React from 'react';
import styles from './MediaplanObjectsScene.module.scss';
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import mediaplanIcon from "../../../../img/icons/mediaplan-icon-grey.svg";
import ItemListTitle from "../../../../components/ItemListTitle";
import Search from "../../../../components/Search";
import MediaplanListActions from "../../../../components/Search/actions/MediaplanListActions";
import CheckboxWithLabel from "../../../../components/common/Checkbox/CheckboxWithLabel";
import MediaplanObjectCard from "../../../../components/Cards/MediaplanObjectCard";
import CardCheckbox from "../../../../components/Cards/CardCheckbox";
import ListContainer from "../../../../components/ListContainer";
import { useRadioMediaplan } from "../../../../hooks/radio/useRadioMediaplan";
import { useSelectableList } from "../../../../hooks/useSelectableList";
import {editRadioMediaplan} from "../../../../redux/modules/radio/radio-actions";
import {useDispatch} from "react-redux";
import {useDebouncedCallback} from "../../../../hooks/useDebouncedCallback";

const generateBreadcrumbsRoutes = (id) => [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Эфир',
    href: '/radio/broadcast'
}, {
    name: 'Страница медиаплана',
    href: `/radio/mediaplans/${id}`
}, {
    name: 'Объекты медиаплана',
    href: `/radio/mediaplans/${id}/objects`
}];

const MediaplanObjectsScene = ({
   match,
}) => {
    useSetCurrentSection(PAGE_SUBSECTIONS.BROADCAST);
    const { id } = match.params;
    const [entityData, isLoaded] = useRadioMediaplan(id);
    const dispatch = useDispatch();

    const [onToggle, toggleAll, selectedIds] = useSelectableList(0, true);

    const mediaplanInfo = entityData && entityData.data;

    const objects = mediaplanInfo && mediaplanInfo.objects || [];

    const onDelete = (id) => {
        if (typeof id === 'number') {
            const updateObjects = objects?.filter(item => item?.id !== id);
            dispatch(editRadioMediaplan({ objects: updateObjects }, mediaplanInfo?.id))
        } else {
            let updateObjects = {};
            selectedIds.forEach((value) => {
                updateObjects = objects.filter(item => item?.id !== value);
            });
            dispatch(editRadioMediaplan({ objects: updateObjects }, mediaplanInfo?.id))
        }
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={generateBreadcrumbsRoutes(id)}/>
            {isLoaded && <>
                <ItemListTitle icon={mediaplanIcon}
                               title={mediaplanInfo.title}>
                    ID {mediaplanInfo.id}
                </ItemListTitle>
                <div className={styles.controlsContainer}>
                    <Search showFilter={false}
                            showSearch={false}
                            containerClassName={styles.searchContainer}
                            actions={
                                <MediaplanListActions
                                    addHref={'/radio/mediaplan/objects/add'}
                                    onDelete={onDelete} />}
                    />
                </div>
                <CheckboxWithLabel onChange={toggleAll}
                                   className={styles.toggleAll}
                                   checked={objects.length && (selectedIds.length === objects.length)}
                                   labelChecked={'Снять'}
                                   labelUnchecked={'Выбрать'}/>
                <ListContainer className={styles.listContainer}>
                    {objects.map((item) =>
                        <MediaplanObjectCard
                            key={item.id}
                            id={item.id}
                            name={item.name}
                            address={item.address}
                            onClick={() => onToggle(item.id)}
                            onDelete={onDelete}
                            checkbox={
                                <CardCheckbox
                                    onChange={() => onToggle(item.id)}
                                    checked={selectedIds.includes(item.id)}/>
                            }
                        />
                    )}
                </ListContainer>
            </>}
        </div>
    );
};

export default MediaplanObjectsScene;
