import React from 'react';
import styles from './ObjectsListScene.module.scss';
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import Search from "../../../../components/Search";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ListContainer from "../../../../components/ListContainer";
import ObjectCard from "../../../../components/Cards/RadioObjectCard";
import Pagination from "../../../../components/Pagination";
import {Link, useHistory} from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import { useRadioObjects } from "../../../../hooks/radio/useRadioObjects";
import {setSelectedObject} from "../../../../redux/modules/profile/profile-actions";
import {useDispatch} from "react-redux";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Объекты',
    href: '/radio/objects'
}];


const ObjectsListScene = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.OBJECTS);
    const dispatch = useDispatch();
    const history = useHistory();

    const [data, isLoaded, setPage, setQuery] = useRadioObjects();

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.controlsContainer}>
                <Search
                    showFilter={false}
                    onChange={({target}) => setQuery(target.value)}
                    actions={
                        <React.Fragment>
                            <Link to='/radio/object/create' className={styles.createButton}>
                                Заказать объект
                            </Link>
                            <div className={styles.countContainer}>
                                Количество объектов:
                                <span className={styles.count}>
                                    {data && data.totalEntities || '-'}
                                </span>
                            </div>
                        </React.Fragment>
                    }
                    controlsClassName={styles.searchControls}
                    containerClassName={styles.searchContainer}
                    searchClassName={styles.searchInput}
                />
            </div>

            {isLoaded ? (
                <ListContainer noLoader className={styles.listContainer}>
                    {data.list.map(item => (
                        <ObjectCard
                            data={item}
                            key={item.id}
                            onClick={() => {
                                dispatch(setSelectedObject([item]));
                                history.push(`/radio/objects/${item.id}`);
                            }}
                        />
                    ))}
                </ListContainer>
            ) : (
                <div className={styles.spinnerContainer}>
                    <Spinner/>
                </div>
            )}
            {isLoaded && <Pagination
                entityName={'Объектов'}
                totalPages={data.totalPages}
                count={data.totalEntities}
                resultsPerPage={data.pageSize}
                currentPage={data.pageNumber}
                setPage={setPage}
            />}
        </div>
    );
};

export default ObjectsListScene;
