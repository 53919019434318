import React, {useEffect, useState} from 'react';
import styles from './BackgroundRAO.module.scss';
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../constants/pageTypes";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Search from "../../../components/Search/BackgroundSearch/index"
import PlaylistItem from "../../../components/BackgroundPlaylistItem/index";
import { Link } from "react-router-dom";
import { PLAYLISTS } from "../../../constants/playlists";
import Button from '../../../components/common/Button';
import {useDispatch, useSelector} from "react-redux";
import {backgroundPlaylists, backgroundRAO} from "../../../redux/modules/background/background-selectors";
import {loadBackgroundPlaylists} from "../../../redux/modules/background/background-actions";
import Spinner from "../../../components/Spinner";
import {useDebouncedCallback} from "../../../hooks/useDebouncedCallback";
import {useRadioObjects} from "../../../hooks/radio/useRadioObjects";

const breadcrumbsRoutes = [
    {
        name: 'Фон',
        href: '/background'
    },
    {
        name: 'РАО',
        href: '/background/RAO'
    }
];

const options = {
    type: 'RAO',
}

const BackgroundRAO = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.RAO);
    const [objects] = useRadioObjects();
    const dispatch = useDispatch();
    const data = useSelector(backgroundRAO);

    const [search, setSearch] = useState('');

    useEffect(() => {
        const options = {
            type: 'RAO',
            title: search,
        };
        dispatch(loadBackgroundPlaylists(options));
    }, [dispatch, search]);

    const setQuery = useDebouncedCallback((query) => {
        setSearch(query);
    }, 500);

    const setOptions = (options) => {
        dispatch(loadBackgroundPlaylists(options));
    }

    const setFilters = (filter) => {
        if (filter) {
            dispatch(loadBackgroundPlaylists({type: 'NON_RAO'}))
        } else {
            dispatch(loadBackgroundPlaylists())
        }
    }

    if (!data) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes} className={styles.breadcrumbs}/>
            {/*<div className={styles.searchContainer}>*/}
            {/*    <Search containerClassName={styles.searchBackground}*/}
            {/*            onChange={({target}) => setQuery(target.value)}*/}
            {/*            setOptions={setOptions}*/}
            {/*            setFilters={setFilters} />*/}
            {/*    <Button className={styles.link} href={`/background/playlist/${PLAYLISTS.id}/order`}>*/}
            {/*        <p>Заказать плейлист</p>*/}
            {/*    </Button>*/}
            {/*</div>*/}
            <div className={styles.playlists}>
                {data.items.map(playlist => (
                    <Link className={styles.playlistLink}
                          to={{
                              pathname: `/background/playlist/${playlist.id}`,
                              state: { RAO: true }
                          }}>
                        <PlaylistItem
                            title={playlist.title}
                            image={playlist.miniatureImage}
                            objectsCount={playlist.objectsCount}
                            totalObjectsCount={objects?.totalEntities}
                            imagePlaylistPlay={playlist.imagePlaylistPlay}
                            isActive={true} />
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default BackgroundRAO;
