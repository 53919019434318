import React from 'react';
import GenericCard from "../GenericCard";
import styles from './ObjectPriorityCard.module.scss'
import { getOptionalValue } from "../../../utils/getOptionalValue";

const ObjectPriorityCard = ({data}) => {
    return (
        <GenericCard headClassName={styles.head} className={styles.card}>
            <div className={styles.playButton}/>
            <div className={styles.text}>
                {getOptionalValue(data, 'title')}
            </div>
            {data.favorite && (<div className={styles.star}/>)}
        </GenericCard>
    );
};

export default ObjectPriorityCard;
