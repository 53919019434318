import React from 'react';
import { fetchStudioAudio } from "../../api/studio-api";
import styles from './AudioPlayer.module.scss';
import Spinner from "../Spinner";
import ReactPlayer from 'react-h5-audio-player';
import { ReactComponent as PlayIcon } from '../../img/icons/play-icon-active.svg';
import { ReactComponent as PauseIcon } from '../../img/icons/player-pause-icon.svg';
import { useDispatch, useSelector } from "react-redux";
import { playerSelector } from "../../redux/modules/player/player-selectors";
import { setCurrentPlayer } from "../../redux/modules/player/player-actions";

const AudioPlayer = ({
     trackId,
     entityName,
     setAudioUrl,
 }) => {
    const playerRef = React.useRef(null);
    const currentPlayer = useSelector(playerSelector);
    const dispatch = useDispatch();

    const [audioSrc, setAudioSrc] = React.useState(null);
    const [isLoading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        const fetchAudio = async () => {
            try {
                const {data: audio} = await fetchStudioAudio(trackId, entityName);

                const audioObjectUrl = URL.createObjectURL(new Blob([audio], {type: 'audio/mpeg'}));
                setAudioSrc(audioObjectUrl);
                setAudioUrl(audioObjectUrl);
            } catch (e) {
                if (e.response && e.response.data) {
                    setError(e.response.data.errorMessage);
                } else {
                    console.error(e);
                }
            }
            setLoading(false);
        }

        fetchAudio();
    }, [trackId, entityName, setAudioUrl]);

    const onPlay = () => {
        dispatch(setCurrentPlayer(audioSrc));
    };

    React.useEffect(() => {
        if (currentPlayer && (audioSrc !== currentPlayer)) {
            playerRef.current && playerRef.current.audio.current.pause();
        }
    }, [audioSrc, currentPlayer])

    if (isLoading) {
        return (
            <div className={styles.playerLoading}>
                <Spinner/>
            </div>
        )
    }

    return (
        <div className={styles.playerContainer}>
            {error ?
                (
                    <div className={styles.error}>
                        {error}
                    </div>
                ) : (
                    <ReactPlayer
                        ref={playerRef}
                        showSkipControls={false}
                        showJumpControls={false}
                        autoPlayAfterSrcChange={false}
                        customVolumeControls={[]}
                        customAdditionalControls={[]}
                        customIcons={{
                            play: <PlayIcon />,
                            pause: <PauseIcon />
                        }}
                        layout="horizontal-reverse"
                        src={audioSrc}
                        onPlay={onPlay}
                    />
                )}
        </div>
    );
};

export default AudioPlayer;
