import { createSelector } from 'reselect';

const backgroundSelector = (state) => state.background;

export const backgroundPlaylists = createSelector(
    backgroundSelector,
    state => state.backgroundPlaylists,
);

export const backgroundRAO = createSelector(
    backgroundSelector,
    state => state.backgroundRAO,
);

export const playlist = createSelector(
    backgroundSelector,
    state => state.playlist,
);

export const timelinePlaylists = createSelector(
    backgroundSelector,
    state => state.timelinePlaylists,
);

export const purchasedPlaylists = createSelector(
    backgroundSelector,
    state => state.purchasedPlaylists,
);

export const objectPlaylists = createSelector(
    backgroundSelector,
    state => state.objectPlaylists,
);
