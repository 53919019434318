import { useDispatch, useSelector } from "react-redux";
import { radioDataSelector, radioParamsSelector } from "../../redux/modules/radio/radio-selectors";
import { loadRadioData, setRadioParams } from "../../redux/modules/radio/radio-actions";
import { RADIO_ENTITY } from "../../constants/radioEntities";
import { useRadioMetadata } from "./useRadioMetadata";
import React, {useCallback} from "react";
import { isRadioPageLoaded } from "../../redux/modules/radio/radio-helpers";
import { useDebouncedCallback } from "../useDebouncedCallback";

export const useRadioObjects = () => {
    const dispatch = useDispatch();
    const data = useSelector(radioDataSelector);
    const params = useSelector(radioParamsSelector);

    const loadObjectsList = useCallback(() => {
        dispatch(loadRadioData(RADIO_ENTITY.OBJECT_LIST))
    }, [dispatch]);

    const setParams = (params) => dispatch(setRadioParams(params));
    useRadioMetadata();

    React.useEffect(() => {
        loadObjectsList();
    }, [loadObjectsList]);

    const setPage = (page) => {
        setParams({
            ...params,
            _page: page,
        });

        loadObjectsList();
    };

    const setAddPageSize = (pageSize) => {
        setParams({
            ...params,
            _pageSize: pageSize,
            _page: 0,
        });

        loadObjectsList();
    };

    const setQuery = useDebouncedCallback((query) => {
        setParams({
            ...params,
            _search: query,
        });

        loadObjectsList();
    }, 500);

    const isLoaded = isRadioPageLoaded(RADIO_ENTITY.OBJECT_LIST, data);

    return [
        data,
        isLoaded,
        setPage,
        setQuery,
        setAddPageSize
    ];
};
