import React from 'react';
import Button from "../../common/Button";
import styles from './ProfileButton.module.scss';
import classNames from 'class-names';

const ProfileButton = ({children, primary, fullWidth, ...props}) => {
    return (
        <Button {...props} className={classNames(styles.profileButton, primary && styles.primary, fullWidth && styles.fullWidth)}>
            {children}
        </Button>
    );
};

export default ProfileButton;
