import React from 'react';
import styles from './ObjectCard.module.scss';
import GenericCard from "../GenericCard";
import classNames from 'class-names';

const RadioObjectCard = ({ data, responsive = true, onClick, checkbox }) => {
    const {id, online, title, city, address, status, transaction} = data;

    return (
        <GenericCard className={classNames(styles.card, responsive && styles.responsive)}
                     href={onClick ? undefined : `/radio/objects/${id}`}
                     onClick={onClick}
                     headClassName={online ? styles.headOnline : styles.headOffline}
                     headContent={<>
                         <div className={styles.icon}/>
                         ID {id} • {status.name}
                         {online && <>
                             <div className={styles.onlineIcon}/>
                             ONLINE
                         </>}
                         {checkbox}
                     </>}>
            <div className={styles.contentContainer}>
                <div className={styles.title}>
                    {title}
                </div>
                <div className={styles.infoBlockPair}>
                    <div className={styles.infoBlock}>
                        <div className={styles.infoTitle}>
                            Город:
                        </div>
                        <div className={styles.infoValue}>
                            {city}
                        </div>
                    </div>
                    {transaction && <div className={styles.infoBlock}>
                        <div className={styles.infoTitle}>
                            Транзакция:
                        </div>
                        <div className={styles.infoValue}>
                            {transaction}
                        </div>
                    </div>}
                </div>
                <div className={styles.infoBlock}>
                    <div className={styles.infoTitle}>
                        Адрес:
                    </div>
                    <div className={styles.infoValue}>
                        {address}
                    </div>
                </div>
            </div>
        </GenericCard>
    );
};

export default RadioObjectCard;
