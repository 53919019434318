import React from 'react';
import styles from './RecordsListScene.module.scss';
import { connect } from "react-redux";
import Pagination from "../../../components/Pagination";
import Search from "../../../components/StudioSearch";
import recordIcon from "../../../img/icons/record-icon.svg";
import { PAGE_SUBSECTIONS, PAGE_TYPE } from "../../../constants/pageTypes";
import { useSetCurrentSection } from "../../../hooks/useSetCurrentSection";
import RecordActions from "../../../components/Search/actions/RecordActions";
import CardCheckbox from "../../../components/Cards/CardCheckbox";
import { showModal } from "../../../redux/actions/ui/modal/modal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ListContainer from "../../../components/ListContainer";
import { useStudioMetadata } from "../../../hooks/useStudioMetadata";
import { useSelectableList } from "../../../hooks/useSelectableList";
import { createStudioDataSelector } from "../../../redux/modules/studio/studio-selectors";
import { STUDIO_ENTITY } from "../../../constants/studioEntities";
import { addTracksToArchive, loadStudioData, orderNewTrack } from "../../../redux/modules/studio/studio-actions";
import Spinner from "../../../components/Spinner";
import StudioRecordCard from "../../../components/Cards/StudioRecordCard";

const mapStateToProps = (state) => ({
    data: createStudioDataSelector(STUDIO_ENTITY.RECORDS)(state)
});

const mapDispatchToProps = dispatch => ({
    loadData: (page) => dispatch(loadStudioData(STUDIO_ENTITY.RECORDS, page)),
    addToArchive: (ids) => dispatch(addTracksToArchive(ids, STUDIO_ENTITY.RECORDS)),
    orderTrack: () => dispatch(orderNewTrack()),
    showModal: (data) => dispatch(showModal(data))
});

const breadcrumbsRoutes = [{
    name: 'Студия звукозаписи',
    href: '#'
}, {
    name: 'Ролики',
    href: '/studio/records'
}];

const RecordsListScene = ({ data, loadData, showModal, addToArchive, orderTrack }) => {
    useSetCurrentSection(PAGE_SUBSECTIONS.RECORDS);
    const [metadataLoaded] = useStudioMetadata();
    const [onToggle, selectAll, selectedIds, unselectAll, setSelectedIds] = useSelectableList();

    React.useEffect(() => {
        loadData();
    }, [loadData]);

    const setPage = (page) => {
        loadData(page);
    };

    const addSelectedToArchive = () => {
        addToArchive(selectedIds);
        unselectAll();
    };

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.recordControlsContainer}>
                {metadataLoaded && <Search
                    entity={STUDIO_ENTITY.RECORDS}
                    actions={<RecordActions
                        orderTrack={orderTrack}
                        onArchive={addSelectedToArchive}
                        pageType={PAGE_TYPE.RECORDS}
                    />}/>}
            </div>
            {data.isLoading ? (
                    <div className={styles.spinnerContainer}>
                        <Spinner/>
                    </div>
                )
                : (
                    <ListContainer noLoader className={styles.listContainer}>
                        {data.list.map((record, index) => (
                            <StudioRecordCard
                                record={record}
                                checkbox={<CardCheckbox
                                    onChange={() => onToggle(record.id)}
                                    checked={selectedIds.includes(record.id)}/>}
                                showStatus={true}
                                entity={STUDIO_ENTITY.RECORDS}
                                icon={recordIcon}
                                key={index}
                            />))}
                    </ListContainer>
                )}
            {!data.isLoading && <Pagination
                entityName={'Роликов'}
                selectable
                totalPages={data.totalPages}
                count={data.totalRecords}
                resultsPerPage={data.pageSize}
                currentPage={data.pageNumber}
                setPage={setPage}
                setSelected={setSelectedIds}
                selectedIds={selectedIds}
                items={data.list}
            />}
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordsListScene);
