import { createSelector } from 'reselect';

const profileSelector = (state) => state.profile;

export const profileSelectorData = createSelector(
    profileSelector,
    ({ profileData }) => profileData,
);

export const profileSelectorDocuments = createSelector(
    profileSelector,
    state => state.profileDocuments,
);

export const profileSelectorIdeas = createSelector(
    profileSelector,
    state => state.profileIdeas,
);

export const profileSelectorMail = createSelector(
    profileSelector,
    state => state.mail,
);

export const profileSelectorChangePassword = createSelector(
    profileSelector,
    state => state.changePassword,
);

export const profileSelectorBills = createSelector(
    profileSelector,
    state => state.bills,
);

export const profileSelectorMaintenanceReasons = createSelector(
    profileSelector,
    state => state.maintenanceReasons,
);

export const profileSelectorMaintenanceStatuses = createSelector(
    profileSelector,
    state => state.maintenanceStatuses,
);

export const profileSelectorMaintenances = createSelector(
    profileSelector,
    state => state.maintenances,
);

export const profileSelectedObject = createSelector(
    profileSelector,
    state => state.selectedObject,
);
