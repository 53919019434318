import React, { useState } from 'react';
import styles from './MediaplanAddRecordScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Search from "../../../../components/Search";
import CheckboxWithLabel from "../../../../components/common/Checkbox/CheckboxWithLabel";
import RecordCard from "../../../../components/Cards/RecordCard";
import CardCheckbox from "../../../../components/Cards/CardCheckbox";
import classNames from "class-names";
import Button from "../../../../components/common/Button";
import Pagination from "../../../../components/Pagination";
import ListContainer from "../../../../components/ListContainer";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Эфир',
    href: '/radio/broadcast'
}, {
    name: 'Страница медиаплана',
    href: '/radio/mediaplan'
}, {
    name: 'Добавление роликов',
    href: '/radio/mediaplan/records/add'
}];

const testObjectIds = [1, 5, 12, 14, 99];

const MediaplanAddRecordScene = () => {
    const [selectedIds, setSelectedIds] = useState([]);

    const onToggle = (id) => selectedIds.includes(id) ?
        setSelectedIds(selectedIds.filter(item => item !== id)) :
        setSelectedIds([...selectedIds, id]);

    const toggleAll = (e) => {
        e.preventDefault();
        return setSelectedIds(selectedIds.length ? [] : testObjectIds);
    };

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.searchContainer}>
                <Search containerClassName={styles.search}
                        actions={
                            <div className={classNames(styles.buttonsContainer, styles.hideMobile)}>
                                <Button href={'/radio/mediaplan'}>
                                    Отменить
                                </Button>
                                <Button href={'/radio/mediaplan/records'}>
                                    Выбрать ролики
                                </Button>
                            </div>
                        }
                        showFilter={true}/>
            </div>
            <div className={classNames(styles.buttonsContainer, styles.hideDesktop)}>
                <Button href={'/radio/mediaplan'}>
                    Отменить
                </Button>
                <Button href={'/radio/mediaplan/records'}>
                    Выбрать
                </Button>
            </div>
            <CheckboxWithLabel onChange={toggleAll}
                               checked={selectedIds.length === testObjectIds.length}
                               labelChecked={'Снять'}
                               labelUnchecked={'Выбрать'}/>
            <ListContainer className={styles.listContainer}>
                {testObjectIds.map((item, index) =>
                    <RecordCard key={index}
                                checkbox={<CardCheckbox
                                    onChange={() => onToggle(item)}
                                    checked={selectedIds.includes(item)}/>}
                                showStatus/>
                )}
            </ListContainer>
            <Pagination count={12}
                        entityName={'Роликов'}
                        resultsPerPage={6}
                        currentPage={1}
                        setPage={() => {
                        }}
                        items={[]}/>
        </div>
    );
};

export default MediaplanAddRecordScene;
