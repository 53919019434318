import React, { useState, useEffect } from 'react';
import styles from './ObjectScenePlayer.module.scss';
import classNames from 'class-names';
import { Link } from "react-router-dom";

const ObjectScenePlayer = ({ id, isMuted, isRecording, setRecording }) => {
    const [volume, setVolume] = useState(75);
    const [isPaused, setPaused] = useState(false);

    useEffect(() => {
        isRecording && setPaused(true);
    }, [isRecording, setPaused]);

    useEffect(() => {
        !isPaused && setRecording(false);
    }, [isPaused, setRecording]);

    const visualiserAnimated = !isPaused && !isMuted && volume > 0 && !isRecording;
    const visualiserMuted = isMuted || isRecording;

    return (
        <div className={styles.container}>
            <div
                className={classNames(styles.visualiser,
                    {
                        [styles.visualiserAnimated]: visualiserAnimated,
                        [styles.visualiserMuted]: visualiserMuted
                    })}>
                <div className={styles.innerCircle} style={{ height: `${volume}%`, width: `${volume}%` }}>
                    <div className={styles.innerCircle}>
                        {isRecording ? <div className={styles.microphoneIcon}/> : <div className={classNames(styles.innerCircle, styles.centerCircle)}/>}
                    </div>
                </div>
                {!isRecording &&
                <div className={styles.volumeContainer}>
                    {isMuted ? 0 : volume}
                </div>}
            </div>
            <div className={styles.titleContainer}>
                <Link to={'/radio/object/broadcast-management'} className={styles.settingsIcon}/>
                <Link to={`/radio/object/background/${id}`} className={styles.backgroundIcon}/>
                <Link to={`/radio/objects/${id}/priority`} className={styles.lightningIcon}/>
                <div className={classNames(styles.titleText, { [styles.scrollingTitle]: !isPaused })}>
                    <span>День Святого Валентина</span>
                </div>
            </div>
            <div className={styles.playerControls}>
                <div className={styles.minusButton}
                     onClick={() => !isMuted && setVolume(volume > 0 ? volume - 1 : volume)}>
                    -
                </div>
                <div className={classNames(styles.playButton, { [styles.paused]: isPaused })}
                     onClick={() => setPaused(!isPaused)}
                />
                <div className={styles.plusButton}
                     onClick={() => !isMuted && setVolume(volume < 100 ? volume + 1 : volume)}>
                    +
                </div>
            </div>
        </div>
    );
};

export default ObjectScenePlayer;
