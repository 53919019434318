import React, { useState } from 'react';
import styles from './RestorePasswordScene.module.scss';
import { DEFAULT_ROUTE } from "../../constants/routing";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AuthInput from "../../components/common/AuthInput";
import Modal from "../../components/AuthModal";
import AuthButton from "../../components/common/AuthButton";
import classNames from 'class-names';

const mapStateToProps = ({ authData }) => ({
    authorized: authData.authorized
});

const RestorePasswordScene = ({ authorized }) => {
    const [currentStage, setCurrentStage] = useState('email');

    if (authorized) {
        return <Redirect to={DEFAULT_ROUTE}/>
    }

    let form;

    switch (currentStage) {
        case 'email':
            form = (<>
                <div className={"auth-modal__form"}>
                    <AuthInput placeholder={""}
                               hint={'Укажите адрес электронной почты'}/>
                </div>
                <div className={"auth-modal__buttons-container"}>
                    <AuthButton primary onClick={() => setCurrentStage('code')}>
                        Отправить код
                    </AuthButton>
                </div>
            </>);
            break;

        case 'code':
            form = (<>
                <div className={"auth-modal__form"}>
                    <AuthInput placeholder={""}
                               hint={'Введите код, что бы продолжить'}/>
                </div>
                <div className={"auth-modal__buttons-container"}>
                    <AuthButton primary onClick={() => setCurrentStage('newPassword')}>
                        Сбросить пароль
                    </AuthButton>
                </div>
            </>);
            break;

        case 'newPassword':
            form = (<>
                <div className={"auth-modal__form"}>
                    <AuthInput placeholder={"Пароль"}
                               type={'password'}
                               hint={'Введите новый пароль'}/>
                    <AuthInput placeholder={"Повторите пароль"}
                               type={'password'}
                               hint={'Введите новый пароль'}/>
                </div>
                <div className={"auth-modal__buttons-container"}>
                    <Link to={'/login'}>
                        <AuthButton primary>
                            Подтвердить
                        </AuthButton>
                    </Link>
                </div>
            </>);
            break;

        default: break;
    }

    return (
        <>
            <div className={styles.backgroundContainer}/>
            <div className={styles.container}>
                <div className={styles.logo}/>
                <Modal className={styles.modalContainer}>
                    <h1 className={"auth-modal__head"}>
                        Восстановление пароля
                    </h1>
                    <div className={classNames(styles.formWrapper, currentStage === 'newPassword' && styles.noMargin)}>
                        {form}
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default connect(mapStateToProps)(RestorePasswordScene);
