import {api} from "../App";
import {RADIO_URL} from "./radio-api";

const PROFILE_URL = 'http://api.2.dev.yradio.ru/profile/v1';
const PROFILE_DATA = `${PROFILE_URL}/profile`;
const PROFILE_DOCUMENTS = `${PROFILE_URL}/profile/documents`;
const PROFILE_IDEAS = `${PROFILE_URL}/ideas`;
const PROFILE_MAIL = `${PROFILE_URL}/mail-to-manager`;
const PROFILE_CHANGE_PASSWORD = `${PROFILE_URL}/profile/changePassword`;
const PROFILE_BILLS = `${PROFILE_URL}/bills`;
const PROFILE_MAINTENANCE_REASONS = `${RADIO_URL}/maintenance-reasons`;
const PROFILE_MAINTENANCE_STATUSES = `${RADIO_URL}/maintenance-statuses`;
const PROFILE_MAINTENANCES = `${RADIO_URL}/maintenances`;
const PROFILE_CHANGE_EMAIL = `${PROFILE_DATA}/change-email`;
const PROFILE_CHANGE_PHONE = `${PROFILE_DATA}/change-phone`;
const PROFILE_CHANGE_USER = `${PROFILE_DATA}/update-user`;

export const fetchProfileData = async () => {
    return api.get(PROFILE_DATA);
};

export const fetchProfileDocuments = async () => {
    return api.get(PROFILE_DOCUMENTS);
};

export const fetchProfileBills = async (params) => {
    return await api.get(PROFILE_BILLS, { params });
};

export const fetchMaintenanceStatuses = async () => {
    return api.get(PROFILE_MAINTENANCE_STATUSES);
};

export const fetchMaintenanceReasons = async () => {
    return api.get(PROFILE_MAINTENANCE_REASONS);
};

export const fetchMaintenances = async (params) => {
    return api.get(PROFILE_MAINTENANCES, { params });
};

export const postProfileMaintenance = async (form) => {
    return await api.post(PROFILE_MAINTENANCES, form);
};

export const postProfileIdeas = async (form) => {
    return await api.post(PROFILE_IDEAS, form);
};

export const postProfileMail = async (form) => {
    return await api.post(PROFILE_MAIL, form);
};

export const putProfileChangePassword = async (form) => {
    return await api.put(PROFILE_CHANGE_PASSWORD, form);
};

export const putProfileChangeEmail = async (form) => {
    return await api.put(PROFILE_CHANGE_EMAIL, form);
};

export const putProfileChangePhone = async (form) => {
    return await api.put(PROFILE_CHANGE_PHONE, form);
};

export const putProfileChangeUser = async (form) => {
    return await api.put(PROFILE_CHANGE_USER, form);
};
