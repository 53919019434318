import React from 'react';
import styles from './MediaplanRecordTimeScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import recordIcon from "../../../../img/icons/record-icon.svg";
import ItemListTitle from "../../../../components/ItemListTitle";
import RecordStatus from "../../../../components/Cards/RecordCard/RecordStatus";
import { RECORD_ONLINE_STATUS, RECORD_STATUS } from "../../../../constants/recordTypes";
import Input from "../../../../components/common/Input";
import RecordDateCard from "../../../../components/Cards/RecordDateCard";
import Button from "../../../../components/common/Button";
import ListContainer from "../../../../components/ListContainer";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Эфир',
    href: '/radio/broadcast'
}, {
    name: 'Страница медиаплана',
    href: '/radio/mediaplan'
}, {
    name: 'Ролики',
    href: '/radio/mediaplan/records'
}, {
    name: 'Выбор времени вещания',
    href: '/radio/mediaplan/record/time'
}];

const MediaplanRecordTimeScene = () => {
    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <ItemListTitle icon={recordIcon}
                           title={"Пакет Праздничный День Святого Валентина Грааль"}>
                ID 51464 <RecordStatus onlineStatus={RECORD_ONLINE_STATUS.ONLINE}
                                       status={RECORD_STATUS.READY}/>
            </ItemListTitle>
            <div className={styles.formContainer}>
                <div className={styles.formInputs}>
                    <Input className={styles.input}
                           name={'startDate'}
                           label={'Дата начала выхода'}
                           type={'date'}/>
                    <Input className={styles.input}
                           name={'endDate'}
                           label={'Дата окончания выхода'}
                           type={'date'}/>
                </div>
                <Button className={styles.addButton}>
                    Добавить период выхода
                </Button>
            </div>
            <ListContainer autoHeight className={styles.listContainer}>
                <RecordDateCard startDate={'27.10.2019'}
                                endDate={'28.10.2019'}
                                id={'3214124'}/>
                <RecordDateCard startDate={'27.10.2019'}
                                endDate={'28.10.2019'}
                                id={'3214124'}/>
                <RecordDateCard startDate={'27.10.2019'}
                                endDate={'28.10.2019'}
                                id={'3214124'}/>
            </ListContainer>
            <div className={styles.buttonContainer}>
                <Button>
                    Применить
                </Button>
            </div>
        </div>
    );
};

export default MediaplanRecordTimeScene;
