import React from 'react';
import styles from './PriorityListScene.module.scss';
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import PriorityAdCard from "../../../../components/Cards/PriorityAdCard";
import Pagination from "../../../../components/Pagination";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import ListContainer from "../../../../components/ListContainer";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Приоритетные объявления',
    href: '/radio/priority'
}];

const PriorityListScene = () => {
    useSetCurrentSection(PAGE_SUBSECTIONS.PRIORITY);

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.controlsContainer}>
                <Link to={'/radio/priority/create'} className={styles.createButton}>
                    Создать приоритетное объявление
                </Link>
                <div className={styles.countContainer}>
                    Список объявлений
                    <span className={styles.countValue}>
                        6/10
                    </span>
                </div>
            </div>
            <ListContainer className={styles.listContainer}>
                <PriorityAdCard/>
                <PriorityAdCard/>
                <PriorityAdCard/>
                <PriorityAdCard/>
                <PriorityAdCard/>
                <PriorityAdCard/>
            </ListContainer>
            <Pagination count={12}
                        entityName={'Объявлений'}
                        resultsPerPage={6}
                        currentPage={1}
                        setPage={() => {}}
                        items={[]}/>
        </div>
    );
};

export default PriorityListScene;
