import React from 'react';
import styles from './Document.module.scss';

const Document = ({title, link}) => {
    return (
        <div className={styles.container}>
            <div className={styles.documentIcon} />
            <a className={styles.linkContainer}
               href={link}
               rel="noopener noreferrer"
               target="_blank"
               download>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.openIcon}/>
            </a>
        </div>
    );
};

export default Document;
