import React from 'react';
import PropTypes from 'prop-types';
import styles from './AuthButton.module.scss';
import classNames from 'class-names';

const AuthButton = ({primary, children, onClick, ...props}) => {
    return (
        <div onClick={onClick} className={classNames(styles.container, {[styles.primary]: primary})}>
            {children}
        </div>
    );
};

AuthButton.propTypes = {
    primary: PropTypes.bool,
    onClick: PropTypes.func
};

export default AuthButton;
