import React, { useState } from 'react';
import styles from './MediaplanRecordsScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ItemListTitle from "../../../../components/ItemListTitle";
import mediaplanIcon from '../../../../img/icons/mediaplan-icon-grey.svg';
import Search from "../../../../components/Search";
import RecordCard from "../../../../components/Cards/RecordCard";
import {Link, useParams} from "react-router-dom";
import CardCheckbox from "../../../../components/Cards/CardCheckbox";
import CheckboxWithLabel from "../../../../components/common/Checkbox/CheckboxWithLabel";
import MediaplanListActions from "../../../../components/Search/actions/MediaplanListActions";
import Pagination from "../../../../components/Pagination";
import ListContainer from "../../../../components/ListContainer";
import {useRadioMediaplan} from "../../../../hooks/radio/useRadioMediaplan";
import {editRadioMediaplan} from "../../../../redux/modules/radio/radio-actions";
import {useDispatch} from "react-redux";
import {useDebouncedCallback} from "../../../../hooks/useDebouncedCallback";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Эфир',
    href: '/radio/broadcast'
}, {
    name: 'Страница медиаплана',
    href: '/radio/mediaplan'
}, {
    name: 'Ролики медиаплана',
    href: '/radio/mediaplan/records'
}];

const getRecordMenuOptions = (id, onDelete) => (<>
    <Link to={'/radio/mediaplan/record/time'} className="popup-menu-item">
        Выбор даты выхода
    </Link>
    <div className="popup-menu-item" onClick={() => onDelete(id)}>
        Удалить ролик
    </div>
</>);

const getRecordData = () => (
    [
        {
            label: "С",
            value: "12.09.2019"
        },
        {
            label: "По",
            value: "13.09.2019"
        },
    ]
);

const testObjectIds = [1, 5, 12, 14, 99];

const MediaplanRecordsScene = () => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [search, setSearch] = useState('');
    const { id } = useParams();
    const [entityData, isLoaded] = useRadioMediaplan(id);
    const dispatch = useDispatch();

    const mediaplanInfo = entityData && entityData.data;

    const tracks = mediaplanInfo && mediaplanInfo.tracks || [];

    const onToggle = (id) => selectedIds.includes(id) ?
        setSelectedIds(selectedIds.filter(item => item !== id)) :
        setSelectedIds([...selectedIds, id]);

    const toggleAll = (e) => {
        e.preventDefault();
        return setSelectedIds(selectedIds.length ? [] : testObjectIds);
    };

    const onDelete = (id) => {
        const updateTracks = tracks?.filter(item => item?.id !== id);
        dispatch(editRadioMediaplan({ tracks: updateTracks }, mediaplanInfo?.id))
    }

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <ItemListTitle icon={mediaplanIcon}
                           title={"Медиаплан #1, 09.09.2019 - 12.09.2019"}>
                ID 51464
            </ItemListTitle>
            {/*<div className={styles.searchContainer}>*/}
            {/*    <Search containerClassName={styles.search}*/}
            {/*            actions={<MediaplanListActions addHref={'/radio/mediaplan/records/add'}/>}*/}
            {/*            showFilter={false}/>*/}
            {/*</div>*/}
            <CheckboxWithLabel onChange={toggleAll}
                               checked={selectedIds.length === testObjectIds.length}
                               className={styles.toggleAll}
                               labelChecked={'Снять'}
                               labelUnchecked={'Выбрать'}/>
            <ListContainer className={styles.listContainer}>
                {tracks.map((item) =>
                    <RecordCard key={item.id}
                                id={item.id}
                                name={item.name}
                                onClick={() => onToggle(item.id)}
                                onDelete={onDelete}
                                checkbox={<CardCheckbox
                                    onChange={() => onToggle(item)}
                                    checked={selectedIds.includes(item)}/>}
                                getRecordData={getRecordData}
                                getMenuOptions={getRecordMenuOptions}/>
                )}
            </ListContainer>
            <Pagination count={12}
                        entityName={'Объектов'}
                        resultsPerPage={6}
                        currentPage={1}
                        setPage={() => {}}
                        items={[]}/>
        </div>
    );
};

export default MediaplanRecordsScene;
