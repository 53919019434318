export const getOptionalValue = (object, key) => {
    const path = key.split('.');
    let value = object;

    path.forEach(pathItem => {
        if (value && value[pathItem]) {
            value = value[pathItem];
        } else {
            value = null;
        }
    });

    return value === null ? '-' : value;
}
