export const STUDIO_ENTITY = {
    RECORDS: 'tracks',
    ARCHIVE: 'archived-tracks',
    DEMO: 'demo-tracks',
}

export const STUDIO_ENTITY_URL_MAP = {
    [STUDIO_ENTITY.RECORDS]: 'records',
    [STUDIO_ENTITY.ARCHIVE]: 'archive',
    [STUDIO_ENTITY.DEMO]: 'demo',
}

export const STUDIO_URL_ENTITY_MAP = {
    'records': STUDIO_ENTITY.RECORDS,
    'archive': STUDIO_ENTITY.ARCHIVE,
    'demo': STUDIO_ENTITY.DEMO,
}
