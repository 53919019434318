import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'class-names';
import styles from './CalendarRepeatMonthly.module.scss';


const CalendarRepeatMonthly = ({ selectedDate }) => {
    const currentDay = selectedDate.getDate();
    const [monthRepeat, setMonthRepeat] = React.useState(
        'day'
    );

    return (
        <div className={styles.container}>
            <h5 className={styles.title}>Когда повторяется</h5>
            <div className={styles.monthRepeatSelect}>
                <div
                    className={classNames([
                        styles.selectionItem,
                        monthRepeat === 'day' && styles.selectionItemActive,
                    ])}
                    onClick={() => setMonthRepeat('day')}>
                    Ежемесячно ({currentDay} числа)
                </div>
                <div
                    className={classNames([
                        styles.selectionItem,
                        monthRepeat === 'weekday' && styles.selectionItemActive,
                    ])}
                    onClick={() => setMonthRepeat('weekday')}>
                    Каждый 2-й вторник месяца
                </div>
            </div>
        </div>
    );
};

CalendarRepeatMonthly.propTypes = {
    selectedDate: PropTypes.instanceOf(Date)
};

export default CalendarRepeatMonthly;
