import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import classNames from 'class-names';
import { Link } from "react-router-dom";

const Button = ({ children, onClick, className, href, ...props }) => {
    const Component = href ? Link : 'button';
    return (
        <Component to={href ? href : undefined}
                   className={classNames(styles.button, className)}
                   onClick={onClick}
                   {...props}>
            {children}
        </Component>
    );
};

Button.propTypes = {
    onClick: PropTypes.func,
    href: PropTypes.string
};

export default Button;
