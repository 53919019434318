import React, { useEffect, useState } from 'react';
import styles from './RecordScene.module.scss';
import classNames from "class-names";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import RecordInfoSection from "../../components/RecordScene/InfoSection";
import {
    addTracksToArchive,
    loadStudioRecord,
    removeTracksFromArchive
} from "../../redux/modules/studio/studio-actions";
import { studioRecordSelector } from "../../redux/modules/studio/studio-selectors";
import { STUDIO_ENTITY, STUDIO_URL_ENTITY_MAP } from "../../constants/studioEntities";
import Spinner from "../../components/Spinner";
import AudioPlayer from "../../components/AudioPlayer";
import { useSetCurrentSection } from "../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../constants/pageTypes";

const mapStateToProps = (state) => ({
    record: studioRecordSelector(state)
});

const mapDispatchToProps = dispatch => ({
    loadRecord: (entity, id) => dispatch(loadStudioRecord(entity, id)),
    addToArchive: (ids, entity) => dispatch(addTracksToArchive(ids)),
    removeFromArchive: (ids, entity) => dispatch(removeTracksFromArchive(ids)),
});

const RecordScene = ({ match, record, loadRecord, addToArchive, removeFromArchive }) => {
    const { data, isLoading } = record;
    const { entity: entityUrl } = match.params;
    const entity = STUDIO_URL_ENTITY_MAP[entityUrl];
    useSetCurrentSection(PAGE_SUBSECTIONS[entityUrl.toUpperCase()]);

    const [audioUrl, setAudioUrl] = useState(null);

    useEffect(() => {
        const { id } = match.params;

        if (!(record.recordId === id) || !data && !record.isLoading) {
            loadRecord(entity, id);
        }
    }, [loadRecord, match.params]);

    const [infoOpen, setInfoOpen] = useState(false);

    if (!match.params.id)
        return <Redirect to={'/404'}/>;

    const toggleArchive = () => {
        if (record.archived) {
            removeFromArchive([record.recordId]);
        } else {
            addToArchive([record.recordId]);
        }

        loadRecord(entity, record.recordId);
    }

    return (
        <div className={styles.container}>
            <div className={styles.pageContainer}>
                {(isLoading || !data) ? (
                    <div className={styles.spinnerContainer}>
                        <Spinner/>
                    </div>
                ) : (
                    <div className={styles.mainContainer}>
                        <Link to={"/studio/records"} className={styles.backLink}>
                            Вернуться к списку роликов
                        </Link>
                        <div className={styles.statusInfo}>
                            ID {record.recordId} • {data.onAir ? "В ЭФИРЕ" : "НЕ В ЭФИРЕ"}
                        </div>
                        <div className={styles.flexContainer}>
                            <h1 className={styles.title}>
                                {data.title}
                            </h1>
                            <div className={styles.player}>
                                <div className={styles.hintText}>
                                    Прослушать ролик
                                </div>
                                <AudioPlayer
                                    trackId={record.recordId}
                                    entityName={STUDIO_URL_ENTITY_MAP[entityUrl]}
                                    setAudioUrl={setAudioUrl}
                                />
                            </div>
                            <div className={styles.buttonsContainer}>
                                {record.onAir && <div className={classNames(styles.button, styles.buttonGray)}>
                                    Снять с эфира
                                </div>}
                                {entity !== STUDIO_ENTITY.DEMO && !record.archived && !record.onAir && (
                                    <div onClick={toggleArchive} className={styles.button}>
                                        Добавить в архив
                                    </div>
                                )}
                                {record.archived && (
                                    <div onClick={toggleArchive} className={styles.button}>
                                        Убрать из архива
                                    </div>
                                )}
                                {audioUrl && (
                                        <a
                                            href={audioUrl}
                                            target="_blank"
                                            className={styles.button}
                                        >
                                            Скачать
                                        </a>
                                )}
                            </div>
                        </div>
                        <div className={styles.hintText}>
                            Текст ролика
                        </div>
                        <div className={styles.hintText}>
                            {data.description}
                        </div>
                        {record.onAir && <div className={styles.mobileButton}>
                            Снять с эфира
                        </div>}
                        <div
                            className={classNames(styles.mobileButton, styles.mobileButtonLight,
                                { [styles.mobileButtonActive]: infoOpen })}
                            onClick={() => setInfoOpen(!infoOpen)}>
                            Характеристики ролика
                        </div>
                    </div>
                )}
                {!isLoading && data && <RecordInfoSection open={infoOpen} data={record.data}/>}
            </div>
            <div className={styles.createdAt}>
                Создание ролика: 09.09.2019
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordScene);
