import React from 'react';
import styles from './MediaplanCard.module.scss';
import GenericCard from "../GenericCard";

const MediaplanCard = ({ data }) => {
    const {title, dateFrom, dateTo, id, intensity} = data;

    return (
        <GenericCard className={styles.card}
                     headClassName={styles.head}
                     href={`/radio/mediaplans/${id}`}
                     headContent={<>
                         <div className={styles.headInfo}>
                             <div className={styles.icon}/>
                             ID {id}
                         </div>
                         <div className={styles.headCount}>
                             {intensity}
                         </div>
                     </>}>
            <div className={styles.contentContainer}>
                <div className={styles.title}>
                    {title}
                </div>
                <div className={styles.infoBlock}>
                    <div className={styles.infoTitle}>
                        Начало вещания:
                    </div>
                    <div className={styles.infoValue}>
                        {new Date(dateFrom).toLocaleDateString()}
                    </div>
                </div>
                <div className={styles.infoBlock}>
                    <div className={styles.infoTitle}>
                        Окончание вещания:
                    </div>
                    <div className={styles.infoValue}>
                        {new Date(dateTo).toLocaleDateString()}
                    </div>
                </div>
            </div>
        </GenericCard>
    );
};

export default MediaplanCard;
