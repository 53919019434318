import React, { useState } from 'react';
import styles from './PriorityCreateScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useSetCurrentSection } from "../../../../hooks/useSetCurrentSection";
import { PAGE_SUBSECTIONS } from "../../../../constants/pageTypes";
import Input from "../../../../components/common/Input";
import Button from "../../../../components/common/Button";
import RecordCard from "../../../../components/Cards/RecordCard";
import Search from "../../../../components/Search";
import classNames from 'class-names';
import Pagination from "../../../../components/Pagination";
import CardCheckbox from "../../../../components/Cards/CardCheckbox";
import ListContainer from "../../../../components/ListContainer";

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Приоритетные объявления',
    href: '/radio/priority'
}, {
    name: 'Создание объявления',
    href: '/radio/priority/create'
}];

const testRecordIds = [1, 2, 3, 4, 5, 6];

const PriorityCreateScene = () => {
    const [selectedId, setSelectedId] = useState(testRecordIds[0]);

    useSetCurrentSection(PAGE_SUBSECTIONS.PRIORITY);

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.pageContainer}>
                <div className={styles.form}>
                    <Input name={'name'}
                           label={'Название объявления'}
                           inputClassName={styles.input}/>
                </div>
                <div className={classNames(styles.buttonsContainer, styles.hideMobile)}>
                    <Button href={'/radio/priority'}>
                        Отменить
                    </Button>
                    <Button href={'/radio/priority'}>
                        Создать
                    </Button>
                </div>
            </div>

            <div className={styles.searchContainer}>
                <Search containerClassName={styles.search}
                        showFilter={true}/>
            </div>
            <div className={classNames(styles.buttonsContainer, styles.hideDesktop)}>
                <Button href={'/radio/priority'}>
                    Отменить
                </Button>
                <Button href={'/radio/priority'}>
                    Создать
                </Button>
            </div>
            <ListContainer className={styles.listContainer}>
                {testRecordIds.map((id, index) =>
                    <RecordCard key={index}
                                checkbox={<CardCheckbox
                                    onChange={() => setSelectedId(id)}
                                    checked={selectedId === id}/>}/>
                )}
            </ListContainer>
            <Pagination count={12}
                        entityName={'Объектов'}
                        resultsPerPage={6}
                        currentPage={1}
                        setPage={() => {
                        }}
                        items={[]}/>
        </div>
    );
};

export default PriorityCreateScene;
