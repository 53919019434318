import React from 'react';
import PropTypes from 'prop-types';
import styles from './Breadcrumbs.module.scss';
import { Link } from "react-router-dom";
import classNames from 'class-names';

const Breadcrumbs = ({ routes, className }) => {
    return (
        <div className={classNames(styles.container, className)}>
            {routes.map((link, index) =>
                <Link to={link.href} className={styles.link} key={index}>
                    {link.name}
                </Link>
            )}
        </div>
    );
};

Breadcrumbs.propTypes = {
    routes: PropTypes.arrayOf(
        PropTypes.exact({
            name: PropTypes.string,
            href: PropTypes.string
        })
    ),
    className: PropTypes.string
};

export default Breadcrumbs;
