import { RESET_TOKENS, SET_AUTHORIZED, SET_TOKENS } from "../../actions/action-types";

export const setAuthorized = (authorized) => ({
    type: SET_AUTHORIZED,
    payload: authorized
});

export const setTokens = ({accessToken, refreshToken}) => ({
    type: SET_TOKENS,
    payload: {accessToken, refreshToken},
});

export const resetTokens = () => ({
    type: RESET_TOKENS,
});
