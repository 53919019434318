import { SET_CURRENT_PLAYER } from "./player-actions";

const initialState = null;

export const playerReducer = (state = initialState, action) => {
  switch (action.type) {
      case SET_CURRENT_PLAYER:
          return action.payload;

    default: return state;
  }
};
