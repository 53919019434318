import moment from "moment";

export const TIMELINE_TEST_DATA = {
    groups: [
        {
            id: 1,
            title: 'ID 09385  Пакет Праздничный день'
        },
        {
            id: 2,
            title: 'ID 09385  Пакет Праздничный день'
        },
        {
            id: 3,
            title: 'ID 09385  Пакет Праздника'
        },
        {
            id: 4,
            title: 'ID 09385  Пакет Праздника'
        },
        {
            id: 5,
            title: 'ID 09385  Пакет Праздника'
        },
        {
            id: 6,
            title: 'ID 09385  Пакет Праздника'
        },
        {
            id: 7,
            title: 'ID 09385  Пакет Праздника'
        },
        {
            id: 8,
            title: 'ID 09385  Пакет Праздника'
        },
        {
            id: 9,
            title: 'ID 09385  Пакет Праздника'
        },
        {
            id: 10,
            title: 'ID 09385  Пакет Праздника'
        },
    ],
    items: [
        {
            id: 1,
            group: 1,
            title: '',
            start_time: moment(),
            end_time: moment().add(0.5, 'month'),
            color: '#E75959'
        },
        {
            id: 2,
            group: 2,
            title: '',
            start_time: moment().add(-10, 'day'),
            end_time: moment().add(10, 'day'),
            color: '#FFD464'
        },
        {
            id: 3,
            group: 3,
            title: '',
            start_time: moment().add(-8, 'day'),
            end_time: moment().add(1, 'day'),
            color: '#FCFF77'
        },
        {
            id: 4,
            group: 4,
            title: '',
            start_time: moment().add(4, 'day'),
            end_time: moment().add(12, 'day'),
            color: '#3CE46B'
        },
        {
            id: 5,
            group: 5,
            title: '',
            start_time: moment().add(9, 'day'),
            end_time: moment().add(13, 'day'),
            color: '#54F1AF'
        },
        {
            id: 6,
            group: 6,
            title: '',
            start_time: moment().add(3, 'day'),
            end_time: moment().add(6, 'day'),
            color: '#697BDE'
        },
        {
            id: 25,
            group: 6,
            title: '',
            start_time: moment().add(-5, 'day'),
            end_time: moment().add(-1, 'day'),
            color: '#697BDE'
        },
        {
            id: 7,
            group: 7,
            title: '',
            start_time: moment().add(-9, 'day'),
            end_time: moment().add(-3, 'day'),
            color: '#D359E7'
        },
        {
            id: 8,
            group: 8,
            title: '',
            start_time: moment().add(-5, 'day'),
            end_time: moment().add(5, 'day'),
            color: '#40CED7'
        },
        {
            id: 9,
            group: 9,
            title: '',
            start_time: moment().add(-20, 'day'),
            end_time: moment().add(-12, 'day'),
            color: '#E22960'
        },
        {
            id: 10,
            group: 10,
            title: '',
            start_time: moment().add(1, 'day'),
            end_time: moment().add(8, 'day'),
            color: '#262B9A'
        },
    ]
};
