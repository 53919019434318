import React from 'react';
import { connect } from "react-redux";
import styles from './ModalRoot.module.scss';
import { hideModal } from "../../redux/actions/ui/modal/modal";
import Modal from "./Modal";

const mapStateToProps = ({ ui }) => ({
    modal: ui.modal
});

const mapDispatchToProps = (dispatch) => ({
    hideModal: () => dispatch(hideModal())
});

const ModalRoot = ({ modal, hideModal }) => {
    if (!modal.visible)
        return null;

    return (
        <div className={styles.container}>
            <div className={styles.background} onClick={hideModal}/>
            <Modal onClose={hideModal}
                   data={modal}/>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalRoot);
