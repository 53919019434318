import React from 'react';
import styles from './TimelineScene.module.scss';
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { TIMELINE_TEST_DATA } from './timelineTestData';
import TimelineWrapper from "../../../../components/TimelineWrapper";
import './timeline-custom.scss';

const breadcrumbsRoutes = [{
    name: 'Корпоративное радио',
    href: '#'
}, {
    name: 'Эфир',
    href: '/radio/broadcast'
}, {
    name: 'Страница медиаплана',
    href: '/radio/mediaplan',
}, {
    name: 'Таймлайн',
    href: '/radio/mediaplan/timeline',
}];

const TimelineScene = () => {

    return (
        <div className={styles.container}>
            <Breadcrumbs routes={breadcrumbsRoutes}/>
            <div className={styles.title}>
                Таймлайн: Стратегическое медиапланирование
            </div>
            <div className={styles.hint}>
                Нажатием на таймлайн отдельных роликов можно начать редактирование ролика
            </div>
            <div className={styles.hint}>
                Shift + Колёсико мыши - горизонтальная прокрутка<br/>
                Ctrl + Колёсико мыши - Увеличить/уменьшить масштаб
            </div>
            <div className={styles.timelineContainer}>
                <TimelineWrapper data={TIMELINE_TEST_DATA}/>
            </div>
        </div>
    );
};

export default TimelineScene;
